export default [
  {
    path: '/almacen/salidas',
    name: 'outcomes',
    component: () => import('@/modules/store/outcomes/views/OutcomesViews.vue'),
    meta: {
      pageTitle: 'Salidas',
      breadcrumb: [
        {
          text: 'Almacen',
        },
        {
          text: 'Salidas',
          active: true,
        },
      ],
      resource: 'Out_inventories',
      action: 'read',
      contentClass: 'outcomes-module',
    },
  },
  {
    path: '/almacen/salidas/:id',
    name: 'outcomes-details',
    component: () => import('@/modules/store/outcomes/views/OutcomesDetails.vue'),
    props: true,
    meta: {
      pageTitle: 'Salidas',
      breadcrumb: [
        {
          text: 'Almacen',
        },
        {
          text: 'Salidas',
          to: { name: 'outcomes' },
        },
        {
          text: 'Salidas',
          active: true,
        },
      ],
      resource: 'Out_inventories',
      action: 'read',
      contentClass: 'outcomes-module',
      navActiveLink: 'outcomes',
    },
  },
]
