export default [
  {
    path: '/reportes/pagos',
    name: 'payments-home',
    component: () => import('@/modules/reports/payments/views/PaymentsHome.vue'),
    meta: {
      pageTitle: 'Pagos',
      breadcrumb: [
        {
          text: 'Reportes',
        },
        {
          text: 'Pagos',
          active: true,
        },
      ],
      resource: 'Reports',
      action: 'read all',
      contentClass: 'payments-module',
    },
  },
]
