export default () => ({
  isLoadingProducts: false,
  products: [],
  product: null,

  // * 09/09/2022 - TAG: Manage the resposes after calls
  stateResponse: {
    success: false,
    message: '',
  },

  // * 09/09/2022 - TAG: Attibutes for pagination
  searchTerm: '',
  currentPage: 1,
  totalProducts: 0,
  locationTerm: '',
  perPage: 10,

  // * 14/09/2022 - TAG: Colors for product and tapestry
  isLoadingColors: true,
  tapestryColors: [],
  paintColors: [],

  // * 14/09/2022 - TAG: Suggests for product
  isLoadingSuggests: true,
  suggests: [],

  // * 14/09/2022 - TAG: Proudct type
  types: [
    {
      text: 'Nuevo',
    },
    {
      text: 'Usado',
    },
    {
      text: 'Remanufacturado',
    },
  ],

  // * 14/09/2022 - TAG: Wight types for product
  weights: [
    {
      text: 'kg',
      value: 'KG',
    },
    {
      text: 'lb',
      value: 'Libras',
    },
  ],

  // #region V-Models form (mian information)
  realCount: 0,
  selectedType: '',
  selectedDate: '',
  preSale: false,
  transferableProductId: null,
  // #endregion

  originProductRoute: null,

  // * 21/04/2023 - TAG: For global inventory filter control
  filteredProductsCount: null,
  filteredLowInventoryProductsCount: null,
  filteredOutInventoryProductsCount: null,

  loadingFilteredLists: false,

  // * 17/05/2023 - TAG: Dimensiones del producto
  width: '',
  height: '',
  weight: '',
  length: '',
  selectedWeight: {
    text: 'kg',
    value: 'KG',
  },

  // * 17/05/2023 - TAG: Precios del producto
  costUtility: '',
  exchangeRate: '',
  providersPriceUsd: '',
  unitPriceInvoiceMn: '',
  providersCostingMn: '',
  unitPriceInvoiceUsd: '',

  // * 18/05/2023 - TAG: Precios de lista de productos nuevos y remanufacturados
  minNormalPrice: '',
  maxNormalPrice: '',
  // Respaldo para los precios normales de lista
  backMinNormalPrice: '',
  backMaxNormalPrice: '',
  // Banderas para el setting de precios o si habrá precios de mayorista
  priceToBeDecided: false,
  wholesalerPrice: false,
  // Precios de mayorista
  minWholesalerPrice: '',
  maxWholesalerPrice: '',
  // Respaldo de los precios de mayorista
  backMinWholesalerPrice: '',
  backMaxWholesalerPrice: '',
  // Precio de oferta
  offerPrice: '',
  // Respaldo del precio de oferta
  backOfferPrice: '',

  // * 18/05/2023 - TAG: Precios de los productos usados
  lfPrice: '',
  drPrice: '',
  suggestMaxPrice: '',
  // Respaldo de los precios de productos usados
  backLfPrice: '',
  backDrPrice: '',
  backSuggestMaxPrice: '',

  // * 18/05/2023 - TAG: Información general del producto
  name: '',
  item: '',
  model: '',
  images: [],
  satCode: '',
  lowCount: 0,
  backItem: '',
  idProduct: '',
  image01: null,
  image02: null,
  image03: null,
  destination: '',
  description: '',
  submitted: false,
  internalCode: '',
  selectedBrand: '',
  selectedPaint: '',
  pendingImage: false,
  imagesLoaded: false,
  filteredOptions: [],
  selectedCategory: '',
  selectedTapestry: '',
  selectedSupplier: '',
  loadingDataForm: true,
  backSelectedSupplier: '',
  canceledTypeChange: false,
})
