export default () => ({
  sellersSuggests: [],

  selectedCommissionSeller: null,
  selectedCommissionMonthAndYear: null,

  newCommissionOrders: [],
  newCommissionRefunds: [],
  removedCommissionOrders: [],
  removedCommissionRefunds: [],
  selectedCommissionOrders: [],
  selectedCommissionRefunds: [],

  /**
   * Resumen de la comisión
   */
  commissionTotal: 0,
  commissionRefund: 0,
  commissionPercentage: 0,
  commissionTotalSelling: 0,
  commissionSubtotalSelling: 0,

  /**
   * Detalles de la comisión
   */
  commission: null,
  /**
   * Indica si la comisión se está en modo edición
   */
  isCommissionEditing: false,

  /**
   * Lista de identificadores de las órdenes pertenecientes a la comisión
   */
  commissionOrdersIds: [],
  commissionRefundsIds: [],
  originalCommissionsOrdersIds: [],
  originalCommissionsRefundsIds: [],

  /**
   * Lista de estatus de comisión
   */
  commissionStatus: [
    {
      text: 'Por pagar',
    },
    {
      text: 'Pagado',
    },
    {
      text: 'Cancelado',
    },
  ],

  commissionsYears: [],
  selectedIndexTab: 0,
})
