/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// eslint-disable-next-line no-unused-vars
export const loadOrders = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  // commit('saveListParams', { ...loadParams })

  axios.get('/orders/list',
    {
      params: {
        date: loadParams.dateTerm,
        showing: loadParams.perPage,
        page: loadParams.currentPage,
        search: loadParams.searchTerm,
        order_status: loadParams.statusOderTerm,
        payment_status: loadParams.statusPaymentTerm,
        user: loadParams.userTerm,
        client: loadParams.clientTerm,
        invoice: loadParams.invoicedOptionTerm,
        location: loadParams.locationTerm,
        confirmedDeliveryDate: loadParams.approvedByStoreDateTerm,
        outputDate: loadParams.outputDateTerm,
        completedDate: loadParams.receivedMonthTerm,
        commissionStatus: loadParams.commissionStatusTerm,
        ...(loadParams.sortTerm === 'Descendente' ? { sort: 'ID' } : { sort: '-ID' }),
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const sendOrdersCSVFile = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  const params = {
    date: loadParams.dateTerm,
    user: loadParams.userTerm,
    page: loadParams.currentPage,
    search: loadParams.searchTerm,
    client: loadParams.clientTerm,
    location: loadParams.locationTerm,
    outputDate: loadParams.outputDateTerm,
    invoice: loadParams.invoicedOptionTerm,
    order_status: loadParams.statusOderTerm,
    completedDate: loadParams.receivedMonthTerm,
    payment_status: loadParams.statusPaymentTerm,
    confirmedDeliveryDate: loadParams.approvedByStoreDateTerm,
    ...(loadParams.sortTerm === 'Descendente' ? { sort: 'ID' } : { sort: '-ID' }),
  }

  loadParams.activedColumnsTerm.forEach((column, index) => {
    params[`active[${index}]`] = column.value
  })

  axios.get('/orders/csv', { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadOrder = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/orders/show/${id}`)
    .then(response => {
      commit('setOrder', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
// eslint-disable-next-line no-unused-vars
export const loadComments = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get(`/orders/payment-comments/${loadParams.orderId}`, {
    params: loadParams,
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const deleteOrder = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.delete(`/orders/delete/${id}?showing=10`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadClientsSuggests = async ({ commit }, idUser) => new Promise((resolve, reject) => {
  axios.get(`/orders/clients?showing=0&user=${idUser}`)
    .then(response => {
      commit('setClientsSuggests', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadCreatorsSuggests = async ({ commit }, idUser) => new Promise((resolve, reject) => {
  axios.get(`/orders/users?showing=0&user=${idUser}`)
    .then(response => {
      commit('setCreatorsSuggests', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadOrdersStatus = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/orders/order-status')
    .then(response => {
      commit('setOrdersStatus', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadPaymentsStatus = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/orders/payment-status')
    .then(response => {
      commit('setPaymentsStatus', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const makeInvoice = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.put(`/orders/confirm-invoice/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updateOrder = async ({ commit }, { id, formData }) => new Promise((resolve, reject) => {
  formData.append('_method', 'PUT')

  axios.post(`/orders/update/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      console.log(response, 'en el salvado Correcto')
      resolve(response)
    })
    .catch(error => {
      console.log(error, 'en el salvado Incorrecto')
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const confirmReceiveOrder = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.post(`/orders/confirm/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const makeInProcessTransfer = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.post(`/orders/check-in-process/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const confirmTransfer = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.post(`/orders/confirm-transfer/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const readyForDeliveryOrder = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.post(`/orders/ready/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const stopOrder = async ({ commit }, { id, formData }) => new Promise((resolve, reject) => {
  axios.post(`/orders/stop/${id}`, formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const activateOrder = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.post(`/orders/activate/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const cancelOrder = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.post(`/orders/cancel/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const cancelRequestTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/cancel-order', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const cancelRequestTransferInProgress = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/cancel-in-progress', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const approveRequestTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/approve-order', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const rejectRequestTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/decline-order', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadStatusOrder = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/orders/status/list/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
// eslint-disable-next-line no-unused-vars
export const sendPaymentReminder = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/orders/payment-reminder/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const makePiecesOutputs = async ({ commit }, { id, formData }) => new Promise((resolve, reject) => {
  axios.post(`/orders/outputs/${id}`, formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const authorizeOutput = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.post(`/orders/approve/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const deliveryOrderDone = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.post(`/orders/delivery/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const confirmPayment = async ({ commit }, { iddOrder, idPayment }) => new Promise((resolve, reject) => {
  axios.get(`/orders/confirm-payment/${iddOrder}/${idPayment}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const cancelPayment = async ({ commit }, {
  iddOrder, idPayment, cancelFromReportPayment, comment,
}) => new Promise((resolve, reject) => {
  axios.post(`/orders/cancel-payment/${iddOrder}/${idPayment}/${cancelFromReportPayment}`,
    { comment })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadPDFFile = async ({ commit }, idd) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/orders/download/${idd}`, { responseType: 'arraybuffer' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `orden_${idd}.pdf`
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export const confirmDeliveryDate = async ({ commit }, { id, formData }) => new Promise((resolve, reject) => {
  axios.post(`/orders/confirm-delivery-date/${id}`, formData)
    .then(response => {
      console.log(response, 'en el salvado Correcto')
      resolve(response)
    })
    .catch(error => {
      console.log(error, 'en el salvado Incorrecto')
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const requestTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/request', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const makeRefund = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/devolution/store', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const checkRefundStatus = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`check-devolution?id=${id}`)
    .then(response => {
      commit('setCanMakeRefund', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadUsedCreditBalancePayments = async ({ commit }, { type, id, loadParams }) => new Promise((resolve, reject) => {
  axios.get(`/used-credit-balances/${type}/${id}`,
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadColumnsList = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/orders/column-list')
    .then(response => {
      commit('setColumnsList', response.data.data.columnsList)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// #region Partial delivery lists for modal
export const addPartialDeliveryProvitionalProduct = ({ commit }, provitionalProduct) => {
  commit('addPartialDeliveryProvitionalProduct', provitionalProduct)
}

export const addPartialDeliveryProvitionalCircuit = ({ commit }, provitionalCircuit) => {
  commit('addPartialDeliveryProvitionalCircuit', provitionalCircuit)
}

export const deletePartialDeliveryProvitionalCircuit = ({ commit }, provitionalCircuit) => {
  commit('deletePartialDeliveryProvitionalCircuit', provitionalCircuit)
}

export const addDetailProduct = ({ commit }, detailProduct) => {
  commit('addDetailProduct', detailProduct)
}

export const updateDetailProduct = ({ commit }, detailProduct) => {
  commit('updateDetailProduct', detailProduct)
}

export const addDetailCircuit = ({ commit }, detailCircuit) => {
  commit('addDetailCircuit', detailCircuit)
}

export const updateDetailCircuit = ({ commit }, detailCircuit) => {
  commit('updateDetailCircuit', detailCircuit)
}

export const deleteOrderCircuit = ({ commit }, orderCircuit) => {
  commit('deleteOrderCircuit', orderCircuit)
}

export const addCircuitWithOfferPriceSelected = ({ commit }, circuitWithOfferPriceSelected) => {
  commit('addCircuitWithOfferPriceSelected', circuitWithOfferPriceSelected)
}

export const removeCircuitWithOfferPriceSelected = ({ commit }, circuitWithOfferPriceSelected) => {
  commit('removeCircuitWithOfferPriceSelected', circuitWithOfferPriceSelected)
}

export const addDetailProductPieces = ({ commit }, detailProductPieces) => {
  commit('addDetailProductPieces', detailProductPieces)
}
// #endregion

export const setEditionModeState = ({ commit }, editionModeState) => {
  commit('setEditionModeState', editionModeState)
}

export const setSearchTerm = ({ commit }, searchTerm) => {
  commit('setSearchTerm', searchTerm)
}

export const setCountToInvoice = ({ commit }, countToInvoice) => {
  commit('setCountToInvoice', countToInvoice)
}

export const setInvoiceRequired = ({ commit }, invoiceRequired) => {
  commit('setInvoiceRequired', invoiceRequired)
}

export const removeListParams = ({ commit }, title) => {
  commit('removeListParams', title)
}

export const clearDeliveryState = ({ commit }) => {
  commit('clearDeliveryState')
}

export const clearData = ({ commit }) => {
  commit('clearData')
}
