export default () => ({
  // * 07/03/2023 - TAG: Locations for transfers
  locations: [
    {
      text: 'Bodega GDL',
    },
    {
      text: 'Bodega CDMX',
    },
    {
      text: 'Tienda',
    },
  ],
  selectedOrigin: '',
  selectedDestination: '',
  selectedRequiredDate: '',

  // * 07/03/2023 - TAG: State management list (products and circuits)
  transferPieces: [],
  transferProducts: [],

  transferCircuits: [],
  transferCircuitsProducts: [],
  transferSelectedCircuits: [],

  transferStatusList: [
    {
      text: 'Traspaso',
    },
    {
      text: 'Solicitud',
    },
    {
      text: 'Aprobada',
    },
    {
      text: 'Rechazada',
    },
    {
      text: 'Listo para traspaso',
    },
    {
      text: 'En transito',
    },
    {
      text: 'Recibida',
    },
    {
      text: 'Cancelado',
    },
  ],

  transfer: null,

  // * 16/03/2023 - TAG: Transfer edition mode
  editionModeState: false,

  // * 21/12/2022 - TAG: For making transfer received
  searchTerm: '',
  receivedProvitionalProducts: [],
  receivedProvitionalCircuits: [],
  receivedProvitionalProductsPieces: [],
})
