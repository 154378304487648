export default () => ({
  isLoadingProcategories: false,
  procategories: [],
  /**
   * Respaldo de la lista para cuando la edicion es cancelada
   * y es necesario restaurar la lista original
   */
  backupProcategories: [],

  // 09/09/2022 - TAG: Manage the resposes after calls
  stateResponse: {
    success: false,
    message: '',
  },

  // 09/09/2022 - TAG: Attibutes for pagination
  searchTerm: '',
  totalProcategories: 0,
})
