export default [
  {
    path: '/usuarios/mayoristas',
    name: 'wholesalers-home',
    component: () => import('@/modules/users/wholesalers/views/WholesalersList.vue'),
    meta: {
      pageTitle: 'Mayoristas',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Mayoristas',
          active: true,
        },
      ],
      resource: 'Wholesalers',
      action: 'read',
      contentClass: 'wholesalers-module',
    },
  },
  {
    path: '/usuarios/mayoristas/nuevo',
    name: 'new-wholesaler',
    component: () => import('@/modules/users/wholesalers/views/SaveWholesalerForm.vue'),
    meta: {
      pageTitle: 'Mayoristas',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Mayoristas',
          to: { name: 'wholesalers-home' },
        },
        {
          text: 'Nuevo',
          active: true,
        },
      ],
      resource: 'Wholesalers',
      action: 'read',
      contentClass: 'wholesalers-module',
      navActiveLink: 'wholesalers-home',
    },
  },
  {
    path: '/usuarios/mayoristas/actualizar/:id',
    name: 'update-wholesaler',
    component: () => import('@/modules/users/wholesalers/views/SaveWholesalerForm.vue'),
    props: true,
    meta: {
      pageTitle: 'Mayoristas',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Mayoristas',
          to: { name: 'wholesalers-home' },
        },
        {
          text: 'Actualizar',
          active: true,
        },
      ],
      resource: 'Wholesalers',
      action: 'read',
      contentClass: 'wholesalers-module',
      navActiveLink: 'wholesalers-home',
    },
  },
  {
    path: '/usuarios/mayoristas/:id',
    name: 'detail-wholesaler',
    component: () => import('@/modules/users/wholesalers/views/WholesalersDetail.vue'),
    props: true,
    meta: {
      pageTitle: 'Mayoristas',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Mayoristas',
          to: { name: 'wholesalers-home' },
        },
      ],
      resource: 'Wholesalers',
      action: 'read',
      contentClass: 'wholesalers-module',
      navActiveLink: 'wholesalers-home',
    },
  },
]
