export default [
  {
    path: '/comercial/ordenes:filter?',
    name: 'orders-home',
    component: () => import('@/modules/trade/orders/views/OrdersList.vue'),
    meta: {
      pageTitle: 'Ordenes',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Ordenes',
          active: true,
        },
      ],
      resource: 'Orders',
      action: 'read',
      contentClass: 'orders-module',
    },
  },
  {
    path: '/comercial/orden/:id',
    name: 'order-details',
    component: () => import('@/modules/trade/orders/views/OrderDetails.vue'),
    props: true,
    meta: {
      pageTitle: 'Órdenes',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Órdenes',
          to: { name: 'orders-home' },
        },
        {
          text: 'Detalles de la orden',
          active: true,
        },
      ],
      resource: 'Order',
      action: 'read',
      contentClass: 'orders-module',
      navActiveLink: 'orders-home',
    },
  },
]
