export default [
  {
    path: '/almacen/devoluciones',
    name: 'refunds-list',
    component: () => import('@/modules/store/refunds/views/RefundsList.vue'),
    meta: {
      pageTitle: 'Devoluciones',
      breadcrumb: [
        {
          text: 'Almacen',
        },
        {
          text: 'Devoluciones',
          active: true,
        },
      ],
      resource: 'Devolutions',
      action: 'read',
      contentClass: 'refunds-module',
    },
  },
  {
    path: '/almacen/devoluciones/:id',
    name: 'refund-details',
    component: () => import('@/modules/store/refunds/views/RefundDetails.vue'),
    props: true,
    meta: {
      pageTitle: 'Devoluciones',
      breadcrumb: [
        {
          text: 'Almacen',
        },
        {
          text: 'Devoluciones',
          to: { name: 'refunds-list' },
        },
        {
          text: 'Detalles de la devolución',
          active: true,
        },
      ],
      resource: 'Devolution',
      action: 'read',
      contentClass: 'refunds-module',
      navActiveLink: 'refunds-list',
    },
  },
]
