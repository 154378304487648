/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

export const getIncomes = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/products-entries',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
        brand: loadParams.brandTerm,
        category: loadParams.categoryTerm,
        type: loadParams.typeTerm,
        location: loadParams.locationTerm,
        availability: loadParams.availabilityTerm,
        statusText: loadParams.statusPieceTerm,
        status: loadParams.statusTerm,
        date: loadParams.dateTerm,
        year: loadParams.yearTerm,
        noImport: loadParams.importNumberTerm,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadProductIncome = async ({ commit }, { id, query }) => {
  let route = `/products-entrie/show?id=${id}`

  if (query) {
    route = `/products-entrie/show?id=${id}&${query}`
  }

  return new Promise((resolve, reject) => {
    axios.get(route)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

export const updatePiece = async ({ commit }, formData) => new Promise((resolve, reject) => {
  formData.append('_method', 'PUT')

  axios.post('/piece-entrie/update', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const deletePiece = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.delete(`/products-entrie-piece/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadIncomesYears = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get('/products-entries/years')
    .then(response => {
      commit('setIncomesYears', response.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const sendIncomesCSVFile = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/pdf-incomes',
    {
      params: {
        'filter[search]': loadParams.searchTerm,
        brand: loadParams.brandTerm,
        category: loadParams.categoryTerm,
        type: loadParams.typeTerm,
        location: loadParams.locationTerm,
        availability: loadParams.availabilityTerm,
        statusText: loadParams.statusPieceTerm,
        date: loadParams.dateTerm,
        year: loadParams.yearTerm,
        noImport: loadParams.importNumberTerm,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
