/* eslint-disable import/prefer-default-export */

// * 14/09/2022 - TAG: Functions for procategories list

// 14/09/2022 - TAG: Set the procategories obtained from server to show on list
export const setProcategories = (state, procategories) => {
  state.procategories = procategories
}

export const setBackupProcategories = (state, backupProcategories) => {
  state.backupProcategories = backupProcategories
}

// * 14/09/2022 - TAG: General states for procategories page

// 14/09/2022 - TAG: Set the loading responses state for overlay functions
export const setLoadingProcategories = (state, isLoadingProcategories) => {
  state.isLoadingProcategories = isLoadingProcategories
}

// 14/09/2022 - TAG: Set the state response based on axios requests
export const setStateResponse = (state, stateResponse) => {
  state.stateResponse = stateResponse
}

// 14/09/2022 - TAG: Set the search term for personalized query on server
export const setSearchTerm = (state, searchTerm) => {
  state.searchTerm = searchTerm
}

// 14/09/2022 - TAG: Set the total count categories on server for pagination
export const setTotalProcategories = (state, totalProcategories) => {
  state.totalProcategories = totalProcategories
}
