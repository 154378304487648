/* eslint-disable import/prefer-default-export */
export const getLocations = state => state.locations
export const getSelectedOrigin = state => state.selectedOrigin
export const getSelectedDestination = state => state.selectedDestination
export const getSelectedRequiredDate = state => state.selectedRequiredDate

function searcheableProduct(product, searchTerm) {
  return (product.Name?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.Model?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.Type?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.Item?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.name_brand?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.name_categorie?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.NamePaint?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.NameTapestry?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.QtyIventory?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        || product.Qty?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        || product.UnitPrice?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        || product.Subtotal?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  )
      || product.pieces.filter(piece => piece.Tag.toLowerCase().includes(searchTerm.toLowerCase())
        || piece.NoSeries.toLowerCase().includes(searchTerm.toLowerCase())
        || piece.NoPetition.toLowerCase().includes(searchTerm.toLowerCase())
        || piece.Condition.toLowerCase().includes(searchTerm.toLowerCase())
        || piece.Availability.toLowerCase().includes(searchTerm.toLowerCase())
        || piece.Location.toLowerCase().includes(searchTerm.toLowerCase())).length > 0
}

// * 07/03/2023 - TAG: State management list (products and circuits)
export const getTransferPieces = state => state.transferPieces
export const getTransferProducts = state => state.transferProducts

export const getTransferProductsFiltered = (state, getters) => state.transferProducts.filter(product => {
  const searchTerm = getters.getSearchTerm
  const searcheable = searchTerm.length > 0
    ? searcheableProduct(product, searchTerm)
    : true

  return searcheable
})

export const getTransferCircuits = state => state.transferCircuits

export const getTransferCircuitsFiltered = (state, getters) => state.transferCircuits.filter(circuit => {
  const searchTerm = getters.getSearchTerm
  const searcheable = searchTerm.length > 0
    ? (
      (circuit.Name?.toLowerCase().includes(searchTerm.toLowerCase())
        || circuit.Location?.toLowerCase().includes(searchTerm.toLowerCase())
        || circuit.Qty?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
      || circuit.products.filter(product => searcheableProduct(product, searchTerm))
    )
    : true

  return searcheable
})

export const getTransferSelectedCircuits = state => state.transferSelectedCircuits
export const getTransferCircuitsProducts = state => state.transferCircuitsProducts

export const getTransferStatusList = state => state.transferStatusList

export const getTransfer = state => state.transfer

// * 16/03/2023 - TAG: Transfer edition mode
export const getEditionModeState = state => state.editionModeState

// * 21/12/2022 - TAG: For making transer received
export const getSearchTerm = state => state.searchTerm
export const getReceivedProvitionalProducts = state => state.receivedProvitionalProducts
export const getReceivedProvitionalCircuits = state => state.receivedProvitionalCircuits
export const getReceivedProvitionalProductsPieces = state => state.receivedProvitionalProductsPieces
