/* eslint-disable import/prefer-default-export */

export const setSelectedSuppliers = (state, selectedSuppliers) => {
  state.selectedSuppliers = selectedSuppliers
}

export const setColumnsList = (state, columnsList) => {
  state.columnsList = columnsList
}

export const addSelectedSupplier = (state, selectedSupplier) => {
  state.selectedSuppliers.push(selectedSupplier)
}

export const removeSelectedSupplier = (state, selectedSupplier) => {
  state.selectedSuppliers = state.selectedSuppliers.filter(supplier => supplier.id !== selectedSupplier.id)
}

export const saveListParams = (state, listParams) => {
  const index = state.listsParams.findIndex(params => params.title === listParams.title)
  if (index === -1) {
    state.listsParams.push(listParams)
  } else {
    state.listsParams.splice(index, 1, listParams)
  }
}

export const removeListParams = (state, title) => {
  state.listsParams = state.listsParams.filter(params => params.title !== title)
}

export const addTab = (state, tab) => {
  state.tabs.push(tab)
}

export const clearReportsSuppliersInfo = state => {
  state.selectedSuppliers = []
  state.listsParams = []
  state.tabs = [
    {
      title: 'Todos',
      value: 'all',
    },
    {
      title: 'Sin proveedor',
      value: 'withoutSupplier',
    },
  ]
  state.columnsList = []
}
