export default [
  {
    path: '/produccion/productos',
    name: 'products-home',
    component: () => import('@/modules/production/products/views/ProductsHome.vue'),
    meta: {
      pageTitle: 'Productos',
      breadcrumb: [
        {
          text: 'Producción',
        },
        {
          text: 'Productos',
          active: true,
        },
      ],
      resource: 'Products',
      action: 'read',
      contentClass: 'products-module',
    },
  },
  {
    path: '/produccion/productos/nuevo',
    name: 'new-product',
    component: () => import('@/modules/production/products/views/SaveProductForm.vue'),
    meta: {
      pageTitle: 'Productos',
      breadcrumb: [
        {
          text: 'Producción',
        },
        {
          text: 'Productos',
          to: { name: 'products-home' },
        },
        {
          text: 'Nuevo',
          active: true,
        },
      ],
      resource: 'Product',
      action: 'create',
      contentClass: 'products-module',
      navActiveLink: 'products-home',
    },
  },
  {
    path: '/produccion/productos/actualizar/:id/visible/:visible?',
    name: 'update-product',
    component: () => import('@/modules/production/products/views/SaveProductForm.vue'),
    props: true,
    meta: {
      pageTitle: 'Productos',
      breadcrumb: [
        {
          text: 'Producción',
        },
        {
          text: 'Productos',
          to: { name: 'products-home' },
        },
        {
          text: 'Actualizar',
          active: true,
        },
      ],
      resource: 'Product',
      action: 'edit',
      contentClass: 'products-module',
      navActiveLink: 'products-home',
    },
  },
  {
    path: '/produccion/productos/:id',
    name: 'product-details',
    component: () => import('@/modules/production/products/views/ProductDetails.vue'),
    props: true,
    meta: {
      pageTitle: 'Productos',
      breadcrumb: [
        {
          text: 'Producción',
        },
        {
          text: 'Productos',
          to: { name: 'products-home' },
        },
        {
          text: 'Detalles del producto',
          active: true,
        },
      ],
      resource: 'Product',
      action: 'read',
      contentClass: 'products-module',
      navActiveLink: 'products-home',
    },
  },
  {
    path: '/produccion/productos/:id/visible/:visible/piezas/:query?/cantidad/:count?',
    name: 'product-details-pieces',
    component: () => import('@/modules/production/products/views/ProductDetails.vue'),
    props: true,
    meta: {
      pageTitle: 'Productos',
      breadcrumb: [
        {
          text: 'Producción',
        },
        {
          text: 'Productos',
          to: { name: 'products-home' },
        },
        {
          text: 'Detalles del producto',
          active: true,
        },
      ],
      resource: 'Product',
      action: 'read',
      contentClass: 'products-module',
      navActiveLink: 'products-home',
    },
  },
]
