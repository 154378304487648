export default () => ({
  // * 31/10/2022 - TAG: Customers for quotes
  isLoadingCustomers: true,
  customers: [],

  // * 10/11/2022 - TAG: Global id for form interactions
  idQuote: null,
  idCustomer: null,
  idInvoiceAddress: null,
  idShipmentAddress: null,

  // * 01/11/2022 - TAG: Selected customer from customer information select
  selectedCustomer: null,

  // * 01/11/2022 - TAG: Customer data
  customerType: '',
  customerPhone: '',
  customerEmail: '',
  customerLastName: '',
  customerFirstName: '',

  // * 31/10/2022 - TAG: Invoice data
  RFC: '',
  CFDIUssage: '',
  BusinessName: '',

  // * 01/11/2022 - TAG: Shipment address
  shipmentCity: '',
  shipmentState: '',
  shipmentEmail: '',
  shipmentPhone: '',
  shipmentColony: '',
  shipmentContact: '',
  shipmentAddress: '',
  shipmentZipCode: '',

  // * 01/11/2022 - TAG: Invoice address
  invoiceCity: '',
  invoiceState: '',
  invoiceEmail: '',
  invoicePhone: '',
  invoiceColony: '',
  invoiceContact: '',
  invoiceAddress: '',
  invoiceZipCode: '',

  // * 03/11/2022 - TAG: State management list (products and circuits)
  quotePieces: [],
  quoteProducts: [],

  quoteCircuits: [],
  quoteCircuitsProducts: [],
  quoteSelectedCircuits: [],

  // * 14/09/2022 - TAG: Delivery type and methods
  deliveryTypes: [
    {
      text: 'Entrega a domicilio de ZMG',
    },
    {
      text: 'Flete del cliente',
    },
    {
      text: 'Paquetería',
    },
    {
      text: 'Mercado Libre',
    },
    {
      text: 'Otro',
    },
  ],
  deliveryMethods: [
    {
      text: 'Tarima',
    },
    {
      text: 'Emplaye',
    },
    {
      text: 'Armado',
    },
    {
      text: 'Desarmado Total',
    },
    {
      text: 'Desarmado parcial',
    },
    {
      text: 'En caja',
    },
  ],
  arriveDate: '',
  countToInvoice: 0,
  invoiceRequired: 'No',
  selectedDeliveryType: '',
  selectedDeliveryMethod: '',

  // * 16/11/2022 - TAG: Total quote
  discount: 0,
  exchangeType: 0,
  shipmentCost: 0,
  installationCost: 0,

  // * 17/11/2022 - TAG: For quotes list
  quotesStatus: [
    {
      text: 'Cotización',
    },
    {
      text: 'Enviado',
    },
    {
      text: 'Solicitud',
    },
    {
      text: 'Orden',
    },
    {
      text: 'Rechazado',
    },
    {
      text: 'Cancelado',
    },
  ],
  idOptions: [
    {
      text: 'Ascendente',
      icon: 'ArrowUpIcon',
    },
    {
      text: 'Descendente',
      icon: 'ArrowDownIcon',
    },
  ],

  // * 22/11/2022 - TAG: Quotes list filters
  clientsSuggests: [],
  creatorsSuggests: [],

  // * 29/11/2022 - TAG: Quote payments
  payments: [],
  paymentMethods: [],

  paymentStatus: 0,
  percentagePaid: 0,

  quote: null,

  circuitsWithOfferPriceSelected: [],
})
