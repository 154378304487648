export default () => ({
  listsParams: [],
  ordersColumnsList: [],
  refundsColumnsList: [],
  cashOrdersColumnsList: [],

  tabs: [
    {
      title: 'Todos',
      value: 'all',
    },
    {
      title: 'Efectivo',
      value: 'cash',
    },
    {
      title: 'Devoluciones',
      value: 'refunds',
    },
  ],

  /**
   * Ubicaciones para filtros
   */
  locations: [
    {
      name: 'Bodega GDL',
    },
    {
      name: 'Bodega CDMX',
    },
    {
      name: 'Tienda',
    },
  ],

  /**
   * Estatus de los pagos de las ordenes para filtros
   */
  orderPaymentStatus: [
    {
      name: 'Pago pendiente',
    },
    {
      name: 'Pago liquidado',
    },
  ],

  /**
   * Sugerencias de creadores para filtros
   */
  creatorsSuggests: [],

  /**
   * Estatus de pago para filtros
   */
  paymentStatus: [
    {
      name: 'Pendiente',
    },
    {
      name: 'Pagado',
    },
    {
      name: 'Cancelado',
    },
  ],

  /**
   * Estatus de la entrega de efectivo para filtros
   */
  cashDeliveryStatus: [
    {
      name: 'Pendiente',
    },
    {
      name: 'Por Confirmar',
    },
    {
      name: 'Entregado',
    },
    {
      name: 'Cancelado',
    },
  ],

  /**
   * Tipos de monedas para filtros
   */
  paymentsCurrencies: [
    {
      name: 'MXN',
    },
    {
      name: 'USD',
    },
  ],

  selectedRefundPayment: null,

  /**
   * Filtro de fecha para las tablas de la pestaña dashboard
   * en el módulo de repotes de pagos cargados por defecto
   */
  defaultDashboardDateRange: {
    from: new Date(new Date().setDate(new Date().getDate() - 30)).toLocaleDateString('en-CA'),
    to: new Date().toLocaleDateString('en-CA'),
  },

  /**
   * Filtro de fecha para las tablas de la pestaña dashboard
   * en el módulo de repotes de pagos cargados por el filtro
   * de rango de fechas
   */
  filteredDashboardDateRange: null,

  /**
   * Listas de dashboard
   */
  dashboardPaymentsMethodsList: [],
  dashboardMXNCashPaymentsList: [],
  dashboardUSDCashPaymentsList: [],
  dashboardRegisteredRefundsList: [],
  dashboardRegisteredPaymentsList: [],
  /**
   * Totales de dashboard
   */
  dashboardPaymentsMethodsTotal: null,
  dashboardMXNCashPaymentsTotal: null,
  dashboardUSDCashPaymentsTotal: null,
  dashboardRegisteredRefundsTotal: null,
  dashboardRegisteredPaymentsTotal: null,

  /**
   * Listas de transacciones
   */
  transactionsSellersPaymentsList: [],
  transactionsCustomersPaymentsList: [],
  transactionsWholesalersPaymentsList: [],

  /**
   * Filtro de fecha para las tablas de la pestaña transacciones
   * en el módulo de repotes de pagos cargados por defecto
   */
  defaultTransactionsDateRange: {
    from: new Date(new Date().setDate(new Date().getDate() - 30)).toLocaleDateString('en-CA'),
    to: new Date().toLocaleDateString('en-CA'),
  },

  /**
   * Filtro de fecha para las tablas de la pestaña transacciones
   * en el módulo de repotes de pagos cargados por el filtro
   * de rango de fechas
   */
  filteredTransactionsDateRange: null,

  /**
   * Estatus de la orden para filtros
   */
  orderStatus: [],

  /**
   * Estatus de la cotización para filtros
   */
  quoteStatus: [
    {
      status: 'Solicitud',
    },
    {
      status: 'Rechazado',
    },
    {
      status: 'Cancelado',
    },
  ],

  /**
   * Bandera que indica se se ha seleccionado la opción para mostrar la información de pagos
   * en forma de gráficos dentro de la pestala de dashboard
   */
  showDashboardCharts: false,
})
