/* eslint-disable import/prefer-default-export */

export const getProvitionalProductsToDelete = state => state.provitionalProductsToDelete
export const getProvitionalProducts = state => state.provitionalProducts
export const getProvitionalPieces = state => state.provitionalPieces
export const getCircuitProducts = state => state.circuitProducts
export const getDeletedProducts = state => state.deletedProducts
export const getCircuitPieces = state => state.circuitPieces
export const getDeletedPieces = state => state.deletedPieces
export const getLoadedPieces = state => state.loadedPieces

export const getFilteredCircuitProducts = state => params => state.circuitProducts.filter(product => {
  const passTypeTerm = params.typeTerm.length > 0 ? product.Type === params.typeTerm : true
  const passBrandTerm = params.brandTerm.length > 0 ? product.name_brand === params.brandTerm : true
  const passCategoryTerm = params.categoryTerm.length > 0 ? product.name_categorie === params.categoryTerm : true

  const searchName = params.searchTerm.length > 0
    ? (
      product.Code.toLowerCase().includes(params.searchTerm.toLowerCase())
    || product.Name.toLowerCase().includes(params.searchTerm.toLowerCase())
    || product.Model.toLowerCase().includes(params.searchTerm.toLowerCase())
    || product.Type.toLowerCase().includes(params.searchTerm.toLowerCase())
    || product.NamePaint.toLowerCase().includes(params.searchTerm.toLowerCase())
    || product.CodePaint.toLowerCase().includes(params.searchTerm.toLowerCase())
    || product.NameTapestry.toLowerCase().includes(params.searchTerm.toLowerCase())
    || product.CodeTapestry.toLowerCase().includes(params.searchTerm.toLowerCase())
    )
    : true

  return passTypeTerm && passBrandTerm && passCategoryTerm && searchName
})
