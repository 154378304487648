export default () => ({
  selectedSuppliers: [],
  listsParams: [],
  tabs: [
    {
      title: 'Todos',
      value: 'all',
    },
    {
      title: 'Sin proveedor',
      value: 'withoutSupplier',
    },
  ],
  columnsList: [],
})
