/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// eslint-disable-next-line no-unused-vars
export const loadCircuits = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get(`/${loadParams.resource}`,
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
        location: loadParams.locationTerm,
        ...(loadParams.qtyActive === 1 && { qtyActive: 1 }),
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const createCircuit = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/circuits/save', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      console.log(response, 'en el salvado Correcto')
      resolve(response)
    })
    .catch(error => {
      console.log(error, 'en el salvado Incorrecto')
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updateCircuit = async ({ commit }, formData) => new Promise((resolve, reject) => {
  formData.append('_method', 'PUT')

  axios.post('/circuits/update', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      console.log(response, 'en el actualizado Correcto')
      resolve(response)
    })
    .catch(error => {
      console.log(error, 'en el actualizado Incorrecto')
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadCircuit = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/circuits/show?id=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const deleteCircuit = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.delete(`/circuits/delete?id=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const addProvitionalProductToDelete = ({ commit }, provitionalProductToDelete) => {
  commit('addProvitionalProductToDelete', provitionalProductToDelete)
}

export const addProvitionalProduct = ({ commit }, provitionalProduct) => {
  commit('addProvitionalProduct', provitionalProduct)
}

export const addCircuitProduct = ({ commit }, circuitProduct) => {
  commit('addCircuitProduct', circuitProduct)
}

export const addCircuitProductEmptyPieces = ({ commit }, circuitProduct) => {
  commit('addCircuitProductEmptyPieces', circuitProduct)
}

export const addDeletedProduct = ({ commit }, deletedProduct) => {
  commit('addDeletedProduct', deletedProduct)
}

export const clearFormData = ({ commit }) => {
  commit('clearFormData')
}
