import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import { getHomeRouteForLoggedInUser, getUserData, isUserLoggedIn } from '@/auth/utils'

// ? 07/09/2022 - TAG: Home module routes
import dashboard from '@/modules/home/dashboard/router'

// ? 07/09/2022 - TAG: Production module routes
import catalog from '@/modules/production/catalog/router'
import products from '@/modules/production/products/router'
import circuits from '@/modules/production/circuits/router'
import transfers from '@/modules/production/transfers/router'
import imports from '@/modules/production/imports/router'

// ? 07/09/2022 - TAG: Users module routes
import sellers from '@/modules/users/sellers/router'
import wholesalers from '@/modules/users/wholesalers/router'
import customers from '@/modules/users/customers/router'
import company from '@/modules/users/company/router'

// ? 07/09/2022 - TAG: Comercial module routes
import quotes from '@/modules/trade/quotes/router'
import orders from '@/modules/trade/orders/router'
import commission from '@/modules/trade/commissions/router'

// ? 07/09/2022 - TAG: Trade module routes
import outcomes from '@/modules/store/outcomes/router'
import incomes from '@/modules/store/incomes/router'
import refunds from '@/modules/store/refunds/router'

// ? 07/09/2022 - TAG: Ecommerce module routes
import ecommerce from '@/modules/ecommerce/products/router'

// ? 17/07/2023 - TAG: Report module routes

import suppliers from '@/modules/reports/suppliers/router'
import payments from '@/modules/reports/payments/router'

import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...pages,
    ...quotes,
    ...orders,
    ...catalog,
    ...sellers,
    ...products,
    ...circuits,
    ...dashboard,
    ...wholesalers,
    ...customers,
    ...company,
    ...incomes,
    ...outcomes,
    ...transfers,
    ...refunds,
    ...suppliers,
    ...payments,
    ...ecommerce,
    ...imports,
    ...commission,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// router.beforeRouteLeave((to, from, next) => {
//   const isLoggedIn = isUserLoggedIn()
//   if (!isLoggedIn) {
//     return next({ name: 'auth-login' })
//   }

//   return next()
// })

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.Role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
