/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

export const loadCustomers = async ({ commit }) => {
  commit('setLoadingCustomers', true)

  return new Promise((resolve, reject) => {
    axios.get('/quotes/clients-list')
      .then(response => {
        commit('setCustomers', response.data.data)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
      .then(() => {
        commit('setLoadingCustomers', false)
      })
  })
}

export const loadCustomer = async ({ commit }, id) => {
  commit('setLoadingCustomers', true)

  return new Promise((resolve, reject) => {
    axios.get(`/quotes/client-list?cliente=${id}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
      .then(() => {
        commit('setLoadingCustomers', false)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export const updateCustomerInvoiceData = async ({ commit }, formData) => new Promise((resolve, reject) => {
  formData.append('_method', 'PUT')

  axios.post('/quotes/client-billing', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updateCustomerInformation = async ({ commit }, formData) => new Promise((resolve, reject) => {
  formData.append('_method', 'PUT')

  axios.post('/quotes/update-client', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updateShipmentAddress = async ({ commit }, formData) => new Promise((resolve, reject) => {
  formData.append('_method', 'PUT')

  axios.post('/quotes/client-address', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updateInvoiceAddress = async ({ commit }, formData) => new Promise((resolve, reject) => {
  formData.append('_method', 'PUT')

  axios.post('/quotes/client-billingsdata', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const createQuote = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/quotes/store', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updateQuote = async ({ commit }, formData) => new Promise((resolve, reject) => {
  formData.append('_method', 'PUT')

  axios.post('/quotes/update', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadQuotes = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/quotes',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
        date: loadParams.dateTerm,
        status: loadParams.statusTerm,
        idClient: loadParams.idClientTerm,
        idCreator: loadParams.idCreatorTerm,
        idFilter: loadParams.idFilterUser,
        // ...(loadParams.sortTerm === 'Descendente' && { sort: '-ID' }),
        ...(loadParams.sortTerm === 'Descendente' ? { sort: 'ID' } : { sort: '-ID' }),
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadClientsSuggests = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/quotes/search-clients')
    .then(response => {
      commit('setClientsSuggests', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadCreatorsSuggests = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/quotes/users')
    .then(response => {
      commit('setCreatorsSuggests', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadQuote = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/quotes/show?id=${id}`)
    .then(response => {
      const [quoteData] = response.data
      commit('setQuote', quoteData)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const cancelQuote = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.delete(`quotes/cancel?id=${id}&cancel=true`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadPDFFile = async ({ commit }, { id1, id2 }) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/quotes/download?id=${id1}`, { responseType: 'arraybuffer' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `cotización_${id2}.pdf`
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadImage = async ({ commit }, id1) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/quotes/download-image?id=${id1}`, {
      responseType: 'blob',
    })
      .then(response => {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'pago.jpg'
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadImageBlob = async ({ commit }, id1) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/quotes/download-image?id=${id1}`, {
      responseType: 'blob',
    })
      .then(response => {
        const blob = new Blob([response.data], { type: 'image/jpg' })
        resolve(blob)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export const loadPayments = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/quotes/pays?id=${id}&activeImages=1`)
    .then(response => {
      commit('setPayments', response.data.data.payment)
      commit('setPaymentStatus', response.data.data.status)
      commit('setPercentagePaid', response.data.data.percentage)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadPaymentMethods = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/quotes/payment-methods')
    .then(response => {
      commit('setPaymentMethods', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadPaymentResume = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/quotes/payment-resume?id=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const createPayment = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/quotes/payments', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updatePayment = async ({ commit }, { id, formData }) => new Promise((resolve, reject) => {
  axios.post(`/quotes/payments/${id}`, formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const canMakePayment = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/quotes/can-make-a-payment/${id}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const createImageQuote = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/images/save-quote', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const sendEmailToCustomer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/quotes/send', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const cancelCreatedQuote = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/quotes/cancel', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const confirmPayment = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/quotes/confirm-payment', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const cancelPayment = async ({ commit }, params) => new Promise((resolve, reject) => {
  axios.post(`/quotes/cancel-payment?id=${params.id}&comment=${params.comment}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const approveQuote = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/quotes/approve', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const refuseQuote = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/quotes/refuse', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const deleteQuote = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.delete(`/quotes/delete?id=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const confirmRefusedQuote = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/quotes/change-request', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadCreditBalances = async ({ commit }, id = null) => new Promise((resolve, reject) => {
  axios.get(`/credit-balances?idPayment=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const uploadTaxSituationProof = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/orders/tax-situation', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadTaxSituationProof = async ({ commit }, params) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/orders/tax-situation?${params.idModel}`, {
      responseType: 'blob',
    })
      .then(response => {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${params.fileName}`
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export const deleteTaxSituationProof = async ({ commit }, taxSituationProofId) => new Promise((resolve, reject) => {
  axios.delete(`/orders/delete-tax-situation/${taxSituationProofId}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// #region Quote interactions
export const addQuotePieces = ({ commit }, quotePieces) => {
  commit('addQuotePieces', quotePieces)
}

export const setIdQuote = ({ commit }, idQuote) => {
  commit('setIdQuote', idQuote)
}

export const setIdCustomer = ({ commit }, idCustomer) => {
  commit('setIdCustomer', idCustomer)
}

export const setIdInvoiceAddress = ({ commit }, idInvoiceAddress) => {
  commit('setIdInvoiceAddress', idInvoiceAddress)
}

export const setIdShipmentAddress = ({ commit }, idShipmentAddress) => {
  commit('setIdShipmentAddress', idShipmentAddress)
}
// #endregion

// #region Customer data form
export const setSelectedCustomer = ({ commit }, selectedCustomer) => {
  commit('setSelectedCustomer', selectedCustomer)
}

export const setCustomerType = ({ commit }, customerType) => {
  commit('setCustomerType', customerType)
}

export const setCustomerPhone = ({ commit }, customerPhone) => {
  commit('setCustomerPhone', customerPhone)
}

export const setCustomerEmail = ({ commit }, customerEmail) => {
  commit('setCustomerEmail', customerEmail)
}

export const setCustomerLastName = ({ commit }, customerLastName) => {
  commit('setCustomerLastName', customerLastName)
}

export const setCustomerFirstName = ({ commit }, customerFirstName) => {
  commit('setCustomerFirstName', customerFirstName)
}
// #endregion

// #region Invoice data form
export const setRFC = ({ commit }, RFC) => {
  commit('setRFC', RFC)
}

export const setCFDIUssage = ({ commit }, CFDIUssage) => {
  commit('setCFDIUssage', CFDIUssage)
}

export const setBusinessName = ({ commit }, BusinessName) => {
  commit('setBusinessName', BusinessName)
}
// #endregion

// #region Shipment address data
export const setShipmentCity = ({ commit }, shipmentCity) => {
  commit('setShipmentCity', shipmentCity)
}

export const setShipmentState = ({ commit }, shipmentState) => {
  commit('setShipmentState', shipmentState)
}

export const setShipmentEmail = ({ commit }, shipmentEmail) => {
  commit('setShipmentEmail', shipmentEmail)
}

export const setShipmentPhone = ({ commit }, shipmentPhone) => {
  commit('setShipmentPhone', shipmentPhone)
}

export const setShipmentColony = ({ commit }, shipmentColony) => {
  commit('setShipmentColony', shipmentColony)
}

export const setShipmentContact = ({ commit }, shipmentContact) => {
  commit('setShipmentContact', shipmentContact)
}

export const setShipmentAddress = ({ commit }, shipmentAddress) => {
  commit('setShipmentAddress', shipmentAddress)
}

export const setShipmentZipCode = ({ commit }, shipmentZipCode) => {
  commit('setShipmentZipCode', shipmentZipCode)
}
// #endregion

// #region Invoice address data
export const setInvoiceCity = ({ commit }, invoiceCity) => {
  commit('setInvoiceCity', invoiceCity)
}

export const setInvoiceState = ({ commit }, invoiceState) => {
  commit('setInvoiceState', invoiceState)
}

export const setInvoiceEmail = ({ commit }, invoiceEmail) => {
  commit('setInvoiceEmail', invoiceEmail)
}

export const setInvoicePhone = ({ commit }, invoicePhone) => {
  commit('setInvoicePhone', invoicePhone)
}

export const setInvoiceColony = ({ commit }, invoiceColony) => {
  commit('setInvoiceColony', invoiceColony)
}

export const setInvoiceContact = ({ commit }, invoiceContact) => {
  commit('setInvoiceContact', invoiceContact)
}

export const setInvoiceAddress = ({ commit }, invoiceAddress) => {
  commit('setInvoiceAddress', invoiceAddress)
}

export const setInvoiceZipCode = ({ commit }, invoiceZipCode) => {
  commit('setInvoiceZipCode', invoiceZipCode)
}
// #endregion

// #region State management list (products and circuits)
export const addQuoteProduct = ({ commit }, quoteProduct) => {
  commit('addQuoteProduct', quoteProduct)
}

export const updateQuoteProduct = ({ commit }, quoteProduct) => {
  commit('updateQuoteProduct', quoteProduct)
}

export const addSelectedCircuit = ({ commit }, selectedCircuit) => {
  commit('addSelectedCircuit', selectedCircuit)
}

export const deleteSelectedCircuit = ({ commit }, selectedCircuit) => {
  commit('deleteSelectedCircuit', selectedCircuit)
}

export const updateSelectedCircuit = ({ commit }, selectedCircuit) => {
  commit('updateSelectedCircuit', selectedCircuit)
}

export const cleanSelectedCircuits = ({ commit }) => {
  commit('cleanSelectedCircuits')
}

export const addQuoteCircuit = ({ commit }, quoteCircuit) => {
  commit('addQuoteCircuit', quoteCircuit)
}

export const updateQuoteCircuit = ({ commit }, quoteCircuit) => {
  commit('updateQuoteCircuit', quoteCircuit)
}

export const deleteQuoteCircuit = ({ commit }, quoteCircuit) => {
  commit('deleteQuoteCircuit', quoteCircuit)
}
// #endregion

export const addCircuitWithOfferPriceSelected = ({ commit }, circuitWithOfferPriceSelected) => {
  commit('addCircuitWithOfferPriceSelected', circuitWithOfferPriceSelected)
}

export const removeCircuitWithOfferPriceSelected = ({ commit }, circuitWithOfferPriceSelected) => {
  commit('removeCircuitWithOfferPriceSelected', circuitWithOfferPriceSelected)
}

// #region Delivery type and methods
export const setArriveDate = ({ commit }, arriveDate) => {
  commit('setArriveDate', arriveDate)
}

export const setCountToInvoice = ({ commit }, countToInvoice) => {
  commit('setCountToInvoice', countToInvoice)
}

export const setInvoiceRequired = ({ commit }, invoiceRequired) => {
  commit('setInvoiceRequired', invoiceRequired)
}

export const setSelectedDeliveryType = ({ commit }, selectedDeliveryType) => {
  commit('setSelectedDeliveryType', selectedDeliveryType)
}

export const setSelectedDeliveryMethod = ({ commit }, selectedDeliveryMethod) => {
  commit('setSelectedDeliveryMethod', selectedDeliveryMethod)
}
// #endregion

// #region Extra information
export const setDiscount = ({ commit }, discount) => {
  commit('setDiscount', discount)
}

export const setExchangeType = ({ commit }, exchangeType) => {
  commit('setExchangeType', exchangeType)
}

export const setShipmentCost = ({ commit }, shipmentCost) => {
  commit('setShipmentCost', shipmentCost)
}

export const setInstallationCost = ({ commit }, installationCost) => {
  commit('setInstallationCost', installationCost)
}
// #endregion

export const clearFormData = ({ commit }) => {
  commit('clearFormData')
}
