export default () => ({
  selectedSuppliers: [],
  listsParams: [],
  tabs: [
    {
      title: 'Productos nuevos',
      value: 'news',
      status: 'Nuevo',
    },
    {
      title: 'Productos Seminuevos',
      value: 'used',
      status: 'UsadoRemanufacturado',
    },
    {
      title: 'Circuitos',
      value: 'circuits',
      status: '',
    },
    {
      title: 'Tipo de cambio',
      value: 'money',
      status: '',
    },
  ],
  EcomerceStatus: [
    {
      text: 'activo',
    },
    {
      text: 'inactivo',
    },
  ],
  hidePrice: [
    {
      text: 'Visible',
      icon: '',
    },
    {
      text: 'Oculto',
      icon: '',
    },
  ],
  description: [
    {
      text: 'Si',
      icon: '',
    },
    {
      text: 'No',
      icon: '',
    },
  ],
  favorite: [
    {
      text: 'Ver solo destacados',
      icon: '',
    },
    {
      text: 'Ver todos',
      icon: '',
    },
  ],

  columnsList: [],
  categories: [],
})
