/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// eslint-disable-next-line no-unused-vars
export const loadPiecesProduct = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  let route = `/product/${loadParams.idProduct}/pieces`

  if (loadParams.query) {
    route = `/product/${loadParams.idProduct}/pieces?${loadParams.query}`
  }

  axios.get(route,
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadCSVFile = async ({ commit }) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get('/download-template', { responseType: 'arraybuffer' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'plantilla.xlsx'
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export const checkImportNumbers = async ({ commit }, importNumber) => new Promise((resolve, reject) => {
  axios.get(`/import-numbers?noImport=${importNumber}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// #region V-Models form (main information)
export const setPieces = ({ commit }, pieces) => {
  commit('setPieces', pieces)
}

export const setLocalPieces = ({ commit }, localPieces) => {
  commit('setLocalPieces', localPieces)
}

export const setExternalPieces = ({ commit }, externalPieces) => {
  commit('setExternalPieces', externalPieces)
}

export const setTotalExternalPieces = ({ commit }, totalExternalPieces) => {
  commit('setTotalExternalPieces', totalExternalPieces)
}

export const setPiecesToUpdate = ({ commit }, piecesToUpdate) => {
  commit('setPiecesToUpdate', piecesToUpdate)
}

export const setPiecesToDelete = ({ commit }, piecesToDelete) => {
  commit('setPiecesToDelete', piecesToDelete)
}

export const addPiece = ({ commit }, piece) => {
  commit('addPiece', piece)
}

export const addLocalPiece = ({ commit }, piece) => {
  commit('addLocalPiece', piece)
}

export const updateLocalPiece = ({ commit }, piece) => {
  commit('updateLocalPiece', piece)
}

export const deleteLocalPiece = ({ commit }, piece) => {
  commit('deleteLocalPiece', piece)
}

export const addExternalPiece = ({ commit }, piece) => {
  commit('addExternalPiece', piece)
}

export const addPieceToUpdate = ({ commit }, piece) => {
  commit('addPieceToUpdate', piece)
}

export const addPieceToDelete = ({ commit }, piece) => {
  commit('addPieceToDelete', piece)
}

export const deletePieceToUpdate = ({ commit }, piece) => {
  commit('deletePieceToUpdate', piece)
}

export const setLocalSearchTerm = ({ commit }, localSearchTerm) => {
  commit('setLocalSearchTerm', localSearchTerm)
}

export const setExternalSearchTerm = ({ commit }, externalSearchTerm) => {
  commit('setExternalSearchTerm', externalSearchTerm)
}

export const setEditablePiece = ({ commit }, editablePiece) => {
  commit('setEditablePiece', editablePiece)
}

export const setGlobalDate = ({ commit }, globalDate) => {
  commit('setGlobalDate', globalDate)
}

export const setEditionMode = ({ commit }, editionMode) => {
  commit('setEditionMode', editionMode)
}

export const setAvailablePieces = ({ commit }, availablePieces) => {
  commit('setAvailablePieces', availablePieces)
}

export const setTag = ({ commit }, tag) => {
  commit('setTag', tag)
}

export const setArriveDate = ({ commit }, arriveDate) => {
  commit('setArriveDate', arriveDate)
}

export const setSerieNumber = ({ commit }, serieNumber) => {
  commit('setSerieNumber', serieNumber)
}

export const setImportNumber = ({ commit }, importNumber) => {
  commit('setImportNumber', importNumber)
}

export const setInvoiceNumber = ({ commit }, invoiceNumber) => {
  commit('setInvoiceNumber', invoiceNumber)
}

export const setPetitionNumber = ({ commit }, petitionNumber) => {
  commit('setPetitionNumber', petitionNumber)
}

export const setSelectedLocation = ({ commit }, selectedLocation) => {
  commit('setSelectedLocation', selectedLocation)
}

export const setSelectedCondition = ({ commit }, selectedCondition) => {
  commit('setSelectedCondition', selectedCondition)
}

export const setSelectedAvailability = ({ commit }, selectedAvailability) => {
  commit('setSelectedAvailability', selectedAvailability)
}

export const setCountPiecesQuery = ({ commit }, countPiecesQuery) => {
  commit('setCountPiecesQuery', countPiecesQuery)
}

export const setProductPiecesQuery = ({ commit }, productPiecesQuery) => {
  commit('setProductPiecesQuery', productPiecesQuery)
}

export const clearPiecesInfo = ({ commit }) => {
  commit('clearPiecesInfo')
}
// #endregion
