/* eslint-disable import/prefer-default-export */
export const getRefund = state => state.refund
export const getRefundsStatusList = state => state.refundsStatusList
export const getCreatorsSuggests = state => state.creatorsSuggests
export const getPaymentMethods = state => state.paymentMethods
export const getRefundPayments = state => state.refundPayments
export const getRefundsPaymentsStatusList = state => state.refundsPaymentsStatusList

/**
* Commission statuses list for refunds list
*/
export const getCommissionStatus = state => state.commissionStatus
