export default [
  {
    path: '/comercial/comisiones',
    name: 'commissions-home',
    component: () => import('@/modules/trade/commissions/views/CommissionsHome.vue'),
    meta: {
      pageTitle: 'Comisiones',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Comisiones',
          active: true,
        },
      ],
      resource: 'Commissions',
      action: 'read',
      contentClass: 'commissions-module',
    },
  },
  {
    path: '/comercial/comisiones/nueva',
    name: 'new-commission',
    component: () => import('@/modules/trade/commissions/views/CommissionsSavingForm.vue'),
    meta: {
      pageTitle: 'Comisiones',
      breadcrumb: [
        {
          text: 'Comisiones',
        },
        {
          text: 'Comisiones',
          to: { name: 'commissions-home' },
        },
        {
          text: 'Nuevo',
          active: true,
        },
      ],
      resource: 'Commissions',
      action: 'create',
      contentClass: 'commissions-module',
      navActiveLink: 'commissions-home',
    },
  },
  {
    path: '/comercial/comisiones/actualizar/:id',
    name: 'update-commission',
    component: () => import('@/modules/trade/commissions/views/CommissionsSavingForm.vue'),
    props: true,
    meta: {
      pageTitle: 'Comisiones',
      breadcrumb: [
        {
          text: 'Comisiones',
        },
        {
          text: 'Comisiones',
          to: { name: 'commissions-home' },
        },
        {
          text: 'Actualizar',
          active: true,
        },
      ],
      resource: 'Commissions',
      action: 'update',
      contentClass: 'commissions-module',
      navActiveLink: 'commissions-home',
    },
  },
  {
    path: '/comercial/comisiones/:id',
    name: 'commission-details',
    component: () => import('@/modules/trade/commissions/views/CommissionsDetails.vue'),
    props: true,
    meta: {
      pageTitle: 'Comisiones',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Comisiones',
          to: { name: 'commissions-home' },
        },
        {
          text: 'Detalles de la comisión',
          active: true,
        },
      ],
      resource: 'Commissions',
      action: 'update',
      contentClass: 'commissions-module',
      navActiveLink: 'commissions-home',
    },
  },
]
