/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// eslint-disable-next-line no-unused-vars
export const loadPayments = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  commit('saveListParams', { ...loadParams })

  axios.get('/reports/payments',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
        cash: loadParams.cashTerm,
        locationPayment: loadParams.locationPaymentTerm,
        paymentStatus: loadParams.paymentStatusTerm,
        creator: loadParams.creatorTerm?.id,
        statusConfirmation: loadParams.statusConfirmationTerm,
        deliveryCash: loadParams.deliveryCashTerm,
        currency: loadParams.paymentCurrencyTerm,
        paymentMethod: loadParams.paymentMethodTerm,
        voucherDateStart: loadParams.voucherDateStartTerm,
        voucherDateEnd: loadParams.voucherDateEndTerm,
        registerDateStart: loadParams.registerDateStartTerm,
        registerDateEnd: loadParams.registerDateEndTerm,
        deliveryDateStart: loadParams.deliveryDateStartTerm,
        deliveryDateEnd: loadParams.deliveryDateEndTerm,
        orderStatus: loadParams.orderStatusTerm?.id,
        statusQuote: loadParams.quoteStatusTerm?.status,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadRefundPayments = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  commit('saveListParams', { ...loadParams })

  axios.get('/reports/devolutions',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
        locationPayment: loadParams.locationPaymentTerm,
        creator: loadParams.creatorTerm?.id,
        statusConfirmation: loadParams.statusConfirmationTerm,
        currency: loadParams.paymentCurrencyTerm,
        paymentMethod: loadParams.paymentMethodTerm,
        voucherDateStart: loadParams.voucherDateStartTerm,
        voucherDateEnd: loadParams.voucherDateEndTerm,
        registerDateStart: loadParams.registerDateStartTerm,
        registerDateEnd: loadParams.registerDateEndTerm,
        orderStatus: loadParams.orderStatusTerm?.id,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const saveVoucherImage = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/cash-payment-voucher', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadImage = async ({ commit }, id1) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/quotes/download-image?id=${id1}&isCashPayment=1`, {
      responseType: 'blob',
    })
      .then(response => {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'pago.jpg'
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadImageBlob = async ({ commit }, id1) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/quotes/download-image?id=${id1}&isCashPayment=1`, {
      responseType: 'blob',
    })
      .then(response => {
        const blob = new Blob([response.data], { type: 'image/jpg' })
        resolve(blob)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const confirmPayment = async ({ commit }, idPayment) => new Promise((resolve, reject) => {
  axios.post(`/cash-payment/confirm/${idPayment}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const cancelPayment = async ({ commit }, idPayment) => new Promise((resolve, reject) => {
  axios.post(`/cash-payment/cancel/${idPayment}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadCreatorsSuggests = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/creators')
    .then(response => {
      commit('setCreatorsSuggests', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const sendPaymentsCSVFile = async ({ commit }, { url, loadParams }) => new Promise((resolve, reject) => {
  const params = {
    cash: loadParams.cashTerm,
    'filter[search]': loadParams.searchTerm,
    locationPayment: loadParams.locationPaymentTerm,
    paymentStatus: loadParams.paymentStatusTerm,
    creator: loadParams.creatorTerm?.id,
    statusConfirmation: loadParams.statusConfirmationTerm,
    deliveryCash: loadParams.deliveryCashTerm,
    currency: loadParams.paymentCurrencyTerm,
    paymentMethod: loadParams.paymentMethodTerm,
    voucherDateStart: loadParams.voucherDateStartTerm,
    voucherDateEnd: loadParams.voucherDateEndTerm,
    registerDateStart: loadParams.registerDateStartTerm,
    registerDateEnd: loadParams.registerDateEndTerm,
    deliveryDateStart: loadParams.deliveryDateStartTerm,
    deliveryDateEnd: loadParams.deliveryDateEndTerm,
    orderStatus: loadParams.orderStatusTerm?.id,
    statusQuote: loadParams.quoteStatusTerm?.status,
  }

  loadParams.activedColumnsTerm.forEach((column, index) => {
    params[`active[${index}]`] = column.value
  })

  axios.get(url, { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadOrdersColumnsList = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/reports/payments/columns-list')
    .then(response => {
      commit('setOrdersColumnsList', response.data.data.columnsList)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadCashOrdersColumnsList = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/reports/payments/columns-list?cash=1')
    .then(response => {
      commit('setCashOrdersColumnsList', response.data.data.columnsList)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadRefundsColumnsList = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/reports/payments-devolutions/columns-list')
    .then(response => {
      commit('setRefundsColumnsList', response.data.data.columnsList)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadDashboardRegisteredPayments = async ({ commit, getters }) => new Promise((resolve, reject) => {
  axios.get('/metrics/register-payments',
    {
      params: {
        dateStart: getters.getDashboardDateRange.from,
        dateEnd: getters.getDashboardDateRange.to,
      },
    })
    .then(response => {
      commit('setDashboardRegisteredPaymentsList', response.data.data.payments)
      commit('setDashboardRegisteredPaymentsTotal', response.data.data.total)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadDashboardMethodsPayments = async ({ commit, getters }) => new Promise((resolve, reject) => {
  axios.get('/metrics/payments-methods',
    {
      params: {
        dateStart: getters.getDashboardDateRange.from,
        dateEnd: getters.getDashboardDateRange.to,
      },
    })
    .then(response => {
      commit('setDashboardPaymentsMethodsList', response.data.data.payments)
      commit('setDashboardPaymentsMethodsTotal', response.data.data.total)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadDashboardCashPayments = async ({ commit, getters }, isUSD = 0) => new Promise((resolve, reject) => {
  axios.get('/metrics/payments-cash',
    {
      params: {
        dateStart: getters.getDashboardDateRange.from,
        dateEnd: getters.getDashboardDateRange.to,
        usd: isUSD,
      },
    })
    .then(response => {
      if (!isUSD) {
        commit('setDashboardMXNCashPaymentsList', response.data.payments.payments)
        commit('setDashboardMXNCashPaymentsTotal', response.data.payments.total)
      } else {
        commit('setDashboardUSDCashPaymentsList', response.data.payments.payments)
        commit('setDashboardUSDCashPaymentsTotal', response.data.payments.total)
      }
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadDashboardRegisteredRefunds = async ({ commit, getters }) => new Promise((resolve, reject) => {
  axios.get('/metrics/register-refunds',
    {
      params: {
        dateStart: getters.getDashboardDateRange.from,
        dateEnd: getters.getDashboardDateRange.to,
      },
    })
    .then(response => {
      commit('setDashboardRegisteredRefundsList', response.data.data.payments)
      commit('setDashboardRegisteredRefundsTotal', response.data.data.total)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadTransactionsSellersPayments = async ({ commit, getters }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/transactions/sellers',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        dateStart: getters.getTransactionsDateRange.from,
        dateEnd: getters.getTransactionsDateRange.to,
      },
    })
    .then(response => {
      commit('setTransactionsSellersPaymentsList', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadTransactionsWholesalersPayments = async ({ commit, getters }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/transactions/wholealers',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        dateStart: getters.getTransactionsDateRange.from,
        dateEnd: getters.getTransactionsDateRange.to,
      },
    })
    .then(response => {
      commit('setTransactionsWholesalersPaymentsList', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadTransactionsCustomersPayments = async ({ commit, getters }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/transactions/customers',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        dateStart: getters.getTransactionsDateRange.from,
        dateEnd: getters.getTransactionsDateRange.to,
      },
    })
    .then(response => {
      commit('setTransactionsCustomersPaymentsList', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadDashboardXSLXFile = async ({ commit, getters }) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get('/metrics/download',
      {
        params: {
          dateStart: getters.getDashboardDateRange.from,
          dateEnd: getters.getDashboardDateRange.to,
        },
        responseType: 'arraybuffer',
      })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'dashboard.xlsx'
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadTransactionsXSLXFile = async ({ commit, getters }) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get('/transactions/download',
      {
        params: {
          dateStart: getters.getTransactionsDateRange.from,
          dateEnd: getters.getTransactionsDateRange.to,
        },
        responseType: 'arraybuffer',
      })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/xlsx' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'reporte_pagos.xlsx'
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

export const loadOrdersStatus = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/orders/order-status?payments=1')
    .then(response => {
      commit('setOrderStatus', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const setSelectedRefundPayment = ({ commit }, selectedRefundPayment) => {
  commit('setSelectedRefundPayment', selectedRefundPayment)
}

export const setFilteredDashboardDateRange = ({ commit }, filteredDashboardDateRange) => {
  commit('setFilteredDashboardDateRange', filteredDashboardDateRange)
}

export const setFilteredTransactionsDateRange = ({ commit }, filteredTransactionsDateRange) => {
  commit('setFilteredTransactionsDateRange', filteredTransactionsDateRange)
}

export const setShowDashboardCharts = ({ commit }, showDashboardCharts) => {
  commit('setShowDashboardCharts', showDashboardCharts)
}

export const saveListParams = ({ commit }, listParams) => {
  commit('saveListParams', listParams)
}

export const clearReportsPaymentsInfo = ({ commit }) => {
  commit('clearReportsPaymentsInfo')
}
