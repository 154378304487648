/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// * 08/09/2022 - TAG: Make a request to load products based on params
// eslint-disable-next-line no-unused-vars
export const loadProducts = async ({ commit }, { loadParams, resetLists }) => new Promise((resolve, reject) => {
  commit('setLoadingFilteredLists', true)

  axios.get(`/${loadParams.resource}`,
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
        brand: loadParams.brandTerm,
        category: loadParams.categoryTerm,
        tipo: loadParams.typeTerm,
        location: loadParams.locationTerm,
        except: loadParams.exceptTerm,
        qtyActive: loadParams.qtyActive,
        checkTransfer: loadParams.checkTransfer,
        pendingImage: loadParams.pendingImageTerm,
        priceToBeDefined: loadParams.priceToBeDefinedTerm,
      },
      contentType: 'application/json',
    })
    .then(response => {
      if (loadParams.resource === 'products') {
        commit('setFilteredProductsCount', response.data.data.products.total)
        commit('setFilteredOutInventoryProductsCount', response.data.data.count_out_stock)
        commit('setFilteredLowInventoryProductsCount', response.data.data.count_low_inventory)
      } else if (loadParams.resource === 'products?inventory=low') {
        if (resetLists) {
          commit('setFilteredProductsCount', null)
          commit('setFilteredOutInventoryProductsCount', null)
        }
        commit('setFilteredLowInventoryProductsCount', response.data.data.count_low_inventory)
      } else if (loadParams.resource === 'products?inventory=out') {
        if (resetLists) {
          commit('setFilteredProductsCount', null)
          commit('setFilteredLowInventoryProductsCount', null)
        }
        commit('setFilteredOutInventoryProductsCount', response.data.data.count_out_stock)
      }

      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
    .then(() => {
      commit('setLoadingFilteredLists', false)
    })
})

export const loadTapestryColors = async ({ commit }) => {
  commit('setLoadingColors', true)

  return new Promise((resolve, reject) => {
    axios.get('/colors-tapestry')
      .then(response => {
        commit('setTapestryColors', response.data.colors)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
      .then(() => {
        commit('setLoadingColors', false)
      })
  })
}

export const loadPaintColors = async ({ commit }) => {
  commit('setLoadingColors', true)

  return new Promise((resolve, reject) => {
    axios.get('/colors-paint')
      .then(response => {
        commit('setPaintColors', response.data.colors)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
      .then(() => {
        commit('setLoadingColors', false)
      })
  })
}

export const loadSuggests = async ({ commit }) => {
  commit('setLoadingSuggests', true)

  return new Promise((resolve, reject) => {
    axios.get('/products-search')
      .then(response => {
        commit('setSuggests', response.data.data)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
      .then(() => {
        commit('setLoadingSuggests', false)
      })
  })
}

// * 12/09/2022 - TAG: Make a request to get single product
export const loadProduct = async ({ commit }, {
  id, count, visible, withPieces = false,
}) => {
  commit('setLoadingProducts', true)

  let route = `/products/show?id=${id}`

  if (count) {
    route = `/products/show?id=${id}&visible=${visible}&count=${count}`
  }

  if (withPieces) {
    route = `/products/show?id=${id}&withPieces=1`
  }

  return new Promise((resolve, reject) => {
    axios.get(route)
      .then(response => {
        commit('setProduct', response.data.data.product)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
      .then(() => {
        commit('setLoadingProducts', false)
      })
  })
}

// * 12/09/2022 - TAG: Make a request to get single product
export const loadProduct64 = async ({ commit }, { id, visible }) => {
  commit('setLoadingProducts', true)

  return new Promise((resolve, reject) => {
    axios.get(`/products/show?id=${id}&b64=true&visible=${visible}`)
      .then(response => {
        commit('setProduct', response.data.data.product)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
      .then(() => {
        commit('setLoadingProducts', false)
      })
  })
}

export const createProduct = async ({ commit }, formData) => {
  commit('setLoadingProducts', true)

  return new Promise((resolve, reject) => {
    axios.post('/products/save', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: 'El producto se ha creado correctamente.',
        })
        console.log(response, 'en el salvado Correcto')
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: error.error,
        })
        console.log(error, 'en el salvado Incorrecto')
        reject(error)
      })
      .then(() => {
        commit('setLoadingProducts', false)
      })
  })
}

export const updateProduct = async ({ commit }, formData) => {
  commit('setLoadingProducts', true)
  formData.append('_method', 'PUT')

  return new Promise((resolve, reject) => {
    axios.post('/products/update', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: 'El producto se ha actualizado correctamente.',
        })
        console.log(response, 'en el actualizado Correcto')
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: error.error,
        })
        console.log(error, 'en el actualizado Incorrecto')
        reject(error)
      })
      .then(() => {
        commit('setLoadingProducts', false)
      })
  })
}

export const saveProductImages = async ({ commit }, formData) => {
  commit('setLoadingProducts', true)

  return new Promise((resolve, reject) => {
    axios.post('/images/save', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: 'El producto se ha creado correctamente.',
        })
        console.log(response, 'en el salvado Correcto')
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: error.error,
        })
        console.log(error, 'en el salvado Incorrecto')
        reject(error)
      })
      .then(() => {
        commit('setLoadingProducts', false)
      })
  })
}

export const deleteProduct = async ({ commit }, id) => {
  commit('setLoadingProducts', true)

  return new Promise((resolve, reject) => {
    axios.delete(`/products/delete?idProduct=${id}`)
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: 'El producto se ha eliminado correctamente.',
        })
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: error.error,
        })
        reject(error)
      })
      .then(() => {
        commit('setLoadingProducts', false)
      })
  })
}

export const deleteProductAndTransferPieces = async ({ commit }, products) => {
  commit('setLoadingProducts', true)

  return new Promise((resolve, reject) => {
    axios.delete(`/products/transfer?idProduct=${products.from}&newProduct=${products.to}`)
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: 'Las piezas se han transferido correctamente.',
        })
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: error.error,
        })
        reject(error)
      })
      .then(() => {
        commit('setLoadingProducts', false)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export const sendProductsEmail = async ({ commit }, url) => new Promise((resolve, reject) => {
  axios.get(url)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const setLoadingProducts = ({ commit }, loading) => {
  commit('setLoadingProducts', loading)
}

// 09/09/2022 - TAG: Set the search term for brand searching
export const setLocationTerm = ({ commit }, locationTerm) => {
  commit('setLocationTerm', locationTerm)
}

export const setSearchTerm = ({ commit }, searchTerm) => {
  commit('setSearchTerm', searchTerm)
}

// 14/09/2022 - TAG: Set the current page on brand list pagination
export const setCurrentPage = ({ commit }, currentPage) => {
  commit('setCurrentPage', currentPage)
}

// 14/09/2022 - TAG: Set the count per page for brand list pagination
export const setPerPage = ({ commit }, perPage) => {
  commit('setPerPage', perPage)
}

// #region V-Models form (main information)
export const setRealCount = ({ commit }, realCount) => {
  commit('setRealCount', realCount)
}

export const setSelectedType = ({ commit }, selectedType) => {
  commit('setSelectedType', selectedType)
}

export const setSelectedDate = ({ commit }, selectedDate) => {
  commit('setSelectedDate', selectedDate)
}

export const setPreSale = ({ commit }, preSale) => {
  commit('setPreSale', preSale)
}

export const setTransferableProductId = ({ commit }, transferableProductId) => {
  commit('setTransferableProductId', transferableProductId)
}
// #endregion

export const setOriginProductRoute = ({ commit }, originProductRoute) => {
  commit('setOriginProductRoute', originProductRoute)
}

// * 17/05/2023 - TAG: Dimensiones del producto
export const setWidth = ({ commit }, width) => {
  commit('setWidth', width)
}

export const setHeight = ({ commit }, height) => {
  commit('setHeight', height)
}

export const setWeight = ({ commit }, weight) => {
  commit('setWeight', weight)
}

export const setLength = ({ commit }, length) => {
  commit('setLength', length)
}

export const setSelectedWeight = ({ commit }, selectedWeight) => {
  commit('setSelectedWeight', selectedWeight)
}

// * 17/05/2023 - TAG: Precios del producto
export const setCostUtility = ({ commit }, costUtility) => {
  commit('setCostUtility', costUtility)
}

export const setExchangeRate = ({ commit }, exchangeRate) => {
  commit('setExchangeRate', exchangeRate)
}

export const setProvidersPriceUsd = ({ commit }, providersPriceUsd) => {
  commit('setProvidersPriceUsd', providersPriceUsd)
}

export const setUnitPriceInvoiceMn = ({ commit }, unitPriceInvoiceMn) => {
  commit('setUnitPriceInvoiceMn', unitPriceInvoiceMn)
}

export const setProvidersCostingMn = ({ commit }, providersCostingMn) => {
  commit('setProvidersCostingMn', providersCostingMn)
}

export const setUnitPriceInvoiceUsd = ({ commit }, unitPriceInvoiceUsd) => {
  commit('setUnitPriceInvoiceUsd', unitPriceInvoiceUsd)
}

// * 18/05/2023 - TAG: Precios de lista de productos nuevos y remanufacturados
export const setMinNormalPrice = ({ commit }, minNormalPrice) => {
  commit('setMinNormalPrice', minNormalPrice)
}

export const setMaxNormalPrice = ({ commit }, maxNormalPrice) => {
  commit('setMaxNormalPrice', maxNormalPrice)
}

export const setBackMinNormalPrice = ({ commit }, backMinNormalPrice) => {
  commit('setBackMinNormalPrice', backMinNormalPrice)
}

export const setBackMaxNormalPrice = ({ commit }, backMaxNormalPrice) => {
  commit('setBackMaxNormalPrice', backMaxNormalPrice)
}

export const setPriceToBeDecided = ({ commit }, priceToBeDecided) => {
  commit('setPriceToBeDecided', priceToBeDecided)
}

export const setWholesalerPrice = ({ commit }, wholesalerPrice) => {
  commit('setWholesalerPrice', wholesalerPrice)
}

export const setMinWholesalerPrice = ({ commit }, minWholesalerPrice) => {
  commit('setMinWholesalerPrice', minWholesalerPrice)
}

export const setMaxWholesalerPrice = ({ commit }, maxWholesalerPrice) => {
  commit('setMaxWholesalerPrice', maxWholesalerPrice)
}

export const setBackMinWholesalerPrice = ({ commit }, backMinWholesalerPrice) => {
  commit('setBackMinWholesalerPrice', backMinWholesalerPrice)
}

export const setBackMaxWholesalerPrice = ({ commit }, backMaxWholesalerPrice) => {
  commit('setBackMaxWholesalerPrice', backMaxWholesalerPrice)
}

// * 18/05/2023 - TAG: Precios de los productos usados
export const setLfPrice = ({ commit }, lfPrice) => {
  commit('setLfPrice', lfPrice)
}

export const setDrPrice = ({ commit }, drPrice) => {
  commit('setDrPrice', drPrice)
}

export const setSuggestMaxPrice = ({ commit }, suggestMaxPrice) => {
  commit('setSuggestMaxPrice', suggestMaxPrice)
}

export const setBackLfPrice = ({ commit }, backLfPrice) => {
  commit('setBackLfPrice', backLfPrice)
}

export const setBackDrPrice = ({ commit }, backDrPrice) => {
  commit('setBackDrPrice', backDrPrice)
}

export const setBackSuggestMaxPrice = ({ commit }, backSuggestMaxPrice) => {
  commit('setBackSuggestMaxPrice', backSuggestMaxPrice)
}

// 04/09/2023 - TAG: Precio de oferta
export const setOfferPrice = ({ commit }, offerPrice) => {
  commit('setOfferPrice', offerPrice)
}

export const setBackOfferPrice = ({ commit }, backOfferPrice) => {
  commit('setBackOfferPrice', backOfferPrice)
}

// * 18/05/2023 - TAG: Información general del producto
export const setName = ({ commit }, name) => {
  commit('setName', name)
}

export const setItem = ({ commit }, item) => {
  commit('setItem', item)
}

export const setModel = ({ commit }, model) => {
  commit('setModel', model)
}

export const setImages = ({ commit }, images) => {
  commit('setImages', images)
}

export const setImage01 = ({ commit }, image) => {
  commit('setImage01', image)
}

export const setImage02 = ({ commit }, image) => {
  commit('setImage02', image)
}

export const setImage03 = ({ commit }, image) => {
  commit('setImage03', image)
}

export const setSatCode = ({ commit }, satCode) => {
  commit('setSatCode', satCode)
}

export const setLowCount = ({ commit }, lowCount) => {
  commit('setLowCount', lowCount)
}

export const setBackItem = ({ commit }, backItem) => {
  commit('setBackItem', backItem)
}

export const setIdProduct = ({ commit }, idProduct) => {
  commit('setIdProduct', idProduct)
}

export const setSubmitted = ({ commit }, submitted) => {
  commit('setSubmitted', submitted)
}

export const setDestination = ({ commit }, destination) => {
  commit('setDestination', destination)
}

export const setDescription = ({ commit }, description) => {
  commit('setDescription', description)
}

export const setInternalCode = ({ commit }, internalCode) => {
  commit('setInternalCode', internalCode)
}

export const setSelectedBrand = ({ commit }, selectedBrand) => {
  commit('setSelectedBrand', selectedBrand)
}

export const setSelectedPaint = ({ commit }, selectedPaint) => {
  commit('setSelectedPaint', selectedPaint)
}

export const setPendingImage = ({ commit }, pendingImage) => {
  commit('setPendingImage', pendingImage)
}

export const setImagesLoaded = ({ commit }, imagesLoaded) => {
  commit('setImagesLoaded', imagesLoaded)
}

export const setFilteredOptions = ({ commit }, filteredOptions) => {
  commit('setFilteredOptions', filteredOptions)
}

export const setLoadingDataForm = ({ commit }, loadingDataForm) => {
  commit('setLoadingDataForm', loadingDataForm)
}

export const setSelectedCategory = ({ commit }, selectedCategory) => {
  commit('setSelectedCategory', selectedCategory)
}

export const setSelectedTapestry = ({ commit }, selectedTapestry) => {
  commit('setSelectedTapestry', selectedTapestry)
}

export const setSelectedSupplier = ({ commit }, selectedSupplier) => {
  commit('setSelectedSupplier', selectedSupplier)
}

export const setCanceledTypeChange = ({ commit }, canceledTypeChange) => {
  commit('setCanceledTypeChange', canceledTypeChange)
}

export const setBackSelectedSupplier = ({ commit }, backSelectedSupplier) => {
  commit('setBackSelectedSupplier', backSelectedSupplier)
}

export const setProduct = ({ commit }, product) => {
  commit('setProduct', product)
}

export const clearPrices = ({ commit }) => {
  commit('clearPrices')
}

export const clearProductInfo = ({ commit }) => {
  commit('clearProductInfo')
}
