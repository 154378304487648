/* eslint-disable import/prefer-default-export */

// * 14/09/2022 - TAG: Set all the products for products list view
export const setProducts = (state, products) => {
  state.products = products
}

// * 16/09/2022 - TAG: Used on requests from wainting interactions
export const setLoadingProducts = (state, isLoadingProducts) => {
  state.isLoadingProducts = isLoadingProducts
}

// * 14/09/2022 - TAG: Set the state response based on axios requests
export const setStateResponse = (state, stateResponse) => {
  state.stateResponse = stateResponse
}

// * 14/09/2022 - TAG: Set the colors obtained from server to show on select form
export const setTapestryColors = (state, tapestryColors) => {
  state.tapestryColors = tapestryColors
}

// * 14/09/2022 - TAG: Set the colors obtained from server to show on select form
export const setPaintColors = (state, paintColors) => {
  state.paintColors = paintColors
}

// * 14/09/2022 - TAG: Set the suggests obtained from server to show on select form
export const setSuggests = (state, suggests) => {
  state.suggests = suggests
}

// * 14/09/2022 - TAG: Set the loading responses state for colors select form
export const setLoadingColors = (state, isLoadingColors) => {
  state.isLoadingColors = isLoadingColors
}

// * 14/09/2022 - TAG: Set the loading responses state for suggests name form
export const setLoadingSuggests = (state, isLoadingSuggests) => {
  state.isLoadingSuggests = isLoadingSuggests
}

// * 14/09/2022 - TAG: Set the product for deitails view
export const setProduct = (state, product) => {
  state.product = product
}

// #region Pagination attributes
export const setLocationTerm = (state, locationTerm) => {
  state.locationTerm = locationTerm
}

export const setSearchTerm = (state, searchTerm) => {
  state.searchTerm = searchTerm
}

export const setTotalProducts = (state, totalProducts) => {
  state.totalProducts = totalProducts
}

export const setCurrentPage = (state, currentPage) => {
  state.currentPage = currentPage
}

export const setPerPage = (state, perPage) => {
  state.perPage = perPage
}
// #endregion
// #region V-Models form (main information)
export const setRealCount = (state, realCount) => {
  state.realCount = realCount
}

export const setSelectedType = (state, selectedType) => {
  state.selectedType = selectedType
}

export const setSelectedDate = (state, selectedDate) => {
  state.selectedDate = selectedDate
}

export const setPreSale = (state, preSale) => {
  state.preSale = preSale
}

export const setTransferableProductId = (state, transferableProductId) => {
  state.transferableProductId = transferableProductId
}
// #endregion

export const setOriginProductRoute = (state, originProductRoute) => {
  state.originProductRoute = originProductRoute
}

// * 21/04/2023 - TAG: For global inventory filter control
export const setFilteredProductsCount = (state, filteredProductsCount) => {
  state.filteredProductsCount = filteredProductsCount
}

export const setFilteredLowInventoryProductsCount = (state, filteredLowInventoryProductsCount) => {
  state.filteredLowInventoryProductsCount = filteredLowInventoryProductsCount
}

export const setFilteredOutInventoryProductsCount = (state, filteredOutInventoryProductsCount) => {
  state.filteredOutInventoryProductsCount = filteredOutInventoryProductsCount
}

export const setLoadingFilteredLists = (state, loadingFilteredLists) => {
  state.loadingFilteredLists = loadingFilteredLists
}

// * 17/05/2023 - TAG: Dimensiones del producto
export const setWidth = (state, width) => {
  state.width = width
}

export const setHeight = (state, height) => {
  state.height = height
}

export const setWeight = (state, weight) => {
  state.weight = weight
}

export const setLength = (state, length) => {
  state.length = length
}

export const setSelectedWeight = (state, selectedWeight) => {
  state.selectedWeight = selectedWeight
}

// * 17/05/2023 - TAG: Precios del producto
export const setCostUtility = (state, costUtility) => {
  state.costUtility = costUtility
}

export const setExchangeRate = (state, exchangeRate) => {
  state.exchangeRate = exchangeRate
}

export const setProvidersPriceUsd = (state, providersPriceUsd) => {
  state.providersPriceUsd = providersPriceUsd
}

export const setUnitPriceInvoiceMn = (state, unitPriceInvoiceMn) => {
  state.unitPriceInvoiceMn = unitPriceInvoiceMn
}

export const setProvidersCostingMn = (state, providersCostingMn) => {
  state.providersCostingMn = providersCostingMn
}

export const setUnitPriceInvoiceUsd = (state, unitPriceInvoiceUsd) => {
  state.unitPriceInvoiceUsd = unitPriceInvoiceUsd
}

// * 18/05/2023 - TAG: Precios de lista de productos nuevos y remanufacturados
export const setMinNormalPrice = (state, minNormalPrice) => {
  state.minNormalPrice = minNormalPrice
}

export const setMaxNormalPrice = (state, maxNormalPrice) => {
  state.maxNormalPrice = maxNormalPrice
}

export const setBackMinNormalPrice = (state, backMinNormalPrice) => {
  state.backMinNormalPrice = backMinNormalPrice
}

export const setBackMaxNormalPrice = (state, backMaxNormalPrice) => {
  state.backMaxNormalPrice = backMaxNormalPrice
}

export const setPriceToBeDecided = (state, priceToBeDecided) => {
  state.priceToBeDecided = priceToBeDecided
}

export const setWholesalerPrice = (state, wholesalerPrice) => {
  state.wholesalerPrice = wholesalerPrice
}

export const setMinWholesalerPrice = (state, minWholesalerPrice) => {
  state.minWholesalerPrice = minWholesalerPrice
}

export const setMaxWholesalerPrice = (state, maxWholesalerPrice) => {
  state.maxWholesalerPrice = maxWholesalerPrice
}

export const setBackMinWholesalerPrice = (state, backMinWholesalerPrice) => {
  state.backMinWholesalerPrice = backMinWholesalerPrice
}

export const setBackMaxWholesalerPrice = (state, backMaxWholesalerPrice) => {
  state.backMaxWholesalerPrice = backMaxWholesalerPrice
}

// * 18/05/2023 - TAG: Precios de los productos usados
export const setLfPrice = (state, lfPrice) => {
  state.lfPrice = lfPrice
}

export const setDrPrice = (state, drPrice) => {
  state.drPrice = drPrice
}

export const setSuggestMaxPrice = (state, suggestMaxPrice) => {
  state.suggestMaxPrice = suggestMaxPrice
}

export const setBackLfPrice = (state, backLfPrice) => {
  state.backLfPrice = backLfPrice
}

export const setBackDrPrice = (state, backDrPrice) => {
  state.backDrPrice = backDrPrice
}

export const setBackSuggestMaxPrice = (state, backSuggestMaxPrice) => {
  state.backSuggestMaxPrice = backSuggestMaxPrice
}

// * 04/09/2023 - TAG: Precio de oferta

export const setOfferPrice = (state, offerPrice) => {
  state.offerPrice = offerPrice
}

export const setBackOfferPrice = (state, backOfferPrice) => {
  state.backOfferPrice = backOfferPrice
}

// * 18/05/2023 - TAG: Información general del producto
export const setName = (state, name) => {
  state.name = name
}

export const setItem = (state, item) => {
  state.item = item
}

export const setModel = (state, model) => {
  state.model = model
}

export const setImages = (state, images) => {
  state.images = images
}

export const setImage01 = (state, image) => {
  state.image01 = image
}

export const setImage02 = (state, image) => {
  state.image02 = image
}

export const setImage03 = (state, image) => {
  state.image03 = image
}

export const setSatCode = (state, satCode) => {
  state.satCode = satCode
}

export const setLowCount = (state, lowCount) => {
  state.lowCount = lowCount
}

export const setBackItem = (state, backItem) => {
  state.backItem = backItem
}

export const setIdProduct = (state, idProduct) => {
  state.idProduct = idProduct
}

export const setSubmitted = (state, submitted) => {
  state.submitted = submitted
}

export const setDestination = (state, destination) => {
  state.destination = destination
}

export const setDescription = (state, description) => {
  state.description = description
}

export const setInternalCode = (state, internalCode) => {
  state.internalCode = internalCode
}

export const setSelectedBrand = (state, selectedBrand) => {
  state.selectedBrand = selectedBrand
}

export const setSelectedPaint = (state, selectedPaint) => {
  state.selectedPaint = selectedPaint
}

export const setPendingImage = (state, pendingImage) => {
  state.pendingImage = pendingImage
}

export const setImagesLoaded = (state, imagesLoaded) => {
  state.imagesLoaded = imagesLoaded
}

export const setFilteredOptions = (state, filteredOptions) => {
  state.filteredOptions = filteredOptions
}

export const setLoadingDataForm = (state, loadingDataForm) => {
  state.loadingDataForm = loadingDataForm
}

export const setSelectedCategory = (state, selectedCategory) => {
  state.selectedCategory = selectedCategory
}

export const setSelectedTapestry = (state, selectedTapestry) => {
  state.selectedTapestry = selectedTapestry
}

export const setSelectedSupplier = (state, selectedSupplier) => {
  state.selectedSupplier = selectedSupplier
}

export const setCanceledTypeChange = (state, canceledTypeChange) => {
  state.canceledTypeChange = canceledTypeChange
}

export const setBackSelectedSupplier = (state, backSelectedSupplier) => {
  state.backSelectedSupplier = backSelectedSupplier
}

// Limpia todos los precios de lista de los componentes
export const clearPrices = state => {
  state.lfPrice = ''
  state.drPrice = ''
  state.minNormalPrice = ''
  state.maxNormalPrice = ''
  state.suggestMaxPrice = ''
  state.minWholesalerPrice = ''
  state.maxWholesalerPrice = ''
  state.wholesalerPrice = false
  state.offerPrice = ''
}

export const clearProductInfo = state => {
  // * 17/05/2023 - TAG: Dimensiones del producto
  state.width = ''
  state.height = ''
  state.weight = ''
  state.length = ''
  state.selectedWeight = {
    text: 'kg',
    value: 'KG',
  }

  // * 17/05/2023 - TAG: Precios del producto
  state.costUtility = ''
  state.exchangeRate = ''
  state.providersPriceUsd = ''
  state.unitPriceInvoiceMn = ''
  state.providersCostingMn = ''
  state.unitPriceInvoiceUsd = ''

  state.lfPrice = ''
  state.drPrice = ''
  state.suggestMaxPrice = ''

  state.minNormalPrice = ''
  state.maxNormalPrice = ''
  state.minWholesalerPrice = ''
  state.maxWholesalerPrice = ''

  state.priceToBeDecided = false
  state.wholesalerPrice = false

  state.offerPrice = ''

  state.backMinNormalPrice = ''
  state.backMaxNormalPrice = ''
  state.backMinWholesalerPrice = ''
  state.backMaxWholesalerPrice = ''
  state.backLfPrice = ''
  state.backDrPrice = ''
  state.backSuggestMaxPrice = ''
  state.backOfferPrice = ''

  state.name = ''
  state.item = ''
  state.model = ''
  state.images = []
  state.satCode = ''
  state.lowCount = 0
  state.backItem = ''
  state.image01 = null
  state.image02 = null
  state.image03 = null
  state.idProduct = ''
  state.destination = ''
  state.description = ''
  state.submitted = false
  state.internalCode = ''
  state.selectedBrand = ''
  state.selectedPaint = ''
  state.imagesLoaded = false
  state.filteredOptions = []
  state.selectedCategory = ''
  state.selectedTapestry = ''
  state.selectedSupplier = ''
  state.loadingDataForm = true
  state.canceledTypeChange = false

  state.selectedType = ''
  state.selectedDate = ''
  state.preSale = false
}
