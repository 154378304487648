/* eslint-disable import/prefer-default-export */
export const setOrdersColumnsList = (state, ordersColumnsList) => {
  state.ordersColumnsList = ordersColumnsList
}

export const setRefundsColumnsList = (state, refundsColumnsList) => {
  state.refundsColumnsList = refundsColumnsList
}

export const setCashOrdersColumnsList = (state, cashOrdersColumnsList) => {
  state.cashOrdersColumnsList = cashOrdersColumnsList
}

export const setCreatorsSuggests = (state, creatorsSuggests) => {
  state.creatorsSuggests = creatorsSuggests
}

export const setSelectedRefundPayment = (state, selectedRefundPayment) => {
  state.selectedRefundPayment = selectedRefundPayment
}

export const setFilteredDashboardDateRange = (state, filteredDashboardDateRange) => {
  state.filteredDashboardDateRange = filteredDashboardDateRange
}

/**
 * Listas del dashboard
 */
export const setDashboardPaymentsMethodsList = (state, dashboardPaymentsMethodsList) => {
  state.dashboardPaymentsMethodsList = dashboardPaymentsMethodsList
}

export const setDashboardMXNCashPaymentsList = (state, dashboardMXNCashPaymentsList) => {
  state.dashboardMXNCashPaymentsList = dashboardMXNCashPaymentsList
}

export const setDashboardUSDCashPaymentsList = (state, dashboardUSDCashPaymentsList) => {
  state.dashboardUSDCashPaymentsList = dashboardUSDCashPaymentsList
}

export const setDashboardRegisteredRefundsList = (state, dashboardRegisteredRefundsList) => {
  state.dashboardRegisteredRefundsList = dashboardRegisteredRefundsList
}

export const setDashboardRegisteredPaymentsList = (state, dashboardRegisteredPaymentsList) => {
  state.dashboardRegisteredPaymentsList = dashboardRegisteredPaymentsList
}

/**
 * Totales del dashboard
 */
export const setDashboardPaymentsMethodsTotal = (state, dashboardPaymentsMethodsTotal) => {
  state.dashboardPaymentsMethodsTotal = dashboardPaymentsMethodsTotal
}

export const setDashboardMXNCashPaymentsTotal = (state, dashboardMXNCashPaymentsTotal) => {
  state.dashboardMXNCashPaymentsTotal = dashboardMXNCashPaymentsTotal
}

export const setDashboardUSDCashPaymentsTotal = (state, dashboardUSDCashPaymentsTotal) => {
  state.dashboardUSDCashPaymentsTotal = dashboardUSDCashPaymentsTotal
}

export const setDashboardRegisteredRefundsTotal = (state, dashboardRegisteredRefundsTotal) => {
  state.dashboardRegisteredRefundsTotal = dashboardRegisteredRefundsTotal
}

export const setDashboardRegisteredPaymentsTotal = (state, dashboardRegisteredPaymentsTotal) => {
  state.dashboardRegisteredPaymentsTotal = dashboardRegisteredPaymentsTotal
}

/**
 * Filtro de rango de fechas de transacciones
 */
export const setFilteredTransactionsDateRange = (state, filteredTransactionsDateRange) => {
  state.filteredTransactionsDateRange = filteredTransactionsDateRange
}

/**
 * Listas de transacciones
 */
export const setTransactionsSellersPaymentsList = (state, transactionsSellersPaymentsList) => {
  state.transactionsSellersPaymentsList = transactionsSellersPaymentsList
}

export const setTransactionsCustomersPaymentsList = (state, transactionsCustomersPaymentsList) => {
  state.transactionsCustomersPaymentsList = transactionsCustomersPaymentsList
}

export const setTransactionsWholesalersPaymentsList = (state, transactionsWholesalersPaymentsList) => {
  state.transactionsWholesalersPaymentsList = transactionsWholesalersPaymentsList
}

export const setOrderStatus = (state, orderStatus) => {
  state.orderStatus = orderStatus
}

export const setShowDashboardCharts = (state, showDashboardCharts) => {
  state.showDashboardCharts = showDashboardCharts
}

/**
 * Almacena los parametro de filtrado y paginado de cada pestaña
 */
export const saveListParams = (state, listParams) => {
  const index = state.listsParams.findIndex(params => params.title === listParams.title)
  if (index === -1) {
    state.listsParams.push(listParams)
  } else {
    state.listsParams.splice(index, 1, listParams)
  }
}

export const clearReportsPaymentsInfo = state => {
  state.listsParams = []
  state.ordersColumnsList = []
  state.refundsColumnsList = []
  state.cashOrdersColumnsList = []
  state.creatorsSuggests = []
  state.selectedRefundPayment = null
  state.filteredDashboardDateRange = null
  state.filteredTransactionsDateRange = null

  state.dashboardPaymentsMethodsList = []
  state.dashboardMXNCashPaymentsList = []
  state.dashboardUSDCashPaymentsList = []
  state.dashboardRegisteredRefundsList = []
  state.dashboardRegisteredPaymentsList = []

  state.dashboardPaymentsMethodsTotal = null
  state.dashboardMXNCashPaymentsTotal = null
  state.dashboardUSDCashPaymentsTotal = null
  state.dashboardRegisteredRefundsTotal = null
  state.dashboardRegisteredPaymentsTotal = null
}
