/* eslint-disable import/prefer-default-export */
export const listenerParmasDashboard = (state, params) => {
  state.paramsDashboard = params
}

export const setTotalSale = (state, totalSale) => {
  state.totalSale = totalSale
}

export const setRegisteredPayments = (state, registeredPayments) => {
  state.registeredPayments = registeredPayments
}

export const setTotalSaleRangeDates = (state, totalSaleRangeDates) => {
  state.totalSaleRangeDates = totalSaleRangeDates
}

export const setRegisteredPaymentsRangeDates = (state, registeredPaymentsRangeDates) => {
  state.registeredPaymentsRangeDates = registeredPaymentsRangeDates
}
