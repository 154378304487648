export default () => ({
  /**
   * Orders status for orders list
   */
  ordersStatus: [],

  /**
   * Orders payment status for orders list
   */
  paymentsStatus: [],

  /**
   * Data for filter clients and creators in orders list
   */
  clientsSuggests: [],
  creatorsSuggests: [],

  /**
   * Delivery types for orders
   */
  deliveryTypes: [
    {
      id: 6,
      text: 'Entrega completa',
    },
    {
      id: 5,
      text: 'Entrega parcial',
    },
  ],

  /**
   * Invoiced options for orders
   */
  invoicedOptions: [
    {
      text: 'Si',
    },
    {
      text: 'No',
    },
  ],

  /**
   * Products list and its pieces for order details
   */
  searchTerm: '',
  detailProducts: [],
  detailCircuits: [],
  detailProductsPieces: [],

  /**
   * For making partial delivery
   */
  partialDeliveryProvitionalProducts: [],
  partialDeliveryProvitionalCircuits: [],
  partialDeliveryProvitionalProductsPieces: [],

  /**
   * Order details
   */
  order: null,

  /**
   * Used for indicating if the order is in edition mode
   */
  editionModeState: false,

  /**
   * Locations for orders
   */
  locations: [
    {
      text: 'Bodega CDMX',
    },
    {
      text: 'Bodega GDL',
    },
    {
      text: 'Tienda',
    },
  ],

  selectedLocation: '',

  countToInvoice: 0,
  invoiceRequired: 'No',

  /**
   * Used for button Refund to make complete or partial refund, or nothing
   */
  canMakeRefund: '',

  circuitsWithOfferPriceSelected: [],
  listsParams: [],
  columnsList: [],

  /**
   * Commission statuses list for orders list
   */
  commissionStatus: [
    {
      text: 'Pendiente',
    },
    {
      text: 'Pagado',
    },
    {
      text: 'Por pagar',
    },
  ],
})
