/* eslint-disable import/prefer-default-export */
export const getCustomers = state => state.customers

// * 10/11/2022 - TAG: Global id for form interactions
export const getIdQuote = state => state.idQuote
export const getIdCustomer = state => state.idCustomer
export const getIdInvoiceAddress = state => state.idInvoiceAddress
export const getIdShipmentAddress = state => state.idShipmentAddress

// * 01/11/2022 - TAG: Selected customer from customer information select
export const getSelectedCustomer = state => state.selectedCustomer

// * 01/11/2022 - TAG: Customer data
export const getCustomerType = state => state.customerType
export const getCustomerPhone = state => state.customerPhone
export const getCustomerEmail = state => state.customerEmail
export const getCustomerLastName = state => state.customerLastName
export const getCustomerFirstName = state => state.customerFirstName

// * 31/10/2022 - TAG: Invoice data
export const getRFC = state => state.RFC
export const getCFDIUssage = state => state.CFDIUssage
export const getBusinessName = state => state.BusinessName

// * 01/11/2022 - TAG: Shipment address data
export const getShipmentCity = state => state.shipmentCity
export const getShipmentState = state => state.shipmentState
export const getShipmentEmail = state => state.shipmentEmail
export const getShipmentPhone = state => state.shipmentPhone
export const getShipmentColony = state => state.shipmentColony
export const getShipmentContact = state => state.shipmentContact
export const getShipmentAddress = state => state.shipmentAddress
export const getShipmentZipCode = state => state.shipmentZipCode

// * 01/11/2022 - TAG: Invoice address data
export const getInvoiceCity = state => state.invoiceCity
export const getInvoiceState = state => state.invoiceState
export const getInvoiceEmail = state => state.invoiceEmail
export const getInvoicePhone = state => state.invoicePhone
export const getInvoiceColony = state => state.invoiceColony
export const getInvoiceContact = state => state.invoiceContact
export const getInvoiceAddress = state => state.invoiceAddress
export const getInvoiceZipCode = state => state.invoiceZipCode

// * 03/11/2022 - TAG: State management list (products and circuits)
export const getQuotePieces = state => state.quotePieces
export const getQuoteProducts = state => state.quoteProducts

export const getQuoteCircuits = state => state.quoteCircuits
export const getQuoteSelectedCircuits = state => state.quoteSelectedCircuits
export const getQuoteCircuitsProducts = state => state.quoteCircuitsProducts

// * 14/09/2022 - TAG: Delivery type and methods
export const getDeliveryTypes = state => state.deliveryTypes
export const getDeliveryMethods = state => state.deliveryMethods

export const getArriveDate = state => state.arriveDate
export const getCountToInvoice = state => state.countToInvoice
export const getInvoiceRequired = state => state.invoiceRequired
export const getSelectedDeliveryType = state => state.selectedDeliveryType
export const getSelectedDeliveryMethod = state => state.selectedDeliveryMethod

// * 16/11/2022 - TAG: Total quote
export const getSubtotalUSD = state => {
  let subtotal = 0

  state.quoteProducts.forEach(quoteProduct => {
    subtotal += quoteProduct.unitPrice * quoteProduct.pieces.length
  })

  state.quoteCircuits.forEach(quoteCircuit => {
    quoteCircuit.products.forEach(product => {
      subtotal += product.unitPrice * product.pieces.length
    })
  })

  state.circuitsWithOfferPriceSelected.forEach(quoteCircuit => {
    subtotal += quoteCircuit.OfferPrice
  })

  return subtotal
}

export const getSubtotalMXN = (state, getters) => {
  const realSubtotalMXN = getters.getSubtotalUSD * state.exchangeType

  if (state.discount > 0) {
    return realSubtotalMXN - (realSubtotalMXN * (state.discount / 100))
  }

  return realSubtotalMXN
}

export const getTotalMXN = (state, getters) => {
  const subtotalMXN = getters.getSubtotalMXN
  let totalMXN = subtotalMXN

  if (state.invoiceRequired === 'Si') {
    totalMXN += getters.getIVA
  }

  if (state.shipmentCost > 0) {
    totalMXN += Number(state.shipmentCost)
  }

  if (state.installationCost > 0) {
    totalMXN += Number(state.installationCost)
  }

  return totalMXN.toFixed(2)
}

export const getIVA = (state, getters) => {
  if (state.invoiceRequired === 'Si') {
    if (state.countToInvoice) {
      return ((16 / 100) * state.countToInvoice)
    }

    return ((16 / 100) * getters.getSubtotalMXN)
  }
  return 0
}

// * 16/11/2022 - TAG: Extra information
export const getDiscount = state => state.discount
export const getExchangeType = state => state.exchangeType
export const getShipmentCost = state => state.shipmentCost
export const getInstallationCost = state => state.installationCost

// * 17/11/2022 - TAG: For quotes list
export const getIdOptions = state => state.idOptions
export const getQuotesStatus = state => state.quotesStatus

// * 22/11/2022 - TAG: Quotes list filters
export const getClientsSuggests = state => state.clientsSuggests
export const getCreatorsSuggests = state => state.creatorsSuggests

// * 29/11/2022 - TAG: Quote payments
export const getPayments = state => state.payments
export const getPaymentStatus = state => state.paymentStatus
export const getPercentagePaid = state => state.percentagePaid
export const getPaymentMethods = state => state.paymentMethods

export const getQuote = state => state.quote

// * 08/09/2023 - TAG: Circuits with offer price selected
export const getCircuitsWithOfferPriceSelected = state => state.circuitsWithOfferPriceSelected
