/* eslint-disable import/prefer-default-export */

// * 14/09/2022 - TAG: Functions for categories list

// 14/09/2022 - TAG: Set the categories obtained from server to show on list
export const setCategories = (state, categories) => {
  state.categories = categories
}

// * 14/09/2022 - TAG: General states for categories page

// 14/09/2022 - TAG: Set the loading responses state for overlay functions
export const setLoadingCategories = (state, isLoadingCategories) => {
  state.isLoadingCategories = isLoadingCategories
}

// 14/09/2022 - TAG: Set the state response based on axios requests
export const setStateResponse = (state, stateResponse) => {
  state.stateResponse = stateResponse
}

// * 09/09/2022 - TAG: Functions for set pagination attributes

// 14/09/2022 - TAG: Set the search term for personalized query on server
export const setSearchTerm = (state, searchTerm) => {
  state.searchTerm = searchTerm
}

// 14/09/2022 - TAG: Set the total count categories on server for pagination
export const setTotalCategories = (state, totalCategories) => {
  state.totalCategories = totalCategories
}

// 14/09/2022 - TAG: Set the current page for pagination on categories list
export const setCurrentPage = (state, currentPage) => {
  state.currentPage = currentPage
}

// 14/09/2022 - TAG: Set the count of categories for showing on list
export const setPerPage = (state, perPage) => {
  state.perPage = perPage
}

// 14/09/2022 - TAG: Set the count of categories for showing on list
export const setFilteredProcategory = (state, filteredProcategory) => {
  state.filteredProcategory = filteredProcategory
}
