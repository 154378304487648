export default [
  {
    path: '/usuarios/empresa',
    name: 'company-home',
    component: () => import('@/modules/users/company/views/CompanyUsersList.vue'),
    meta: {
      pageTitle: 'Empresas',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Empresas',
          active: true,
        },
      ],
      resource: 'Users_company',
      action: 'read',
      contentClass: 'company-module',
    },
  },
]
