export default () => ({
  isLoadingCategories: false,
  categories: [],

  // 09/09/2022 - TAG: Manage the resposes after calls
  stateResponse: {
    success: false,
    message: '',
  },

  // 09/09/2022 - TAG: Attibutes for pagination
  searchTerm: '',
  currentPage: 1,
  totalCategories: 0,
  perPage: 10,
  filteredProcategory: null,
})
