/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

const newInstance = axios.create({
  baseURL: 'https://apioptimum.demosturn.com/ecom',
})

// eslint-disable-next-line no-unused-vars
export const loadProducts = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  commit('saveListParams', { ...loadParams })

  axios.get('/ecom/erp/products',
    {
      params: {
        status: loadParams.status,
        identifier: loadParams.identifier,
        to: loadParams.to,
        page: loadParams.currentPage,
        perPage: loadParams.perPage,
        search: loadParams.searchTerm,
        estatus: loadParams.estatus,
        hidePrice: loadParams.hidePrice,
        description: loadParams.description,
        favorite: loadParams.favorite,
        brand: loadParams.brandTerm,
        subcategory: loadParams.subCategoryTerm,
        category: loadParams.categoryTerm,
        orderby: loadParams.orderBy,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadCircuits = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  commit('saveListParams', { ...loadParams })

  axios.get('/ecom/erp/circuits',
    {
      params: {

        status: loadParams.status,
        identifier: loadParams.identifier,
        to: loadParams.to,
        page: loadParams.currentPage,
        perPage: loadParams.perPage,
        search: loadParams.searchTerm,
        estatus: loadParams.estatus,
        hidePrice: loadParams.hidePrice,
        favorite: loadParams.favorite,
        orderby: loadParams.orderBy,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const updateActive = async ({ commit }, { key, formData }) => new Promise((resolve, reject) => {
  axios.post(`/ecom/erp/${key}/settings`, formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
export const updatePriceOffert = async ({ commit }, { key, id, formData }) => new Promise((resolve, reject) => {
  axios.post(`/ecom/erp/${key}/offerprice/${id}`, formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const getExchange = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/ecom/erp/exchange')
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})
export const updateExchange = async ({ commit }, { formData }) => new Promise((resolve, reject) => {
  axios.post('/ecom/erp/exchange', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const loadCategoriesWithoutPagination = async ({ commit }, all = false) => new Promise((resolve, reject) => {
  commit('setLoadingCategories', true)

  let url = '/ecom/cat/categories'

  if (all) {
    url = '/ecom/cat/categories'
  }

  axios.get(url)
    .then(response => {
      commit('setCategories', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
    .then(() => {
      commit('setLoadingCategories', false)
    })
})
