/* eslint-disable import/prefer-default-export */

// * 16/09/2022 - TAG: Information for orders filters list
export const setClientsSuggests = (state, clientsSuggests) => {
  state.clientsSuggests = clientsSuggests
}

export const setSearchTerm = (state, searchTerm) => {
  state.searchTerm = searchTerm
}

export const setCreatorsSuggests = (state, creatorsSuggests) => {
  state.creatorsSuggests = creatorsSuggests
}

export const setOrdersStatus = (state, ordersStatus) => {
  state.ordersStatus = ordersStatus
}

export const setPaymentsStatus = (state, paymentsStatus) => {
  state.paymentsStatus = paymentsStatus
}

export const setOrder = (state, order) => {
  state.order = order
}

export const setSelectedLocation = (state, selectedLocation) => {
  state.selectedLocation = selectedLocation
}

export const setCanMakeRefund = (state, canMakeRefund) => {
  state.canMakeRefund = canMakeRefund
}

export const setCountToInvoice = (state, countToInvoice) => {
  state.countToInvoice = countToInvoice
}

export const setInvoiceRequired = (state, invoiceRequired) => {
  state.invoiceRequired = invoiceRequired
}

// * 21/12/2022 - TAG: Products list and its pieces for order details
export const addDetailProduct = (state, detailProduct) => {
  const existsAtIndex = state.detailProducts
    .findIndex(p => p.IdProduct === detailProduct.IdProduct)

  if (existsAtIndex === -1) {
    state.detailProducts = [
      ...state.detailProducts,
      detailProduct,
    ]

    state.detailProductsPieces = [
      ...state.detailProductsPieces,
      ...detailProduct.pieces,
    ]
  } else {
    const filteredProducts = state.detailProducts
      .filter(product => product.IdProduct !== detailProduct.IdProduct)

    const filteredPieces = state.detailProductsPieces
      .filter(piece => piece.FK_IdProduct !== detailProduct.IdProduct)

    if (detailProduct.pieces.length > 0) {
      state.detailProducts = [...filteredProducts, detailProduct]
      state.detailProductsPieces = [...filteredPieces, ...detailProduct.pieces]
    } else {
      state.detailProducts = [...filteredProducts]
      state.detailProductsPieces = [...filteredPieces]
    }
  }
}

// * 04/01/2023 - TAG: Update detail product
export const updateDetailProduct = (state, detailProduct) => {
  const filteredProducts = state.detailProducts.filter(product => product.IdProduct !== detailProduct.IdProduct)
  const filteredPieces = state.detailProductsPieces.filter(piece => piece.FK_IdProduct !== detailProduct.IdProduct)

  if (detailProduct.pieces.length > 0) {
    const oldQuoteProduct = state.detailProducts.find(quoteProductList => quoteProductList.IdProduct === detailProduct.IdProduct)
    oldQuoteProduct.pieces = detailProduct.pieces

    state.detailProductsPieces = [...filteredPieces, ...detailProduct.pieces]
  } else {
    state.detailProducts = [...filteredProducts]
    state.detailProductsPieces = [...filteredPieces]
  }
}

// * 03/01/2023 - TAG: For adding pieces from circuit products
export const addDetailProductPieces = (state, detailProductsPieces) => {
  state.detailProductsPieces = [...state.detailProductsPieces, ...detailProductsPieces]
}

export const addDetailCircuit = (state, detailCircuit) => {
  state.detailCircuits = [...state.detailCircuits, detailCircuit]
}

export const updateDetailCircuit = (state, quoteCircuit) => {
  const filteredCircuits = state.detailCircuits.filter(circuit => circuit.IdCircuit !== quoteCircuit.IdCircuit)
  state.detailCircuits = [...filteredCircuits, quoteCircuit]
}

export const deleteOrderCircuit = (state, orderCircuit) => {
  const filteredCircuits = state.detailCircuits.filter(circuit => circuit.uuid !== orderCircuit.uuid)
  state.detailCircuits = [...filteredCircuits]
}

// #region Circuits with offer price selected
export const addCircuitWithOfferPriceSelected = (state, circuitWithOfferPriceSelected) => {
  const existsAtIndex = state.circuitsWithOfferPriceSelected.findIndex(circuit => circuit.IdCircuit === circuitWithOfferPriceSelected.IdCircuit)
  if (existsAtIndex === -1) {
    state.circuitsWithOfferPriceSelected = [...state.circuitsWithOfferPriceSelected, circuitWithOfferPriceSelected]
  }
}

export const removeCircuitWithOfferPriceSelected = (state, circuitWithOfferPriceSelected) => {
  const filteredCircuits = state.circuitsWithOfferPriceSelected.filter(circuit => circuit.IdCircuit !== circuitWithOfferPriceSelected.IdCircuit)
  state.circuitsWithOfferPriceSelected = [...filteredCircuits]
}
// #endregion

// * 21/12/2022 - TAG: For making partial delivery
export const addPartialDeliveryProvitionalProduct = (state, provitionalProduct) => {
  const existsAtIndex = state.partialDeliveryProvitionalProducts
    .findIndex(p => p.IdProduct === provitionalProduct.IdProduct)

  if (existsAtIndex === -1) {
    state.partialDeliveryProvitionalProducts = [
      ...state.partialDeliveryProvitionalProducts,
      provitionalProduct,
    ]

    state.partialDeliveryProvitionalProductsPieces = [
      ...state.partialDeliveryProvitionalProductsPieces,
      ...provitionalProduct.pieces,
    ]
  } else {
    const filteredProducts = state.partialDeliveryProvitionalProducts
      .filter(product => product.IdProduct !== provitionalProduct.IdProduct)

    const filteredPieces = state.partialDeliveryProvitionalProductsPieces
      .filter(piece => piece.FK_IdProduct !== provitionalProduct.IdProduct)

    if (provitionalProduct.pieces.length > 0) {
      state.partialDeliveryProvitionalProducts = [...filteredProducts, provitionalProduct]
      state.partialDeliveryProvitionalProductsPieces = [...filteredPieces, ...provitionalProduct.pieces]
    } else {
      state.partialDeliveryProvitionalProducts = [...filteredProducts]
      state.partialDeliveryProvitionalProductsPieces = [...filteredPieces]
    }
  }
}

export const addPartialDeliveryProvitionalCircuit = (state, provitionalCircuit) => {
  state.partialDeliveryProvitionalCircuits = [...state.partialDeliveryProvitionalCircuits, provitionalCircuit]
}

export const deletePartialDeliveryProvitionalCircuit = (state, provitionalCircuit) => {
  const filteredCircuits = state.partialDeliveryProvitionalCircuits.filter(c => c.IdCircuit !== provitionalCircuit.IdCircuit)
  state.partialDeliveryProvitionalCircuits = [...filteredCircuits]
}

export const setColumnsList = (state, columnsList) => {
  state.columnsList = columnsList
}

export const saveListParams = (state, listParams) => {
  const index = state.listsParams.findIndex(params => params.title === listParams.title)
  if (index === -1) {
    state.listsParams.push(listParams)
  } else {
    state.listsParams.splice(index, 1, listParams)
  }
}

export const removeListParams = (state, title) => {
  state.listsParams = state.listsParams.filter(params => params.title !== title)
}

// * 02/01/2023 - TAG: Order edtion
export const setEditionModeState = (state, editionModeState) => {
  state.editionModeState = editionModeState
}

export const clearDeliveryState = state => {
  state.searchTerm = ''
  state.partialDeliveryProvitionalProducts = []
  state.partialDeliveryProvitionalCircuits = []
  state.partialDeliveryProvitionalProductsPieces = []
}

export const clearData = state => {
  state.searchTerm = ''
  // state.listsParams = []
  state.detailProducts = []
  state.detailCircuits = []
  state.detailProductsPieces = []
  state.circuitsWithOfferPriceSelected = []
  state.partialDeliveryProvitionalProducts = []
  state.partialDeliveryProvitionalCircuits = []
  state.partialDeliveryProvitionalProductsPieces = []
}
