import Vue from 'vue'
import Vuex from 'vuex'

// 07/09/2022 - TAG: Modules
import quotes from '@/modules/trade/quotes/store'
import orders from '@/modules/trade/orders/store'
import incomes from '@/modules/store/incomes/store'
import refunds from '@/modules/store/refunds/store'
import sellers from '@/modules/users/sellers/store/'
import company from '@/modules/users/company/store/'
import outcomes from '@/modules/store/outcomes/store'
import dashboard from '@/modules/home/dashboard/store'
import payments from '@/modules/reports/payments/store'
import customers from '@/modules/users/customers/store/'
import imports from '@/modules/production/imports/store'
import ecommerce from '@/modules/ecommerce/products/store'
import commissions from '@/modules/trade/commissions/store'
import circuits from '@/modules/production/circuits/store/'
import wholesalers from '@/modules/users/wholesalers/store/'
import transfers from '@/modules/production/transfers/store'
import brands from '@/modules/production/catalog/store/brands'
import pieces from '@/modules/production/products/store/pieces'
import reportsSuppliers from '@/modules/reports/suppliers/store'
import products from '@/modules/production/products/store/products'
import suppliers from '@/modules/production/catalog/store/suppliers'
import categories from '@/modules/production/catalog/store/categories'
import procategories from '@/modules/production/catalog/store/procategories'

import app from './app'
import filters from './filters'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    filters,
    appConfig,
    verticalMenu,
    // 07/09/2022 - TAG: Modules
    brands,
    pieces,
    quotes,
    orders,
    sellers,
    company,
    incomes,
    refunds,
    imports,
    products,
    circuits,
    outcomes,
    payments,
    suppliers,
    customers,
    dashboard,
    transfers,
    ecommerce,
    categories,
    commissions,
    wholesalers,
    procategories,
    reportsSuppliers,
  },
  strict: process.env.DEV,
})
