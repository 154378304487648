/* eslint-disable import/prefer-default-export */

export const saveListParams = (state, listParams) => {
  const index = state.listsParams.findIndex(params => params.title === listParams.title)
  if (index === -1) {
    state.listsParams.push(listParams)
  } else {
    state.listsParams.splice(index, 1, listParams)
  }
}

export const removeListParams = (state, title) => {
  state.listsParams = state.listsParams.filter(params => params.title !== title)
}

export const addTab = (state, tab) => {
  state.tabs.push(tab)
}

// 14/09/2022 - TAG: Set the categories obtained from server to show on list
export const setCategories = (state, categories) => {
  state.categories = categories
}

export const setLoadingCategories = (state, isLoadingCategories) => {
  state.isLoadingCategories = isLoadingCategories
}
