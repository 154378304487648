/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// export async function getOutcomes({ commit }) {
//   return new Promise((resolve, reject) => {
//     axios.get('https://638a51a3c5356b25a218a46c.mockapi.io/api/v1/outcomes')
//       .then(response => {
//         resolve(response)
//         console.log('res', response)
//       })
//       .catch(error => {
//         console.log(error)
//         reject(error)
//       })
//   })
// }

export const getOutcomes = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/orders/outputs',
    {
      params: {
        date: loadParams.dateTerm,
        perPage: loadParams.perPage,
        page: loadParams.currentPage,
        search: loadParams.searchTerm,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// export const loadCustomer = async ({ commit }, id) => {
//   commit('setLoadingCustomers', true)

//   return new Promise((resolve, reject) => {
//     axios.get(`/quotes/client-list?cliente=${id}`)
//       .then(response => {
//         resolve(response)
//       })
//       .catch(error => {
//         console.log(error)
//         reject(error)
//       })
//       .then(() => {
//         commit('setLoadingCustomers', false)
//       })
//   })
// }

// // eslint-disable-next-line no-unused-vars
// export const updateCustomerInvoiceData = async ({ commit }, formData) => new Promise((resolve, reject) => {
//   formData.append('_method', 'PUT')

//   axios.post('/quotes/client-billing', formData)
//     .then(response => {
//       resolve(response)
//     })
//     .catch(error => {
//       reject(error)
//     })
// })

// // eslint-disable-next-line no-unused-vars
// export const updateCustomerInformation = async ({ commit }, formData) => new Promise((resolve, reject) => {
//   formData.append('_method', 'PUT')

//   axios.post('/quotes/update-client', formData)
//     .then(response => {
//       resolve(response)
//     })
//     .catch(error => {
//       reject(error)
//     })
// })

// // eslint-disable-next-line no-unused-vars
// export const updateShipmentAddress = async ({ commit }, formData) => new Promise((resolve, reject) => {
//   formData.append('_method', 'PUT')

//   axios.post('/quotes/client-address', formData)
//     .then(response => {
//       resolve(response)
//     })
//     .catch(error => {
//       reject(error)
//     })
// })

// // eslint-disable-next-line no-unused-vars
// export const updateInvoiceAddress = async ({ commit }, formData) => new Promise((resolve, reject) => {
//   formData.append('_method', 'PUT')

//   axios.post('/quotes/client-billingsdata', formData)
//     .then(response => {
//       resolve(response)
//     })
//     .catch(error => {
//       reject(error)
//     })
// })

// // eslint-disable-next-line no-unused-vars
// export const createQuote = async ({ commit }, formData) => new Promise((resolve, reject) => {
//   axios.post('/quotes/store', formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   })
//     .then(response => {
//       console.log(response, 'en el salvado Correcto')
//       resolve(response)
//     })
//     .catch(error => {
//       console.log(error, 'en el salvado Incorrecto')
//       reject(error)
//     })
// })

// // eslint-disable-next-line no-unused-vars
// export const updateQuote = async ({ commit }, formData) => new Promise((resolve, reject) => {
//   formData.append('_method', 'PUT')

//   axios.post('/quotes/store', formData, {
//     headers: {
//       'Content-Type': 'multipart/form-data',
//     },
//   })
//     .then(response => {
//       console.log(response, 'en el salvado Correcto')
//       resolve(response)
//     })
//     .catch(error => {
//       console.log(error, 'en el salvado Incorrecto')
//       reject(error)
//     })
// })

// // eslint-disable-next-line no-unused-vars
// export const loadQuotes = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
//   axios.get('/quotes',
//     {
//       params: {
//         'page[number]': loadParams.currentPage,
//         limit: loadParams.perPage,
//         'filter[search]': loadParams.searchTerm,
//         date: loadParams.dateTerm,
//         status: loadParams.statusTerm,
//         idClient: loadParams.idClientTerm,
//         ...(loadParams.sortTerm === 'Ascendente' && { sort: 'ID' }),
//       },
//     })
//     .then(response => {
//       resolve(response)
//     })
//     .catch(error => {
//       console.log(error)
//       reject(error)
//     })
// })

// export const loadClientsSuggests = async ({ commit }) => new Promise((resolve, reject) => {
//   axios.get('/quotes/search-clients')
//     .then(response => {
//       commit('seClientstSuggests', response.data.data)
//       resolve(response)
//     })
//     .catch(error => {
//       console.log(error)
//       reject(error)
//     })
// })

// // eslint-disable-next-line no-unused-vars
// export const loadQuote = async ({ commit }, id) => new Promise((resolve, reject) => {
//   axios.get(`/quotes/show?id=${id}`)
//     .then(response => {
//       resolve(response)
//     })
//     .catch(error => {
//       console.log(error)
//       reject(error)
//     })
// })

// // #region Quote interactions
// export const setIdQuote = ({ commit }, idQuote) => {
//   commit('setIdQuote', idQuote)
// }
// // #endregion

// // #region Customer data form
// export const setSelectedCustomer = ({ commit }, selectedCustomer) => {
//   commit('setSelectedCustomer', selectedCustomer)
// }

// export const setCustomerType = ({ commit }, customerType) => {
//   commit('setCustomerType', customerType)
// }

// export const setCustomerPhone = ({ commit }, customerPhone) => {
//   commit('setCustomerPhone', customerPhone)
// }

// export const setCustomerEmail = ({ commit }, customerEmail) => {
//   commit('setCustomerEmail', customerEmail)
// }

// export const setCustomerLastName = ({ commit }, customerLastName) => {
//   commit('setCustomerLastName', customerLastName)
// }

// export const setCustomerFirstName = ({ commit }, customerFirstName) => {
//   commit('setCustomerFirstName', customerFirstName)
// }
// // #endregion

// // #region Invoice data form
// export const setRFC = ({ commit }, RFC) => {
//   commit('setRFC', RFC)
// }

// export const setCFDIUssage = ({ commit }, CFDIUssage) => {
//   commit('setCFDIUssage', CFDIUssage)
// }

// export const setBusinessName = ({ commit }, BusinessName) => {
//   commit('setBusinessName', BusinessName)
// }
// // #endregion

// // #region Shipment address data
// export const setShipmentCity = ({ commit }, shipmentCity) => {
//   commit('setShipmentCity', shipmentCity)
// }

// export const setShipmentState = ({ commit }, shipmentState) => {
//   commit('setShipmentState', shipmentState)
// }

// export const setShipmentEmail = ({ commit }, shipmentEmail) => {
//   commit('setShipmentEmail', shipmentEmail)
// }

// export const setShipmentPhone = ({ commit }, shipmentPhone) => {
//   commit('setShipmentPhone', shipmentPhone)
// }

// export const setShipmentColony = ({ commit }, shipmentColony) => {
//   commit('setShipmentColony', shipmentColony)
// }

// export const setShipmentContact = ({ commit }, shipmentContact) => {
//   commit('setShipmentContact', shipmentContact)
// }

// export const setShipmentAddress = ({ commit }, shipmentAddress) => {
//   commit('setShipmentAddress', shipmentAddress)
// }

// export const setShipmentZipCode = ({ commit }, shipmentZipCode) => {
//   commit('setShipmentZipCode', shipmentZipCode)
// }
// // #endregion

// // #region Invoice address data
// export const setInvoiceCity = ({ commit }, invoiceCity) => {
//   commit('setInvoiceCity', invoiceCity)
// }

// export const setInvoiceState = ({ commit }, invoiceState) => {
//   commit('setInvoiceState', invoiceState)
// }

// export const setInvoiceEmail = ({ commit }, invoiceEmail) => {
//   commit('setInvoiceEmail', invoiceEmail)
// }

// export const setInvoicePhone = ({ commit }, invoicePhone) => {
//   commit('setInvoicePhone', invoicePhone)
// }

// export const setInvoiceColony = ({ commit }, invoiceColony) => {
//   commit('setInvoiceColony', invoiceColony)
// }

// export const setInvoiceContact = ({ commit }, invoiceContact) => {
//   commit('setInvoiceContact', invoiceContact)
// }

// export const setInvoiceAddress = ({ commit }, invoiceAddress) => {
//   commit('setInvoiceAddress', invoiceAddress)
// }

// export const setInvoiceZipCode = ({ commit }, invoiceZipCode) => {
//   commit('setInvoiceZipCode', invoiceZipCode)
// }
// // #endregion

// // #region State management list (products and circuits)
// export const addQuoteProduct = ({ commit }, quoteProduct) => {
//   commit('addQuoteProduct', quoteProduct)
// }

// export const updateQuoteProduct = ({ commit }, quoteProduct) => {
//   commit('updateQuoteProduct', quoteProduct)
// }

// export const addSelectedCircuit = ({ commit }, selectedCircuit) => {
//   commit('addSelectedCircuit', selectedCircuit)
// }

// export const deleteSelectedCircuit = ({ commit }, selectedCircuit) => {
//   commit('deleteSelectedCircuit', selectedCircuit)
// }

// export const updateSelectedCircuit = ({ commit }, selectedCircuit) => {
//   commit('updateSelectedCircuit', selectedCircuit)
// }

// export const cleanSelectedCircuits = ({ commit }) => {
//   commit('cleanSelectedCircuits')
// }

// export const addQuoteCircuit = ({ commit }, quoteCircuit) => {
//   commit('addQuoteCircuit', quoteCircuit)
// }

// export const updateQuoteCircuit = ({ commit }, quoteCircuit) => {
//   commit('updateQuoteCircuit', quoteCircuit)
// }

// export const deleteQuoteCircuit = ({ commit }, quoteCircuit) => {
//   commit('deleteQuoteCircuit', quoteCircuit)
// }
// // #endregion

// // #region Delivery type and methods
// export const setArriveDate = ({ commit }, arriveDate) => {
//   commit('setArriveDate', arriveDate)
// }

// export const setInvoiceRequired = ({ commit }, invoiceRequired) => {
//   commit('setInvoiceRequired', invoiceRequired)
// }

// export const setSelectedDeliveryType = ({ commit }, selectedDeliveryType) => {
//   commit('setSelectedDeliveryType', selectedDeliveryType)
// }

// export const setSelectedDeliveryMethod = ({ commit }, selectedDeliveryMethod) => {
//   commit('setSelectedDeliveryMethod', selectedDeliveryMethod)
// }
// // #endregion

// // #region Extra information
// export const setDiscount = ({ commit }, discount) => {
//   commit('setDiscount', discount)
// }

// export const setExchangeType = ({ commit }, exchangeType) => {
//   commit('setExchangeType', exchangeType)
// }

// export const setShipmentCost = ({ commit }, shipmentCost) => {
//   commit('setShipmentCost', shipmentCost)
// }

// export const setInstallationCost = ({ commit }, installationCost) => {
//   commit('setInstallationCost', installationCost)
// }
// // #endregion
