/* eslint-disable import/prefer-default-export */
export const setNewProductsColumnsList = (state, newProductsColumnsList) => {
  state.newProductsColumnsList = newProductsColumnsList
}

export const setUsedProductsColumnsList = (state, usedProductsColumnsList) => {
  state.usedProductsColumnsList = usedProductsColumnsList
}

export const setIsEditionModeEnabled = (state, isEditionModeEnabled) => {
  state.isEditionModeEnabled = isEditionModeEnabled
}

export const setIsLoadingGlobalResponse = (state, isLoadingGlobalResponse) => {
  state.isLoadingGlobalResponse = isLoadingGlobalResponse
}

/**
 * Almacena los parametro de filtrado y paginado de cada pestaña
 */
export const saveListParams = (state, listParams) => {
  const index = state.listsParams.findIndex(params => params.title === listParams.title)
  if (index === -1) {
    state.listsParams.push(listParams)
  } else {
    state.listsParams.splice(index, 1, listParams)
  }
}

export const clearImportsInfo = state => {
  state.listsParams = []
  state.newProductsColumnsList = []
  state.usedProductsColumnsList = []

  state.isEditionModeEnabled = false
}
