export default () => ({
  isLoadingPieces: true,
  pieces: [],
  localPieces: [],
  externalPieces: [],

  // * 06/06/2023 - TAG: Total de piezas en el servidor
  totalExternalPieces: 0,

  piecesToUpdate: [],
  piecesToDelete: [],

  localSearchTerm: '',
  externalSearchTerm: '',

  editablePiece: null,

  // * 09/09/2022 - TAG: Manage the resposes after calls
  stateResponse: {
    success: false,
    message: '',
  },

  // * 19/09/2022 - TAG: Locations for pieces
  locations: [
    {
      text: 'Bodega GDL',
    },
    {
      text: 'Bodega CDMX',
    },
    {
      text: 'Tienda',
    },
  ],

  // * 19/09/2022 - TAG: Conditions for pieces
  conditions: [
    {
      text: 'Nuevo',
    },
    {
      text: 'Habilitado',
    },
    {
      text: 'Funcional',
    },
    {
      text: 'Desarmado',
    },
    {
      text: 'Incompleto',
    },
  ],

  // * 19/09/2022 - TAG: Availabilities for pieces
  availabilities: [
    {
      text: 'Disponible',
    },
    {
      text: 'No disponible',
    },
    {
      text: 'Preventa',
    },
  ],

  globalDate: '',
  editionMode: false,
  availablePieces: false,

  // #region V-Models form (main information)
  tag: '',
  arriveDate: '',
  serieNumber: '',
  importNumber: '',
  invoiceNumber: '',
  petitionNumber: '',
  selectedLocation: '',
  selectedCondition: '',
  selectedAvailability: '',
  // #endregion

  // * 06/06/2023 - TAG: Lista de todas las piezas de un producto agregas a un circuito
  countPiecesQuery: 0,
  productPiecesQuery: '',

  /**
   * Estados de las piezas en la lista de entradas
   */
  piecesStatus: [
    {
      text: 'Inventario',
    },
    {
      text: 'Circuito',
    },
    {
      text: 'Solicitud',
    },
    {
      text: 'Orden',
    },
    {
      text: 'Entregado',
    },
    {
      text: 'Eliminada',
    },
  ],
})
