/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// 14/09/2022 - TAG: Make a request to load suppliers based on params
export const loadSuppliers = async ({ commit, state }, withParams = true) => {
  commit('setLoadingSuppliers', true)

  let params = {}

  if (withParams) {
    params = {
      sort: 'Name',
      'page[number]': state.currentPage,
      limit: state.perPage,
      'filter[search]': state.searchTerm,
    }
  }

  return new Promise((resolve, reject) => {
    axios.get('/suppliers', { params })
      .then(response => {
        commit('setSuppliers', response.data.suppliers.data)
        commit('setTotalSuppliers', response.data.suppliers.total)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
      .then(() => {
        commit('setLoadingSuppliers', false)
      })
  })
}

export const loadSuppliersWithoutPagination = async ({ commit }, all = false) => new Promise((resolve, reject) => {
  commit('setLoadingSuppliers', true)

  let url = '/suppliers/list'

  if (all) {
    url = '/suppliers/list-add'
  }

  axios.get(url)
    .then(response => {
      commit('setSuppliers', response.data.suppliers)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
    .then(() => {
      commit('setLoadingSuppliers', false)
    })
})

// 14/09/2022 - TAG: Create a new supplier
export const createSupplier = async ({ commit, dispatch }, brand) => new Promise((resolve, reject) => {
  axios.post('/suppliers/save', brand)
    .then(response => {
      commit('setStateResponse', {
        success: true,
        message: response.data.message,
      })
      dispatch('loadSuppliers')
      resolve(response)
    })
    .catch(error => {
      commit('setStateResponse', {
        success: false,
        message: 'Error al intentar crear el proveedor',
      })
      reject(error)
    })
})

// 14/09/2022 - TAG: Update an existing supplier
export const updateSupplier = async ({ commit, dispatch }, supplier) => new Promise((resolve, reject) => {
  axios.put('/suppliers/update', supplier)
    .then(response => {
      commit('setStateResponse', {
        success: true,
        message: response.data.message,
      })
      dispatch('loadSuppliers')
      resolve(response)
    })
    .catch(error => {
      commit('setStateResponse', {
        success: false,
        message: 'Error al intentar actualizar el proveedor',
      })
      reject(error)
    })
})

// 09/09/2022 - TAG: Set the search term for supplier searching
export const setSearchTerm = ({ commit }, searchTerm) => {
  commit('setSearchTerm', searchTerm)
}

// 14/09/2022 - TAG: Set the current page on supplier list pagination
export const setCurrentPage = ({ commit }, currentPage) => {
  commit('setCurrentPage', currentPage)
}

// 14/09/2022 - TAG: Set the count per page for supplier list pagination
export const setPerPage = ({ commit }, perPage) => {
  commit('setPerPage', perPage)
}
