export default {
  namespaced: true,
  state: {
    filteredYear: '',
    filteredBrand: '',
    filteredSortBy: '',
    filteredOrigin: '',
    filteredStatus: '',
    filteredSeller: '',
    filteredCreator: '',
    filteredPerPage: 10,
    filteredCustomer: '',
    filteredSupplier: '',
    filteredLocation: '',
    filteredFavorite: '',
    filteredCategory: '',
    filteredPriceEcom: '',
    filteredCurrentPage: 1,
    filteredSearchTerm: '',
    filteredOutputDate: '',
    filteredOrderStatus: '',
    filteredQuoteStatus: '',
    filteredDestination: '',
    filteredSubcategory: '',
    filteredProductType: '',
    filteredDescription: '',
    filteredStatusPiece: '',
    filteredMonthAndYear: '',
    filteredRequiredDate: '',
    filteredAvailability: '',
    filteredReceivedDate: '',
    filteredPendingImage: false,
    filteredInvoiceOption: '',
    filteredPaymentStatus: '',
    filteredCreatedAtDate: '',
    filteredPaymentMethod: '',
    filteredReceivedMonth: '',
    filteredPiecesLocation: '',
    filteredPaymentCurrency: '',
    filteredCommissionStatus: '',
    filteredPriceToBeDefined: false,
    filteredSelectedIndexTab: 0,
    filteredOrderPaymentStatus: '',
    filteredCashDeliveryStatus: '',
    filteredApprovedByStoreDate: '',
    filteredRefundPaymentStatus: '',
    filteredInventorySelectedTab: '',
    filteredImportNumberSearchTerm: '',
    /**
     * Range filters for reports payments
     */
    filteredVoucherDateRange: null,
    filteredRegisterDateRange: null,
    filteredDeliveryDateRange: null,
    /**
     * Range filters for dashboard lists
     */
    filteredDashboardDateRange: null,
    /**
     * Range filters for transactions lists
     */
    filteredTransactionsDateRange: null,
    /**
     * Dates ranges for filtering imports tables
     */
    filteredETDDateRange: null,
    filteredETADateRange: null,
    filteredOrderDateRange: null,
    filteredArrivalDateRange: null,
    /**
     * Dates for commissions filtering
     */
    filteredPaymentDate: null,
    filteredRegisterDate: null,
    /**
     * Almacena la informacion de columnas visibles
     */
    filteredListParams: [],
  },
  getters: {
    getFilteredYear: state => state.filteredYear,
    getFilteredBrand: state => state.filteredBrand,
    getFilteredSortBy: state => state.filteredSortBy,
    getFilteredOrigin: state => state.filteredOrigin,
    getFilteredStatus: state => state.filteredStatus,
    getFilteredSeller: state => state.filteredSeller,
    getFilteredCreator: state => state.filteredCreator,
    getFilteredPerPage: state => state.filteredPerPage,
    getFilteredFavorite: state => state.filteredFavorite,
    getFilteredCustomer: state => state.filteredCustomer,
    getFilteredSupplier: state => state.filteredSupplier,
    getFilteredLocation: state => state.filteredLocation,
    getFilteredCategory: state => state.filteredCategory,
    getFilteredPriceEcom: state => state.filteredPriceEcom,
    getFilteredSearchTerm: state => state.filteredSearchTerm,
    getFilteredOutputDate: state => state.filteredOutputDate,
    getFilteredDescription: state => state.filteredDescription,
    getFilteredStatusPiece: state => state.filteredStatusPiece,
    getFilteredCurrentPage: state => state.filteredCurrentPage,
    getFilteredOrderStatus: state => state.filteredOrderStatus,
    getFilteredQuoteStatus: state => state.filteredQuoteStatus,
    getFilteredDestination: state => state.filteredDestination,
    getFilteredSubcategory: state => state.filteredSubcategory,
    getFilteredProductType: state => state.filteredProductType,
    getFilteredMonthAndYear: state => state.filteredMonthAndYear,
    getFilteredRequiredDate: state => state.filteredRequiredDate,
    getFilteredAvailability: state => state.filteredAvailability,
    getFilteredReceivedDate: state => state.filteredReceivedDate,
    getFilteredPendingImage: state => state.filteredPendingImage,
    getFilteredInvoiceOption: state => state.filteredInvoiceOption,
    getFilteredPaymentStatus: state => state.filteredPaymentStatus,
    getFilteredCreatedAtDate: state => state.filteredCreatedAtDate,
    getFilteredPaymentMethod: state => state.filteredPaymentMethod,
    getFilteredReceivedMonth: state => state.filteredReceivedMonth,
    getFilteredPiecesLocation: state => state.filteredPiecesLocation,
    getFilteredPaymentCurrency: state => state.filteredPaymentCurrency,
    getFilteredCommissionStatus: state => state.filteredCommissionStatus,
    getFilteredPriceToBeDefined: state => state.filteredPriceToBeDefined,
    getFilteredSelectedIndexTab: state => state.filteredSelectedIndexTab,
    getFilteredOrderPaymentStatus: state => state.filteredOrderPaymentStatus,
    getFilteredCashDeliveryStatus: state => state.filteredCashDeliveryStatus,
    getFilteredApprovedByStoreDate: state => state.filteredApprovedByStoreDate,
    getFilteredRefundPaymentStatus: state => state.filteredRefundPaymentStatus,
    getFilteredInventorySelectedTab: state => state.filteredInventorySelectedTab,
    getFilteredImportNumberSearchTerm: state => state.filteredImportNumberSearchTerm,
    /**
     * Range filters for reports payments
     */
    getFilteredVoucherDateRange: state => state.filteredVoucherDateRange,
    getFilteredRegisterDateRange: state => state.filteredRegisterDateRange,
    getFilteredDeliveryDateRange: state => state.filteredDeliveryDateRange,
    /**
     * Range filters for dashboard lists
     */
    getFilteredDashboardDateRange: state => state.filteredDashboardDateRange,
    /**
     * Range filters for transactions lists
     */
    getFilteredTransactionsDateRange: state => state.filteredTransactionsDateRange,
    /**
     * Dates for filtering imports tables
     */
    getFilteredETDDateRange: state => state.filteredETDDateRange,
    getFilteredETADateRange: state => state.filteredETADateRange,
    getFilteredOrderDateRange: state => state.filteredOrderDateRange,
    getFilteredArrivalDateRange: state => state.filteredArrivalDateRange,
    /**
     * Dates for commissions filtering
     */
    getFilteredPaymentDate: state => state.filteredPaymentDate,
    getFilteredRegisterDate: state => state.filteredRegisterDate,
    /**
     * Almacena la informacion de columnas visibles
     */
    getFilteredListParams: state => state.filteredListParams,

    getSearchTerms: state => state.filteredSearchTerm.split(' '),
    getAreLoadedFilters: state => (
      state.filteredYear !== ''
        || state.filteredBrand !== ''
        || state.filteredSortBy !== ''
        || state.filteredOrigin !== ''
        || state.filteredStatus !== ''
        || state.filteredSeller !== ''
        || state.filteredCreator !== ''
        || state.filteredCustomer !== ''
        || state.filteredSupplier !== ''
        || state.filteredLocation !== ''
        || state.filteredFavorite !== ''
        || state.filteredCategory !== ''
        || state.filteredPriceEcom !== ''
        || state.filteredOutputDate !== ''
        || state.filteredOrderStatus !== ''
        || state.filteredQuoteStatus !== ''
        || state.filteredDestination !== ''
        || state.filteredSubcategory !== ''
        || state.filteredProductType !== ''
        || state.filteredDescription !== ''
        || state.filteredStatusPiece !== ''
        || state.filteredMonthAndYear !== ''
        || state.filteredRequiredDate !== ''
        || state.filteredAvailability !== ''
        || state.filteredReceivedDate !== ''
        || state.filteredPendingImage !== false
        || state.filteredInvoiceOption !== ''
        || state.filteredPaymentStatus !== ''
        || state.filteredCreatedAtDate !== ''
        || state.filteredPaymentMethod !== ''
        || state.filteredReceivedMonth !== ''
        || state.filteredPiecesLocation !== ''
        || state.filteredPaymentCurrency !== ''
        || state.filteredCommissionStatus !== ''
        || state.filteredPriceToBeDefined !== false
        || state.filteredSelectedIndexTab !== 0
        || state.filteredOrderPaymentStatus !== ''
        || state.filteredCashDeliveryStatus !== ''
        || state.filteredApprovedByStoreDate !== ''
        || state.filteredRefundPaymentStatus !== ''
        || state.filteredInventorySelectedTab !== ''
        || state.filteredImportNumberSearchTerm !== ''
        /**
         * Range filters for reports payments
         */
        || state.filteredVoucherDateRange !== null
        || state.filteredRegisterDateRange !== null
        || state.filteredDeliveryDateRange !== null
        /**
         * Range filters for dashboard lists
         */
        || state.filteredDashboardDateRange !== null
        /**
         * Range filters for transactions lists
         */
        || state.filteredTransactionsDateRange !== null
        /**
         * Dates for filtering imports tables
         */
        || state.filteredETDDateRange !== null
        || state.filteredETADateRange !== null
        || state.filteredOrderDateRange !== null
        || state.filteredArrivalDateRange !== null
        /**
        * Dates for commissions filtering
        */
        || state.filteredPaymentDate !== null
        || state.filteredRegisterDate !== null
        /**
        * Almacena la informacion de columnas visibles
        */
        || state.filteredListParams.length > 0
    ),
  },
  mutations: {
    SET_FILTERED_YEAR(state, filteredYear) {
      state.filteredYear = filteredYear
    },
    SET_FILTERED_BRAND(state, filteredBrand) {
      state.filteredBrand = filteredBrand
    },
    SET_FILTERED_SORT_BY(state, filteredSortBy) {
      state.filteredSortBy = filteredSortBy
    },
    SET_FILTERED_ORIGIN(state, filteredOrigin) {
      state.filteredOrigin = filteredOrigin
    },
    SET_FILTERED_STATUS(state, filteredStatus) {
      state.filteredStatus = filteredStatus
    },
    SET_FILTERED_SELLER(state, filteredSeller) {
      state.filteredSeller = filteredSeller
    },
    SET_FILTERED_CREATOR(state, filteredCreator) {
      state.filteredCreator = filteredCreator
    },
    SET_FILTERED_PER_PAGE(state, filteredPerPage) {
      state.filteredPerPage = filteredPerPage
    },
    SET_FILTERED_CUSTOMER(state, filteredCustomer) {
      state.filteredCustomer = filteredCustomer
    },
    SET_FILTERED_SUPPLIER(state, filteredSupplier) {
      state.filteredSupplier = filteredSupplier
    },
    SET_FILTERED_LOCATION(state, filteredLocation) {
      state.filteredLocation = filteredLocation
    },
    SET_FILTERED_SEARCH_TERM(state, filteredSearchTerm) {
      state.filteredSearchTerm = filteredSearchTerm
    },
    SET_FILTERED_CURRENT_PAGE(state, filteredCurrentPage) {
      state.filteredCurrentPage = filteredCurrentPage
    },
    SET_FILTERED_CATEGORY(state, filteredCategory) {
      state.filteredCategory = filteredCategory
    },
    SET_FILTERED_PRICE(state, filteredPriceEcom) {
      state.filteredPriceEcom = filteredPriceEcom
    },
    SET_FILTERED_OUTPUT_DATE(state, filteredOutputDate) {
      state.filteredOutputDate = filteredOutputDate
    },
    SET_FILTERED_ORDER_STATUS(state, filteredOrderStatus) {
      state.filteredOrderStatus = filteredOrderStatus
    },
    SET_FILTERED_QUOTE_STATUS(state, filteredQuoteStatus) {
      state.filteredQuoteStatus = filteredQuoteStatus
    },
    SET_FILTERED_DESTINATION(state, filteredDestination) {
      state.filteredDestination = filteredDestination
    },
    SET_FILTERED_SUBCATEGORY(state, filteredSubcategory) {
      state.filteredSubcategory = filteredSubcategory
    },
    SET_FILTERED_PRODUCT_TYPE(state, filteredProductType) {
      state.filteredProductType = filteredProductType
    },
    SET_FILTERED_REQUIRED_DATE(state, filteredRequiredDate) {
      state.filteredRequiredDate = filteredRequiredDate
    },
    SET_FILTERED_AVAILABILITY(state, filteredAvailability) {
      state.filteredAvailability = filteredAvailability
    },
    SET_FILTERED_RECEIVED_DATE(state, filteredReceivedDate) {
      state.filteredReceivedDate = filteredReceivedDate
    },
    SET_FILTERED_INVOICE_OPTION(state, filteredInvoiceOption) {
      state.filteredInvoiceOption = filteredInvoiceOption
    },
    SET_FILTERED_PAYMENT_STATUS(state, filteredPaymentStatus) {
      state.filteredPaymentStatus = filteredPaymentStatus
    },
    SET_FILTERED_CREATED_AT_DATE(state, filteredCreatedAtDate) {
      state.filteredCreatedAtDate = filteredCreatedAtDate
    },
    SET_FILTERED_PAYMENT_METHOD(state, filteredPaymentMethod) {
      state.filteredPaymentMethod = filteredPaymentMethod
    },
    SET_FILTERED_RECEIVED_MONTH(state, filteredReceivedMonth) {
      state.filteredReceivedMonth = filteredReceivedMonth
    },
    SET_FILTERED_PIECES_LOCATION(state, filteredPiecesLocation) {
      state.filteredPiecesLocation = filteredPiecesLocation
    },
    SET_FILTERED_PENDING_IMAGE(state, filteredPendingImage) {
      state.filteredPendingImage = filteredPendingImage
    },
    SET_FILTERED_PRICE_TO_BE_DEFINED(state, filteredPriceToBeDefined) {
      state.filteredPriceToBeDefined = filteredPriceToBeDefined
    },
    SET_FILTERED_PAYMENT_CURRENCY(state, filteredPaymentCurrency) {
      state.filteredPaymentCurrency = filteredPaymentCurrency
    },
    SET_FILTERED_COMMISSION_STATUS(state, filteredCommissionStatus) {
      state.filteredCommissionStatus = filteredCommissionStatus
    },
    SET_FILTERED_SELECTED_INDEX_TAB(state, filteredSelectedIndexTab) {
      state.filteredSelectedIndexTab = filteredSelectedIndexTab
    },
    SET_FILTERED_ORDER_PAYMENT_STATUS(state, filteredOrderPaymentStatus) {
      state.filteredOrderPaymentStatus = filteredOrderPaymentStatus
    },
    SET_FILTERED_CASH_DELIVERY_STATUS(state, filteredCashDeliveryStatus) {
      state.filteredCashDeliveryStatus = filteredCashDeliveryStatus
    },
    SET_FILTERED_APPROVED_BY_STORE_DATE(state, filteredApprovedByStoreDate) {
      state.filteredApprovedByStoreDate = filteredApprovedByStoreDate
    },
    SET_FILTERED_REFUND_PAYMENT_STATUS(state, filteredRefundPaymentStatus) {
      state.filteredRefundPaymentStatus = filteredRefundPaymentStatus
    },
    SET_FILTERED_INVENTORY_SELECTED_TAB(state, filteredInventorySelectedTab) {
      state.filteredInventorySelectedTab = filteredInventorySelectedTab
    },
    SET_FILTERED_IMPORT_NUMBER_SEARCH_TERM(state, filteredImportNumberSearchTerm) {
      state.filteredImportNumberSearchTerm = filteredImportNumberSearchTerm
    },
    SET_FILTERED_DESCRIPTION(state, filteredDescription) {
      state.filteredDescription = filteredDescription
    },
    SET_FILTERED_STATUS_PIECE(state, filteredStatusPiece) {
      state.filteredStatusPiece = filteredStatusPiece
    },
    SET_FILTERED_MONTH_AND_YEAR(state, filteredMonthAndYear) {
      state.filteredMonthAndYear = filteredMonthAndYear
    },
    SET_FILTER_FAVORITE(state, filteredFavorite) {
      state.filteredFavorite = filteredFavorite
    },
    /**
     * Range filters for reports payments
     */
    SET_FILTERED_VOUCHER_DATE_RANGE(state, filteredVoucherDateRange) {
      state.filteredVoucherDateRange = filteredVoucherDateRange
    },
    SET_FILTERED_REGISTER_DATE_RANGE(state, filteredRegisterDateRange) {
      state.filteredRegisterDateRange = filteredRegisterDateRange
    },
    SET_FILTERED_DELIVERY_DATE_RANGE(state, filteredDeliveryDateRange) {
      state.filteredDeliveryDateRange = filteredDeliveryDateRange
    },
    /**
     * Range filters for dashboard lists
     */
    SET_FILTERED_DASHBOARD_DATE_RANGE(state, filteredDashboardDateRange) {
      state.filteredDashboardDateRange = filteredDashboardDateRange
    },
    /**
     * Range filters for transactions lists
     */
    SET_FILTERED_TRANSACTIONS_DATE_RANGE(state, filteredTransactionsDateRange) {
      state.filteredTransactionsDateRange = filteredTransactionsDateRange
    },
    /**
     * Dates for filtering imports tables
     */
    SET_FILTERED_ETD_DATE_RANGE(state, filteredETDDateRange) {
      state.filteredETDDateRange = filteredETDDateRange
    },
    SET_FILTERED_ETA_DATE_RANGE(state, filteredETADateRange) {
      state.filteredETADateRange = filteredETADateRange
    },
    SET_FILTERED_ORDER_DATE_RANGE(state, filteredOrderDateRange) {
      state.filteredOrderDateRange = filteredOrderDateRange
    },
    SET_FILTERED_ARRIVAL_DATE_RANGE(state, filteredArrivalDateRange) {
      state.filteredArrivalDateRange = filteredArrivalDateRange
    },
    /**
     * Dates for commissions filtering
     */
    SET_FILTERED_PAYMENT_DATE(state, filteredPaymentDate) {
      state.filteredPaymentDate = filteredPaymentDate
    },
    SET_FILTERED_REGISTER_DATE(state, filteredRegisterDate) {
      state.filteredRegisterDate = filteredRegisterDate
    },
    /**
     * Almacena la informacion de columnas visibles
     */
    SAVE_LIST_PARAMS(state, listParams) {
      const index = state.filteredListParams.findIndex(params => params.title === listParams.title)
      if (index === -1) {
        state.filteredListParams.push(listParams)
      } else {
        state.filteredListParams.splice(index, 1, listParams)
      }
    },
    REMOVE_LIST_PARAMS(state, title) {
      state.listsParams = state.listsParams.filter(params => params.title !== title)
    },
    CLEAR_FILTERED_DATA(state) {
      state.filteredYear = ''
      state.filteredBrand = ''
      state.filteredSortBy = ''
      state.filteredOrigin = ''
      state.filteredStatus = ''
      state.filteredSeller = ''
      state.filteredCreator = ''
      state.filteredPerPage = 10
      state.filteredCustomer = ''
      state.filteredSupplier = ''
      state.filteredLocation = ''
      state.filteredCategory = ''
      state.filteredFavorite = ''
      state.filteredPriceEcom = ''
      state.filteredOutputDate = ''
      state.filteredCurrentPage = 1
      state.filteredSearchTerm = ''
      state.filteredOrderStatus = ''
      state.filteredQuoteStatus = ''
      state.filteredDestination = ''
      state.filteredSubcategory = ''
      state.filteredProductType = ''
      state.filteredDescription = ''
      state.filteredStatusPiece = ''
      state.filteredMonthAndYear = ''
      state.filteredRequiredDate = ''
      state.filteredAvailability = ''
      state.filteredReceivedDate = ''
      state.filteredPendingImage = false
      state.filteredInvoiceOption = ''
      state.filteredPaymentStatus = ''
      state.filteredCreatedAtDate = ''
      state.filteredPaymentMethod = ''
      state.filteredReceivedMonth = ''
      state.filteredPiecesLocation = ''
      state.filteredPaymentCurrency = ''
      state.filteredCommissionStatus = ''
      state.filteredSelectedIndexTab = 0
      state.filteredPriceToBeDefined = false
      state.filteredOrderPaymentStatus = ''
      state.filteredCashDeliveryStatus = ''
      state.filteredApprovedByStoreDate = ''
      state.filteredRefundPaymentStatus = ''
      state.filteredInventorySelectedTab = ''
      state.filteredImportNumberSearchTerm = ''
      /**
       * Range filters for reports payments
       */
      state.filteredVoucherDateRange = null
      state.filteredRegisterDateRange = null
      state.filteredDeliveryDateRange = null
      /**
       * Range filters for dashboard lists
       */
      state.filteredDashboardDateRange = null
      /**
       * Dates for filtering imports tables
       */
      state.filteredETDDateRange = null
      state.filteredETADateRange = null
      state.filteredOrderDateRange = null
      state.filteredArrivalDateRange = null
      /**
     * Dates for commissions filtering
     */
      state.filteredPaymentDate = null
      state.filteredRegisterDate = null
      /**
      * Almacena la informacion de columnas visibles
      */
      state.filteredListParams = []
    },
  },
  actions: {
    setFilteredYear({ commit }, filteredYear) {
      commit('SET_FILTERED_YEAR', filteredYear)
    },
    setFilteredBrand({ commit }, filteredBrand) {
      commit('SET_FILTERED_BRAND', filteredBrand)
    },
    setFilteredSortBy({ commit }, filteredSortBy) {
      commit('SET_FILTERED_SORT_BY', filteredSortBy)
    },
    setFilteredOrigin({ commit }, filteredOrigin) {
      commit('SET_FILTERED_ORIGIN', filteredOrigin)
    },
    setFilteredStatus({ commit }, filteredStatus) {
      commit('SET_FILTERED_STATUS', filteredStatus)
    },
    setFilteredSeller({ commit }, filteredSeller) {
      commit('SET_FILTERED_SELLER', filteredSeller)
    },
    setFilteredCreator({ commit }, filteredCreator) {
      commit('SET_FILTERED_CREATOR', filteredCreator)
    },
    setFilteredPerPage({ commit }, filteredPerPage) {
      commit('SET_FILTERED_PER_PAGE', filteredPerPage)
    },
    setFilteredCustomer({ commit }, filteredCustomer) {
      commit('SET_FILTERED_CUSTOMER', filteredCustomer)
    },
    setFilteredSupplier({ commit }, filteredSupplier) {
      commit('SET_FILTERED_SUPPLIER', filteredSupplier)
    },
    setFilteredLocation({ commit }, filteredLocation) {
      commit('SET_FILTERED_LOCATION', filteredLocation)
    },
    setFilteredSearchTerm({ commit }, filteredSearchTerm) {
      commit('SET_FILTERED_SEARCH_TERM', filteredSearchTerm)
    },
    setFilteredCurrentPage({ commit }, filteredCurrentPage) {
      commit('SET_FILTERED_CURRENT_PAGE', filteredCurrentPage)
    },
    setFilteredOrderStatus({ commit }, filteredOrderStatus) {
      commit('SET_FILTERED_ORDER_STATUS', filteredOrderStatus)
    },
    setFilteredQuoteStatus({ commit }, filteredQuoteStatus) {
      commit('SET_FILTERED_QUOTE_STATUS', filteredQuoteStatus)
    },
    setFilteredDestination({ commit }, filteredDestination) {
      commit('SET_FILTERED_DESTINATION', filteredDestination)
    },
    setFilteredSubcategory({ commit }, filteredSubcategory) {
      commit('SET_FILTERED_SUBCATEGORY', filteredSubcategory)
    },
    setFilteredCategory({ commit }, filteredCategory) {
      commit('SET_FILTERED_CATEGORY', filteredCategory)
    },
    setFilteredProductType({ commit }, filteredProductType) {
      commit('SET_FILTERED_PRODUCT_TYPE', filteredProductType)
    },
    setFilteredRequiredDate({ commit }, filteredRequiredDate) {
      commit('SET_FILTERED_REQUIRED_DATE', filteredRequiredDate)
    },
    setFilteredAvailability({ commit }, filteredAvailability) {
      commit('SET_FILTERED_AVAILABILITY', filteredAvailability)
    },
    setFilteredReceivedDate({ commit }, filteredReceivedDate) {
      commit('SET_FILTERED_RECEIVED_DATE', filteredReceivedDate)
    },
    setFilteredInvoiceOption({ commit }, filteredInvoiceOption) {
      commit('SET_FILTERED_INVOICE_OPTION', filteredInvoiceOption)
    },
    setFilteredPaymentStatus({ commit }, filteredPaymentStatus) {
      commit('SET_FILTERED_PAYMENT_STATUS', filteredPaymentStatus)
    },
    setFilteredCreatedAtDate({ commit }, filteredCreatedAtDate) {
      commit('SET_FILTERED_CREATED_AT_DATE', filteredCreatedAtDate)
    },
    setFilteredPaymentMethod({ commit }, filteredPaymentMethod) {
      commit('SET_FILTERED_PAYMENT_METHOD', filteredPaymentMethod)
    },
    setFilteredReceivedMonth({ commit }, filteredReceivedMonth) {
      commit('SET_FILTERED_RECEIVED_MONTH', filteredReceivedMonth)
    },
    setFilteredPiecesLocation({ commit }, filteredPiecesLocation) {
      commit('SET_FILTERED_PIECES_LOCATION', filteredPiecesLocation)
    },
    setFilteredPendingImage({ commit }, filteredPendingImage) {
      commit('SET_FILTERED_PENDING_IMAGE', filteredPendingImage)
    },
    setFilteredPriceToBeDefined({ commit }, filteredPriceToBeDefined) {
      commit('SET_FILTERED_PRICE_TO_BE_DEFINED', filteredPriceToBeDefined)
    },
    setFilteredPaymentCurrency({ commit }, filteredPaymentCurrency) {
      commit('SET_FILTERED_PAYMENT_CURRENCY', filteredPaymentCurrency)
    },
    setFilteredCommissionStatus({ commit }, filteredCommissionStatus) {
      commit('SET_FILTERED_COMMISSION_STATUS', filteredCommissionStatus)
    },
    setFilteredSelectedIndexTab({ commit }, filteredSelectedIndexTab) {
      commit('SET_FILTERED_SELECTED_INDEX_TAB', filteredSelectedIndexTab)
    },
    setFilteredOrderPaymentStatus({ commit }, filteredOrderPaymentStatus) {
      commit('SET_FILTERED_ORDER_PAYMENT_STATUS', filteredOrderPaymentStatus)
    },
    setFilteredCashDeliveryStatus({ commit }, filteredCashDeliveryStatus) {
      commit('SET_FILTERED_CASH_DELIVERY_STATUS', filteredCashDeliveryStatus)
    },
    setFilteredApprovedByStoreDate({ commit }, filteredApprovedByStoreDate) {
      commit('SET_FILTERED_APPROVED_BY_STORE_DATE', filteredApprovedByStoreDate)
    },
    setFilteredRefundPaymentStatus({ commit }, filteredRefundPaymentStatus) {
      commit('SET_FILTERED_REFUND_PAYMENT_STATUS', filteredRefundPaymentStatus)
    },
    setFilteredInventorySelectedTab({ commit }, filteredInventorySelectedTab) {
      commit('SET_FILTERED_INVENTORY_SELECTED_TAB', filteredInventorySelectedTab)
    },
    setFilteredImportNumberSearchTerm({ commit }, filteredImportNumberSearchTerm) {
      commit('SET_FILTERED_IMPORT_NUMBER_SEARCH_TERM', filteredImportNumberSearchTerm)
    },
    clearFilteredData({ commit }) {
      commit('CLEAR_FILTERED_DATA')
    },
    setFilteredPriceEcom({ commit }, filteredPriceEcom) {
      commit('SET_FILTERED_PRICE', filteredPriceEcom)
    },
    setFilteredOutputDate({ commit }, filteredOutputDate) {
      commit('SET_FILTERED_OUTPUT_DATE', filteredOutputDate)
    },
    setFilteredDescription({ commit }, filteredDescription) {
      commit('SET_FILTERED_DESCRIPTION', filteredDescription)
    },
    setFilteredStatusPiece({ commit }, filteredStatusPiece) {
      commit('SET_FILTERED_STATUS_PIECE', filteredStatusPiece)
    },
    setFilteredMonthAndYear({ commit }, filteredMonthAndYear) {
      commit('SET_FILTERED_MONTH_AND_YEAR', filteredMonthAndYear)
    },
    setFilteredFavorite({ commit }, filteredFavorite) {
      commit('SET_FILTER_FAVORITE', filteredFavorite)
    },
    /**
     * Range filters for reports payments
     */
    setFilteredVoucherDateRange({ commit }, filteredVoucherDateRange) {
      commit('SET_FILTERED_VOUCHER_DATE_RANGE', filteredVoucherDateRange)
    },
    setFilteredRegisterDateRange({ commit }, filteredRegisterDateRange) {
      commit('SET_FILTERED_REGISTER_DATE_RANGE', filteredRegisterDateRange)
    },
    setFilteredDeliveryDateRange({ commit }, filteredDeliveryDateRange) {
      commit('SET_FILTERED_DELIVERY_DATE_RANGE', filteredDeliveryDateRange)
    },
    /**
     * Range filters for dashboard lists
     */
    setFilteredDashboardDateRange({ commit }, filteredDashboardDateRange) {
      commit('SET_FILTERED_DASHBOARD_DATE_RANGE', filteredDashboardDateRange)
    },
    /**
     * Range filters for transactions lists
     */
    setFilteredTransactionsDateRange({ commit }, filteredTransactionsDateRange) {
      commit('SET_FILTERED_TRANSACTIONS_DATE_RANGE', filteredTransactionsDateRange)
    },
    /**
     * Dates for filtering imports tables
     */
    setFilteredETDDateRange({ commit }, filteredETDDateRange) {
      commit('SET_FILTERED_ETD_DATE_RANGE', filteredETDDateRange)
    },
    setFilteredETADateRange({ commit }, filteredETADateRange) {
      commit('SET_FILTERED_ETA_DATE_RANGE', filteredETADateRange)
    },
    setFilteredOrderDateRange({ commit }, filteredOrderDateRange) {
      commit('SET_FILTERED_ORDER_DATE_RANGE', filteredOrderDateRange)
    },
    setFilteredArrivalDateRange({ commit }, filteredArrivalDateRange) {
      commit('SET_FILTERED_ARRIVAL_DATE_RANGE', filteredArrivalDateRange)
    },
    /**
     * Dates for commissions filtering
     */
    setFilteredPaymentDate({ commit }, filteredPaymentDate) {
      commit('SET_FILTERED_PAYMENT_DATE', filteredPaymentDate)
    },
    setFilteredRegisterDate({ commit }, filteredRegisterDate) {
      commit('SET_FILTERED_REGISTER_DATE', filteredRegisterDate)
    },
    /**
     * Almacena la informacion de columnas visibles
     */
    saveListParams({ commit }, listParams) {
      commit('SAVE_LIST_PARAMS', listParams)
    },
    removeListParams({ commit }, title) {
      commit('REMOVE_LIST_PARAMS', title)
    },
  },
}
