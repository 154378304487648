/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// 14/09/2022 - TAG: Make a request to load categories based on params
export const loadProcategories = async ({ commit, state }, withParams = true) => {
  commit('setLoadingProcategories', true)

  let params = {}

  if (withParams) {
    params = {
      'filter[search]': state.searchTerm,
    }
  }

  return new Promise((resolve, reject) => {
    axios.get('/true-categories', { params })
      .then(response => {
        commit('setProcategories', response.data.categories)
        commit('setBackupProcategories', response.data.categories)
        commit('setTotalProcategories', response.data.categories.length)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
      .then(() => {
        commit('setLoadingProcategories', false)
      })
  })
}

// 14/09/2022 - TAG: Create a new procategory
export const createProcategory = async ({ commit, dispatch }, procategory) => {
  commit('setLoadingProcategories', true)

  return new Promise((resolve, reject) => {
    axios.post('/true-categories/save', procategory)
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: response.data.message,
        })
        dispatch('loadProcategories')
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: 'Error al intentar crear la categoría',
        })
        reject(error)
      })
      .then(() => {
        commit('setLoadingProcategories', false)
      })
  })
}

// 14/09/2022 - TAG: Sort procategories list
export const sortCategories = async ({ commit, dispatch }, procategories) => {
  commit('setLoadingProcategories', true)

  return new Promise((resolve, reject) => {
    axios.post('/sort-categories', procategories)
      .then(response => {
        dispatch('loadProcategories')
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: 'Error al intentar ordenar las categorías',
        })
        reject(error)
      })
      .then(() => {
        commit('setLoadingProcategories', false)
      })
  })
}

// 14/09/2022 - TAG: Update an existing category
export const updateProcategory = async ({ commit, dispatch }, category) => {
  commit('setLoadingProcategories', true)

  return new Promise((resolve, reject) => {
    axios.put('/true-categories/update', category)
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: response.data.message,
        })
        dispatch('loadProcategories')
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: 'Error al intentar actualizar la categoría',
        })
        reject(error)
      })
      .then(() => {
        commit('setLoadingProcategories', false)
      })
  })
}

// 14/09/2022 - TAG: Update an existing category
export const deleteProcategory = async ({ commit, dispatch }, id) => {
  commit('setLoadingProcategories', true)

  return new Promise((resolve, reject) => {
    axios.delete(`/true-categories/delete?id=${id}`)
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: response.data.message,
        })
        dispatch('loadProcategories')
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: 'Error al intentar actualizar la categoría',
        })
        reject(error)
      })
      .then(() => {
        commit('setLoadingProcategories', false)
      })
  })
}

// 09/09/2022 - TAG: Set the search term for category searching
export const setSearchTerm = ({ commit }, searchTerm) => {
  commit('setSearchTerm', searchTerm)
}

export const updateProcategories = ({ commit }, procategories) => {
  commit('setProcategories', procategories)
}
