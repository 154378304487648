export default [
  {
    path: '/usuarios/vendedores',
    name: 'sellers-home',
    component: () => import('@/modules/users/sellers/views/SellersList.vue'),
    meta: {
      pageTitle: 'Vendedores',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Vendedores',
          active: true,
        },
      ],
      resource: 'Sellers',
      action: 'read',
      contentClass: 'sellers-module',
    },
  },
  {
    path: '/usuarios/vendedores/:id',
    name: 'detail-seller',
    component: () => import('@/modules/users/sellers/views/SellerDetail.vue'),
    props: true,
    meta: {
      pageTitle: 'Vendedor',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Vendedores',
          to: { name: 'sellers-home' },
        },
      ],
      resource: 'Sellers',
      action: 'read',
      contentClass: 'sellers-module',
      navActiveLink: 'seller-home',
    },
  },
]
