/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// eslint-disable-next-line no-unused-vars
export const loadProducts = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  commit('saveListParams', { ...loadParams })

  axios.get('/products/reports',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
        brand: loadParams.brandTerm,
        category: loadParams.categoryTerm,
        supplier: loadParams.supplierTerm,
        withoutSupplier: loadParams.withoutSupplierTerm,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadColumnsList = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/reports/columns-list')
    .then(response => {
      commit('setColumnsList', response.data.data.columnsList)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadPDFFile = async ({ commit }, query) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/reports/download?${query}`, { responseType: 'arraybuffer' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        const currentDate = new Date().toJSON().slice(0, 10)
        link.download = `reporte-${currentDate}.pdf`
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

export const setSelectedSuppliers = ({ commit }, selectedSuppliers) => {
  commit('setSelectedSuppliers', selectedSuppliers)
}

export const addSelectedSupplier = ({ commit }, selectedSupplier) => {
  commit('addSelectedSupplier', selectedSupplier)
}

export const removeSelectedSupplier = ({ commit }, selectedSupplier) => {
  commit('removeSelectedSupplier', selectedSupplier)
}

export const removeListParams = ({ commit }, title) => {
  commit('removeListParams', title)
}

export const addTab = ({ commit }, tab) => {
  commit('addTab', tab)
}

export const clearReportsSuppliersInfo = ({ commit }) => {
  commit('clearReportsSuppliersInfo')
}
