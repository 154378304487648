/* eslint-disable import/prefer-default-export */

// * 16/09/2022 - TAG: Used on requests from wainting interactions
export const setPieces = (state, pieces) => {
  state.pieces = pieces
}

export const setLocalPieces = (state, localPieces) => {
  state.localPieces = localPieces
}

export const setExternalPieces = (state, externalPieces) => {
  state.externalPieces = externalPieces
}

export const setTotalExternalPieces = (state, totalExternalPieces) => {
  state.totalExternalPieces = totalExternalPieces
}

export const setPiecesToUpdate = (state, piecesToUpdate) => {
  state.piecesToUpdate = piecesToUpdate
}

export const setPiecesToDelete = (state, piecesToDelete) => {
  state.piecesToDelete = piecesToDelete
}

export const addPiece = (state, piece) => {
  state.pieces.push(piece)
}

export const addLocalPiece = (state, piece) => {
  state.localPieces.push(piece)
  state.pieces.push(piece)
}

export const updateLocalPiece = (state, piece) => {
  state.localPieces = [piece, ...state.localPieces.filter(p => p.id !== piece.id)]
}

export const deleteLocalPiece = (state, piece) => {
  const filteredPieces = state.localPieces.filter(localPiece => localPiece.id !== piece.id)
  state.localPieces = [...filteredPieces]
}

export const addExternalPiece = (state, piece) => {
  state.externalPieces.push(piece)
  state.pieces.push(piece)
}

export const addPieceToUpdate = (state, piece) => {
  const existsAtIndex = state.piecesToUpdate
    .findIndex(p => p.IdPiece === piece.IdPiece)

  if (existsAtIndex === -1) {
    state.piecesToUpdate.push(piece)
  } else {
    const filteredPieces = state.piecesToUpdate
      .filter(p => p.IdPiece !== piece.IdPiece)

    state.piecesToUpdate = [...filteredPieces, piece]
  }
}

export const deletePieceToUpdate = (state, piece) => {
  const filteredPieces = state.piecesToUpdate.filter(pieceToUpdate => pieceToUpdate.IdPiece !== piece.IdPiece)
  state.piecesToUpdate = [...filteredPieces]
}

export const addPieceToDelete = (state, piece) => {
  state.piecesToDelete.push(piece)
}

export const setLocalSearchTerm = (state, localSearchTerm) => {
  state.localSearchTerm = localSearchTerm
}

export const setExternalSearchTerm = (state, externalSearchTerm) => {
  state.externalSearchTerm = externalSearchTerm
}

export const setEditablePiece = (state, editablePiece) => {
  state.editablePiece = editablePiece
}

export const setLoadingPieces = (state, isLoadingPieces) => {
  state.isLoadingPieces = isLoadingPieces
}

// * 14/09/2022 - TAG: Set the state response based on axios requests
export const setStateResponse = (state, stateResponse) => {
  state.stateResponse = stateResponse
}

export const setGlobalDate = (state, globalDate) => {
  state.globalDate = globalDate
}

export const setEditionMode = (state, editionMode) => {
  state.editionMode = editionMode
}

export const setAvailablePieces = (state, availablePieces) => {
  state.availablePieces = availablePieces
}

// #region V-Models form (main information)
export const setTag = (state, tag) => {
  state.tag = tag
}

export const setArriveDate = (state, arriveDate) => {
  state.arriveDate = arriveDate
}

export const setSerieNumber = (state, serieNumber) => {
  state.serieNumber = serieNumber
}

export const setImportNumber = (state, importNumber) => {
  state.importNumber = importNumber
}

export const setInvoiceNumber = (state, invoiceNumber) => {
  state.invoiceNumber = invoiceNumber
}

export const setPetitionNumber = (state, petitionNumber) => {
  state.petitionNumber = petitionNumber
}

export const setSelectedLocation = (state, selectedLocation) => {
  state.selectedLocation = selectedLocation
}

export const setSelectedCondition = (state, selectedCondition) => {
  state.selectedCondition = selectedCondition
}

export const setSelectedAvailability = (state, selectedAvailability) => {
  state.selectedAvailability = selectedAvailability
}
// #endregion

// * 06/06/2023 - TAG: Lista de todas las piezas de un producto agregas a un circuito

export const setCountPiecesQuery = (state, countPiecesQuery) => {
  state.countPiecesQuery = countPiecesQuery
}

export const setProductPiecesQuery = (state, productPiecesQuery) => {
  state.productPiecesQuery = productPiecesQuery
}

export const clearPiecesInfo = state => {
  state.pieces = []
  state.localPieces = []
  state.externalPieces = []

  state.totalExternalPieces = 0

  state.piecesToUpdate = []
  state.piecesToDelete = []

  state.localSearchTerm = ''
  state.externalSearchTerm = ''

  state.editablePiece = null

  state.globalDate = ''
  state.editionMode = false
  state.availablePieces = false

  state.tag = ''
  state.arriveDate = ''
  state.serieNumber = ''
  state.importNumber = ''
  state.invoiceNumber = ''
  state.petitionNumber = ''
  state.selectedLocation = ''
  state.selectedCondition = ''
  state.selectedAvailability = ''
}
