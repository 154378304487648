/* eslint-disable import/prefer-default-export */

// * 14/09/2022 - TAG: Functions for brands list

// 14/09/2022 - TAG: Set the brands obtained from server to show on list
export const setBrands = (state, brands) => {
  state.brands = brands
}

// 14/09/2022 - TAG: Set the loading responses state for overlay functions
export const setLoadingBrands = (state, isLoadingBrands) => {
  state.isLoadingBrands = isLoadingBrands
}

// * 14/09/2022 - TAG: Responses state

// 14/09/2022 - TAG: Set the state response based on axios requests
export const setStateResponse = (state, stateResponse) => {
  state.stateResponse = stateResponse
}

// * 09/09/2022 - TAG: Functions for set pagination attributes

// 14/09/2022 - TAG: Set the search term for personalized query on server
export const setSearchTerm = (state, searchTerm) => {
  state.searchTerm = searchTerm
}

// 14/09/2022 - TAG: Set the total count brands on server for pagination
export const setTotalBrands = (state, totalBrands) => {
  state.totalBrands = totalBrands
}

// 14/09/2022 - TAG: Set the current page for pagination on brands list
export const setCurrentPage = (state, currentPage) => {
  state.currentPage = currentPage
}

// 14/09/2022 - TAG: Set the count of brands for showing on list
export const setPerPage = (state, perPage) => {
  state.perPage = perPage
}
