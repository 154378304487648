/* eslint-disable import/prefer-default-export */

export const setSellersSuggests = (state, sellersSuggests) => {
  state.sellersSuggests = sellersSuggests
}

export const setSelectedCommissionSeller = (state, seller) => {
  state.selectedCommissionSeller = seller
}

/**
 * Agrega una orden a la lista de órdenes seleccionadas para la comisión siempre y cuando no exista
 */
export const addSelectedCommissionOrder = (state, order) => {
  const orderExists = state.selectedCommissionOrders.some(selectedOrder => selectedOrder.ID === order.ID)
  if (!orderExists) {
    state.selectedCommissionOrders.push(order)
  }

  // Si la orden ya fue eliminada previamente, se elimina de la lista de órdenes eliminadas
  const removedOrderIndex = state.removedCommissionOrders.findIndex(removedOrder => removedOrder.ID === order.ID)
  if (removedOrderIndex !== -1) {
    state.removedCommissionOrders.splice(removedOrderIndex, 1)
  }

  // Verifica si el id de la orden existe la lista de indentificadores originales de las órdenes pertenecientes a la comisión
  // si no existe se agrega a la lista de nuevas órdenes y no existe en la lista de nuevas órdenes
  const originalOrderIdExists = state.originalCommissionsOrdersIds.some(id => id === order.id_order)
  if (!originalOrderIdExists) {
    const newOrderExists = state.newCommissionOrders.some(newOrder => newOrder.ID === order.ID)
    if (!newOrderExists) {
      state.newCommissionOrders.push(order)
    }
  }

  // Agrega el identificador de la orden a la lista de identificadores de las órdenes pertenecientes a la comisión
  // si no existe y a la lista de nuevas órdenes si no existe
  const orderIdExists = state.commissionOrdersIds.some(id => id === order.id_order)
  if (!orderIdExists) {
    state.commissionOrdersIds.push(order.id_order)
  }
}

/**
 * Elimina una orden de la lista de órdenes seleccionadas para la comisión
 */
export const removeSelectedCommissionOrder = (state, order) => {
  const filteredOrders = state.selectedCommissionOrders.filter(selectedOrder => selectedOrder.ID !== order.ID)
  state.selectedCommissionOrders = [...filteredOrders]

  // Se agrega la orden a la lista de órdenes eliminadas siempre y cuando no exista en la lista de nuevas órdenes
  const newOrderIndex = state.newCommissionOrders.findIndex(newOrder => newOrder.ID === order.ID)
  if (newOrderIndex !== -1) {
    state.newCommissionOrders.splice(newOrderIndex, 1)
  }

  // Verifica si el id de la orden existe la lista de indentificadores originales de las órdenes pertenecientes a la comisión
  // si existe se agrega a la lista de órdenes eliminadas
  const originalOrderIdIndex = state.originalCommissionsOrdersIds.findIndex(id => id === order.id_order)
  if (originalOrderIdIndex !== -1) {
    state.removedCommissionOrders.push(order)
  }

  // Remueve el identificador de la orden de la lista de identificadores de las órdenes pertenecientes a la comisión
  // y si existe en la lista de nuevas órdenes se elimina
  const orderIdIndex = state.commissionOrdersIds.findIndex(id => id === order.id_order)
  if (orderIdIndex !== -1) {
    state.commissionOrdersIds.splice(orderIdIndex, 1)
  }
}

/**
 * Agrega un reembolso a la lista de reembolsos seleccionados para la comisión siempre y cuando no exista
 */
export const addSelectedCommissionRefund = (state, refund) => {
  const refundExists = state.selectedCommissionRefunds.some(selectedRefund => selectedRefund.ID === refund.ID)
  if (!refundExists) {
    state.selectedCommissionRefunds.push(refund)
  }

  // Si la devolución ya fue eliminada previamente, se elimina de la lista de devoluciones eliminadas
  const removedRefundIndex = state.removedCommissionRefunds.findIndex(removedRefund => removedRefund.ID === refund.ID)
  if (removedRefundIndex !== -1) {
    state.removedCommissionRefunds.splice(removedRefundIndex, 1)
  }

  // Verifica si el id de la devolución existe la lista de indentificadores originales de las devoluciones pertenecientes a la comisión
  // si no existe se agrega a la lista de nuevas devoluciones y no existe en la lista de nuevas devoluciones
  const originalRefundIdExists = state.originalCommissionsRefundsIds.some(id => id === refund.IdDevolution)
  if (!originalRefundIdExists) {
    const newRefundExists = state.newCommissionRefunds.some(newRefund => newRefund.ID === refund.ID)
    if (!newRefundExists) {
      state.newCommissionRefunds.push(refund)
    }
  }

  // Agrega el identificador de la devolución a la lista de identificadores de las devoluciones pertenecientes a la comisión
  // si no existe y a la lista de nuevas devoluciones si no existe
  const refundIdExists = state.commissionRefundsIds.some(id => id === refund.IdDevolution)
  if (!refundIdExists) {
    state.commissionRefundsIds.push(refund.IdDevolution)
  }
}

/**
 * Elimina un reembolso de la lista de reembolsos seleccionados para la comisión
 */
export const removeSelectedCommissionRefund = (state, refund) => {
  const filteredRefunds = state.selectedCommissionRefunds.filter(selectedRefund => selectedRefund.ID !== refund.ID)
  state.selectedCommissionRefunds = [...filteredRefunds]

  // Se agrega la devolución a la lista de devoluciones eliminadas siempre y cuando no exista en la lista de nuevas devoluciones
  const newRefundIndex = state.newCommissionRefunds.findIndex(newRefund => newRefund.ID === refund.ID)
  if (newRefundIndex !== -1) {
    state.newCommissionRefunds.splice(newRefundIndex, 1)
  }

  // Verifica si el id de la devolución existe la lista de indentificadores originales de las devoluciones pertenecientes a la comisión
  // si existe se agrega a la lista de devoluciones eliminadas
  const originalRefundIdIndex = state.originalCommissionsRefundsIds.findIndex(id => id === refund.IdDevolution)
  if (originalRefundIdIndex !== -1) {
    state.removedCommissionRefunds.push(refund)
  }

  // Remueve el identificador de la devolución de la lista de identificadores de las devoluciones pertenecientes a la comisión
  // y si existe en la lista de nuevas devoluciones se elimina
  const refundIdIndex = state.commissionRefundsIds.findIndex(id => id === refund.IdDevolution)
  if (refundIdIndex !== -1) {
    state.commissionRefundsIds.splice(refundIdIndex, 1)
  }
}

export const clearSelectedItemsListsFromCommission = state => {
  state.selectedCommissionOrders = []
  state.selectedCommissionRefunds = []

  state.commissionOrdersIds = []
  state.commissionRefundsIds = []
  state.originalCommissionsOrdersIds = []
  state.originalCommissionsRefundsIds = []

  state.newCommissionOrders = []
  state.newCommissionRefunds = []
  state.removedCommissionOrders = []
  state.removedCommissionRefunds = []
}

export const setSelectedCommissionMonthAndYear = (state, monthAndYear) => {
  state.selectedCommissionMonthAndYear = monthAndYear
}

/**
 * Resumen de la comisión
 */

export const setCommissionPercentage = (state, percentage) => {
  state.commissionPercentage = percentage
}

/**
 * Detalles de la comisión
 */

export const setCommission = (state, commission) => {
  state.commission = commission
}

/**
 * Indica si la comisión se está en modo edición
 */
export const setIsCommissionEditing = (state, isEditing) => {
  state.isCommissionEditing = isEditing
}

/**
 * Establece la lista de identificadores de las órdenes pertenecientes a la comisión
 */
export const setCommissionOrdersIds = (state, ordersIds) => {
  state.commissionOrdersIds = ordersIds
}

/**
 * Establece la lista de identificadores de las órdenes pertenecientes a la comisión
 */
export const setOriginalCommissionsOrdersIds = (state, ordersIds) => {
  state.originalCommissionsOrdersIds = ordersIds
}

/**
 * Agrega un id a la lista de identificadores de las órdenes pertenecientes a la comisión
 */
export const addCommissionOrderId = (state, orderId) => {
  state.commissionOrdersIds.push(orderId)
}

/**
 * Elimina un id de la lista de identificadores de las órdenes pertenecientes a la comisión
 */
export const removeCommissionOrderId = (state, orderId) => {
  const filteredOrdersIds = state.commissionOrdersIds.filter(id => id !== orderId)
  state.commissionOrdersIds = [...filteredOrdersIds]
}

/**
 * Establece la lista de identificadores de las devoluciones pertenecientes a la comisión
 */
export const setCommissionRefundsIds = (state, refundsIds) => {
  state.commissionRefundsIds = refundsIds
}

/**
 * Establece la lista de identificadores de las devoluciones pertenecientes a la comisión
 */
export const setOriginalCommissionsRefundsIds = (state, refundsIds) => {
  state.originalCommissionsRefundsIds = refundsIds
}

/**
 * Agrega un id a la lista de identificadores de las devoluciones pertenecientes a la comisión
 */
export const addCommissionRefundId = (state, refundId) => {
  state.commissionRefundsIds.push(refundId)
}

/**
 * Elimina un id de la lista de identificadores de las devoluciones pertenecientes a la comisión
 */
export const removeCommissionRefundId = (state, refundId) => {
  const filteredRefundsIds = state.commissionRefundsIds.filter(id => id !== refundId)
  state.commissionRefundsIds = [...filteredRefundsIds]
}

/**
 * Filtros de listas de comisiones
 */
export const setCommissionsYears = (state, years) => {
  state.commissionsYears = years
}

export const setSelectedIndexTab = (state, index) => {
  state.selectedIndexTab = index
}
