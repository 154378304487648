/* eslint-disable import/prefer-default-export */
export const setSelectedOrigin = (state, selectedOrigin) => {
  state.selectedOrigin = selectedOrigin
}

export const setSelectedDestination = (state, selectedDestination) => {
  state.selectedDestination = selectedDestination
}

export const setSelectedRequiredDate = (state, selectedRequiredDate) => {
  state.selectedRequiredDate = selectedRequiredDate
}

// #region State management list (products and circuits)
export const addTransferProduct = (state, transferProduct) => {
  const existsAtIndex = state.transferProducts.findIndex(p => p.IdProduct === transferProduct.IdProduct)
  if (existsAtIndex === -1) {
    state.transferProducts = [...state.transferProducts, transferProduct]
    state.transferPieces = [...state.transferPieces, ...transferProduct.pieces]
  } else {
    const filteredProducts = state.transferProducts.filter(product => product.IdProduct !== transferProduct.IdProduct)
    const filteredPieces = state.transferPieces.filter(piece => piece.FK_IdProduct !== transferProduct.IdProduct)

    if (transferProduct.pieces.length > 0) {
      state.transferProducts = [...filteredProducts, transferProduct]
      state.transferPieces = [...filteredPieces, ...transferProduct.pieces]
    } else {
      state.transferProducts = [...filteredProducts]
      state.transferPieces = [...filteredPieces]
    }
  }
}

export const updateTransferProduct = (state, transferProduct) => {
  const filteredProducts = state.transferProducts.filter(product => product.IdProduct !== transferProduct.IdProduct)
  const filteredPieces = state.transferPieces.filter(piece => piece.FK_IdProduct !== transferProduct.IdProduct)

  if (transferProduct.pieces.length > 0) {
    const oldTransferProduct = state.transferProducts.find(transferProductList => transferProductList.IdProduct === transferProduct.IdProduct)
    oldTransferProduct.pieces = transferProduct.pieces

    state.transferPieces = [...filteredPieces, ...transferProduct.pieces]
  } else {
    state.transferProducts = [...filteredProducts]
    state.transferPieces = [...filteredPieces]
  }
}

export const addTransferPieces = (state, transferPieces) => {
  state.transferPieces = [...state.transferPieces, ...transferPieces]
}

export const addSelectedCircuit = (state, selectedCircuit) => {
  state.transferSelectedCircuits = [...state.transferSelectedCircuits, selectedCircuit]
}

export const updateSelectedCircuit = (state, selectedCircuit) => {
  const oldTransferCircuit = state.transferSelectedCircuits.find(quoteCircuit => quoteCircuit.IdCircuit === selectedCircuit.IdCircuit)
  oldTransferCircuit.count = selectedCircuit.count
}

export const deleteSelectedCircuit = (state, selectedCircuit) => {
  const filteredCircuits = state.transferSelectedCircuits.filter(circuit => circuit.IdCircuit !== selectedCircuit.IdCircuit)
  state.transferSelectedCircuits = [...filteredCircuits]
}

export const cleanSelectedCircuits = state => {
  state.transferSelectedCircuits = []
}

export const addTransferCircuit = (state, transferCircuit) => {
  state.transferCircuits = [...state.transferCircuits, transferCircuit]
}

export const updateTransferCircuit = (state, transferCircuit) => {
  const oldCircuit = state.transferCircuits.find(circuit => circuit.uuid === transferCircuit.uuid)
  oldCircuit.count = transferCircuit.count
}

export const deleteTransferCircuit = (state, transferCircuit) => {
  const filteredCircuits = state.transferCircuits.filter(circuit => circuit.uuid !== transferCircuit.uuid)
  state.transferCircuits = [...filteredCircuits]
}

// * 21/12/2022 - TAG: For making transfer received
export const addReceivedProvitionalProduct = (state, receivedProduct) => {
  const existsAtIndex = state.receivedProvitionalProducts
    .findIndex(p => p.IdProduct === receivedProduct.IdProduct)

  if (existsAtIndex === -1) {
    state.receivedProvitionalProducts = [
      ...state.receivedProvitionalProducts,
      receivedProduct,
    ]

    state.receivedProvitionalProductsPieces = [
      ...state.receivedProvitionalProductsPieces,
      ...receivedProduct.pieces,
    ]
  } else {
    const filteredProducts = state.receivedProvitionalProducts
      .filter(product => product.IdProduct !== receivedProduct.IdProduct)

    const filteredPieces = state.receivedProvitionalProductsPieces
      .filter(piece => piece.FK_IdProduct !== receivedProduct.IdProduct)

    if (receivedProduct.pieces.length > 0) {
      state.receivedProvitionalProducts = [...filteredProducts, receivedProduct]
      state.receivedProvitionalProductsPieces = [...filteredPieces, ...receivedProduct.pieces]
    } else {
      state.receivedProvitionalProducts = [...filteredProducts]
      state.receivedProvitionalProductsPieces = [...filteredPieces]
    }
  }
}

export const addReceivedProvitionalCircuit = (state, receivedCircuit) => {
  state.receivedProvitionalCircuits = [...state.receivedProvitionalCircuits, receivedCircuit]
}

export const deleteReceivedProvitionalCircuit = (state, receivedCircuit) => {
  const filteredCircuits = state.receivedProvitionalCircuits.filter(c => c.IdCircuit !== receivedCircuit.IdCircuit)
  state.receivedProvitionalCircuits = [...filteredCircuits]
}
// #endregion State management list (products and circuits)

export const setTransfer = (state, transfer) => {
  state.transfer = transfer
}

// * 16/03/2023 - TAG: Transfer edition mode
export const setEditionModeState = (state, editionModeState) => {
  state.editionModeState = editionModeState
}

export const setSearchTerm = (state, searchTerm) => {
  state.searchTerm = searchTerm
}

export const clearDeliveryState = state => {
  state.searchTerm = ''
  state.receivedProvitionalProducts = []
  state.receivedProvitionalCircuits = []
  state.receivedProvitionalProductsPieces = []
}

export const clearFormData = (state, resetTransfer) => {
  state.selectedOrigin = ''
  state.selectedDestination = ''
  state.selectedRequiredDate = ''
  state.transferPieces = []
  state.transferProducts = []
  state.transferCircuits = []
  state.transferCircuitsProducts = []
  state.transferSelectedCircuits = []

  if (resetTransfer) {
    state.transfer = null
  }
}
