export default [
  {
    path: '/produccion/traspasos',
    name: 'transfers-list',
    component: () => import('@/modules/production/transfers/views/TransfersList.vue'),
    meta: {
      resource: 'Transfers',
      action: 'read',
      contentClass: 'transfers-module',
    },
  },
  {
    path: '/produccion/traspasos/nuevo',
    name: 'new-transfer',
    component: () => import('@/modules/production/transfers/views/TransferSaveForm.vue'),
    meta: {
      resource: 'Transfer',
      action: 'create',
      contentClass: 'transfers-module',
      navActiveLink: 'transfers-list',
    },
  },
  {
    path: '/produccion/traspasos/actualizar/:id',
    name: 'update-transfer',
    component: () => import('@/modules/production/transfers/views/TransferSaveForm.vue'),
    props: true,
    meta: {
      resource: 'Transfer',
      action: 'edit',
      contentClass: 'transfers-module',
      navActiveLink: 'transfers-list',
    },
  },
  {
    path: '/produccion/traspasos/:id',
    name: 'transfer-details',
    component: () => import('@/modules/production/transfers/views/TransferDetails.vue'),
    props: true,
    meta: {
      resource: 'Transfer',
      action: 'read',
      contentClass: 'transfers-module',
      navActiveLink: 'transfers-list',
    },
  },
]
