/* eslint-disable import/prefer-default-export */
export const getSellersSuggests = state => state.sellersSuggests
export const getNewCommissionOrders = state => state.newCommissionOrders
export const getNewCommissionRefunds = state => state.newCommissionRefunds
export const getRemovedCommissionOrders = state => state.removedCommissionOrders
export const getRemovedCommissionRefunds = state => state.removedCommissionRefunds
export const getSelectedCommissionSeller = state => state.selectedCommissionSeller
export const getSelectedCommissionOrders = state => state.selectedCommissionOrders
export const getSelectedCommissionRefunds = state => state.selectedCommissionRefunds
export const getSelectedCommissionMonthAndYear = state => state.selectedCommissionMonthAndYear

/**
 * Resumen de la comisión
 */

// Total de reembolsos de las devoluciones seleccionadas
export const getCommissionRefund = (state, getters) => {
  let total = 0

  if (state.commission) {
    return (state.commission.devolution + getters.getQuantityToAddToRefunds - getters.getQuantityToRemoveFromRefunds).toFixed(2)
  }

  state.selectedCommissionRefunds.forEach(refund => {
    total += refund.TotalMxn
  })

  return total.toFixed(2)
}

// Subtotal de venta de las órdenes seleccionadas
export const getCommissionSubtotalSelling = (state, getters) => {
  let total = 0

  if (state.commission) {
    return (state.commission.subtotal + getters.getQuantityToAddToOrders - getters.getQuantityToRemoveFromOrders).toFixed(2)
  }

  state.selectedCommissionOrders.forEach(order => {
    total += order.subtotal_mxn
  })

  return total.toFixed(2)
}

// Total de venta (se calcula al total de venta el reembolso de las devoluciones seleccionadas)
export const getCommissionTotalSelling = (_, getters) => (getters.getCommissionSubtotalSelling - getters.getCommissionRefund).toFixed(2)

// Porcentaje de comisión
export const getCommissionPercentage = state => state.commissionPercentage

// Total de la comisión (se calcula al total de venta por el porcentaje de comisión)
export const getCommissionTotal = (state, getters) => Math.round(getters.getCommissionTotalSelling * state.commissionPercentage) / 100

/**
 * Detalles de la comisión
 */

export const getCommission = state => state.commission

/**
 * Indica si la comisión se está en modo edición
 */
export const getIsCommissionEditing = state => state.isCommissionEditing

/**
 * Lista de identificadores de las órdenes pertenecientes a la comisión
 */
export const getCommissionOrdersIds = state => state.commissionOrdersIds
export const getOriginalCommissionsOrdersIds = state => state.originalCommissionsOrdersIds

/**
 * Lista de identificadores de las devoluciones pertenecientes a la comisión
 */
export const getCommissionRefundsIds = state => state.commissionRefundsIds
export const getOriginalCommissionsRefundsIds = state => state.originalCommissionsRefundsIds

/**
 * En el modo de edición, indica la cantidad a remover del subtotal que se van a eliminar de las órdenes
 */
export const getQuantityToRemoveFromOrders = state => {
  let total = 0

  state.removedCommissionOrders.forEach(order => {
    total += order.subtotal_mxn
  })

  return total
}

/**
 * En el modo de edición, indica la cantidad a sumar al subototal de nuevas órdenes
 */
export const getQuantityToAddToOrders = state => {
  let total = 0

  state.newCommissionOrders.forEach(order => {
    total += order.subtotal_mxn
  })

  return total
}

/**
 * En el modo de edición, indica la cantidad a remover de la cantidad de devoluciones
 */
export const getQuantityToRemoveFromRefunds = state => {
  let total = 0

  state.removedCommissionRefunds.forEach(refund => {
    total += refund.TotalMxn
  })

  return total
}

/**
 * En el modo de edición, indica la cantidad a sumar al total de nuevas devoluciones
 */
export const getQuantityToAddToRefunds = state => {
  let total = 0

  state.newCommissionRefunds.forEach(refund => {
    total += refund.TotalMxn
  })

  return total
}

/**
 * Filtros de listas de comisiones
*/
export const getCommissionStatus = state => state.commissionStatus
export const getCommissionsYears = state => state.commissionsYears
export const getSelectedIndexTab = state => state.selectedIndexTab
