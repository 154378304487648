export default [
  {
    path: '/reportes/proveedores',
    name: 'suppliers-home',
    component: () => import('@/modules/reports/suppliers/views/SuppliersHome.vue'),
    meta: {
      pageTitle: 'Proveedores',
      breadcrumb: [
        {
          text: 'Reportes',
        },
        {
          text: 'Proveedores',
          active: true,
        },
      ],
      resource: 'Reports',
      action: 'read',
      contentClass: 'reports-module',
    },
  },
]
