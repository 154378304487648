/* eslint-disable import/prefer-default-export */
export const getTabs = state => state.tabs
export const getListsParams = state => state.listsParams
export const getOrdersColumnsList = state => state.ordersColumnsList
export const getRefundsColumnsList = state => state.refundsColumnsList
export const getCashOrdersColumnsList = state => state.cashOrdersColumnsList

export const getLocations = state => state.locations
export const getOrderPaymentStatus = state => state.orderPaymentStatus
export const getCreatorsSuggests = state => state.creatorsSuggests
export const getPaymentStatus = state => state.paymentStatus
export const getCashDeliveryStatus = state => state.cashDeliveryStatus
export const getPaymentsCurrencies = state => state.paymentsCurrencies

export const getSelectedRefundPayment = state => state.selectedRefundPayment

/**
 * Información de las listas del dashboard
 */
export const getDashboardDateRange = state => {
  if (state.filteredDashboardDateRange) {
    return state.filteredDashboardDateRange
  }
  return state.defaultDashboardDateRange
}
export const getFilteredDashboardDateRange = state => state.filteredDashboardDateRange

/**
 * Listas del dashboard
 */
export const getDashboardPaymentsMethodsList = state => state.dashboardPaymentsMethodsList
export const getDashboardMXNCashPaymentsList = state => state.dashboardMXNCashPaymentsList
export const getDashboardUSDCashPaymentsList = state => state.dashboardUSDCashPaymentsList
export const getDashboardRegisteredRefundsList = state => state.dashboardRegisteredRefundsList
export const getDashboardRegisteredPaymentsList = state => state.dashboardRegisteredPaymentsList

/**
 * Totales del dashboard
 */
export const getDashboardPaymentsMethodsTotal = state => state.dashboardPaymentsMethodsTotal
export const getDashboardMXNCashPaymentsTotal = state => state.dashboardMXNCashPaymentsTotal
export const getDashboardUSDCashPaymentsTotal = state => state.dashboardUSDCashPaymentsTotal
export const getDashboardRegisteredRefundsTotal = state => state.dashboardRegisteredRefundsTotal
export const getDashboardRegisteredPaymentsTotal = state => state.dashboardRegisteredPaymentsTotal

/**
 * Información de las listas del dashboard
 */
export const getTransactionsDateRange = state => {
  if (state.filteredTransactionsDateRange) {
    return state.filteredTransactionsDateRange
  }
  return state.defaultTransactionsDateRange
}
export const getFilteredTransactionsDateRange = state => state.filteredTransactionsDateRange

/**
 * Listas de transacciones
 */
export const getTransactionsSellersPaymentsList = state => state.transactionsSellersPaymentsList
export const getTransactionsCustomersPaymentsList = state => state.transactionsCustomersPaymentsList
export const getTransactionsWholesalersPaymentsList = state => state.transactionsWholesalersPaymentsList

/**
 * Estatus de la orden para filtros
 */
export const getOrderStatus = state => state.orderStatus

/**
 * Estatus de la cotización para filtros
 */
export const getQuoteStatus = state => state.quoteStatus

/**
 * Bandera que indica se se ha seleccionado la opción para mostrar la información de pagos
 * en forma de gráficos dentro de la pestala de dashboard
*/
export const getShowDashboardCharts = state => state.showDashboardCharts
