export default [
  {
    path: '/produccion/importaciones',
    name: 'imports-home',
    component: () => import('@/modules/production/imports/views/ImportsHome.vue'),
    meta: {
      resource: 'Import',
      action: 'read import_numbers',
      contentClass: 'imports-module',
    },
  },
]
