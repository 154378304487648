export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]

export const adminAbilities = [
  {
    action: 'manage',
    subject: 'all',
  },
  {
    inverted: true,
    action: 'create',
    subject: 'Customers',
  },
]

export const sellerAbilities = [
  {
    action: 'read',
    subject: 'Main',
  },
  {
    action: 'read',
    subject: 'Dashboard',
  },
  {
    action: 'read',
    subject: 'Production',
  },
  {
    action: 'read',
    subject: 'Products',
  },
  {
    action: 'read',
    subject: 'Circuits',
  },
  {
    action: 'read',
    subject: 'Trade',
  },
  {
    action: 'read',
    subject: 'Quotes',
  },
  // #region Product information visibility
  {
    action: 'read',
    subject: 'product_InternalCode',
  },
  {
    action: 'read',
    subject: 'product_Brand',
  },
  {
    action: 'read',
    subject: 'product_Model',
  },
  {
    action: 'read',
    subject: 'product_Category',
  },
  {
    action: 'read',
    subject: 'product_Type',
  },
  {
    action: 'read',
    subject: 'product_Name',
  },
  {
    action: 'read',
    subject: 'product_TapestryColor',
  },
  {
    action: 'read',
    subject: 'product_PaintColor',
  },
  {
    action: 'read',
    subject: 'product_Quantity',
  },
  {
    action: 'read',
    subject: 'product_SATCode',
  },
  {
    action: 'read',
    subject: 'product_Item',
  },
  {
    inverted: true,
    action: 'read',
    subject: 'product_LowInventory',
  },
  {
    inverted: true,
    action: 'read',
    subject: 'product_Destination',
  },
  // #endregion
  // #region Pieces visibility
  {
    action: 'read',
    subject: 'piece_Tag',
  },
  {
    action: 'read',
    subject: 'piece_NoSeries',
  },
  {
    action: 'read',
    subject: 'piece_NoPetition',
  },
  {
    inverted: true,
    action: 'read',
    subject: 'piece_NoInvoice',
  },
  {
    inverted: true,
    action: 'read',
    subject: 'piece_NoImport',
  },
  {
    action: 'read',
    subject: 'piece_Condition',
  },
  {
    action: 'read',
    subject: 'piece_Availability',
  },
  {
    action: 'read',
    subject: 'piece_Location',
  },
  {
    inverted: true,
    action: 'read',
    subject: 'piece_ArriveDate',
  },
  // #endregion
  {
    action: 'read',
    subject: 'Auth',
  },
]

export const _ = undefined
