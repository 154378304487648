/* eslint-disable import/prefer-default-export */
export const setCreatorsSuggests = (state, creatorsSuggests) => {
  state.creatorsSuggests = creatorsSuggests
}

export const setRefund = (state, refund) => {
  state.refund = refund
}

export const setPaymentMethods = (state, paymentMethods) => {
  state.paymentMethods = paymentMethods
}

export const setRefundPayments = (state, refundPayments) => {
  state.refundPayments = refundPayments
}

export const setRefundsPaymentsStatusList = (state, refundsPaymentsStatusList) => {
  state.refundsPaymentsStatusList = refundsPaymentsStatusList
}

export const clearData = state => {
  state.refund = null
  state.refundPayments = []
  state.paymentMethods = []
  state.creatorsSuggests = []
}
