export default [
  {
    path: '/ecommerce/products',
    name: 'mercancia',
    component: () => import('@/modules/ecommerce/products/views/MerchandiseHome.vue'),
    meta: {
      pageTitle: 'Productos',
      breadcrumb: [
        {
          text: 'E-commerce',
          active: true,
        },
        {
          text: 'Productos',
          active: true,
        },
      ],
      resource: 'Ecommerce',
      action: 'read',
    },
  },
]
