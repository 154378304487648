export default [
  {
    path: '/usuarios/clientes',
    name: 'customers-home',
    component: () => import('@/modules/users/customers/views/CustomersList.vue'),
    meta: {
      pageTitle: 'Clientes',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Clientes',
          active: true,
        },
      ],
      resource: 'Clients',
      action: 'read',
      contentClass: 'customers-module',
    },
  },
  {
    path: '/usuarios/clientes/nuevo',
    name: 'new-customer',
    component: () => import('@/modules/users/customers/views/SaveCustomerForm.vue'),
    meta: {
      pageTitle: 'Clientes',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Clientes',
          to: { name: 'customers-home' },
        },
        {
          text: 'Nuevo',
          active: true,
        },
      ],
      resource: 'Client',
      action: 'create',
      contentClass: 'customers-module',
      navActiveLink: 'customers-home',
    },
  },
  {
    path: '/usuarios/clientes/actualizar/:id',
    name: 'update-customer',
    component: () => import('@/modules/users/customers/views/SaveCustomerForm.vue'),
    props: true,
    meta: {
      pageTitle: 'Clientes',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Clientes',
          to: { name: 'customers-home' },
        },
        {
          text: 'Actualizar',
          active: true,
        },
      ],
      resource: 'Client',
      action: 'edit',
      contentClass: 'customers-module',
      navActiveLink: 'customers-home',
    },
  },
  {
    path: '/usuarios/clientes/:id',
    name: 'detail-customer',
    component: () => import('@/modules/users/customers/views/CustomerDetail.vue'),
    props: true,
    meta: {
      pageTitle: 'Cliente',
      breadcrumb: [
        {
          text: 'Usuarios',
        },
        {
          text: 'Vendedores',
          to: { name: 'customers-home' },
        },
      ],
      resource: 'Client',
      action: 'read',
      contentClass: 'customers-module',
      navActiveLink: 'customers-home',
    },
  },
]
