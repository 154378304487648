export default () => ({
  provitionalProductsToDelete: [],
  provitionalProducts: [],
  provitionalPieces: [],
  circuitProducts: [],
  deletedProducts: [],
  circuitPieces: [],
  deletedPieces: [],
  loadedPieces: [],
})
