/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// 14/09/2022 - TAG: Make a request to load categories based on params
export const loadCategories = async ({ commit, state }, withParams = true) => {
  commit('setLoadingCategories', true)

  let params = {}

  if (withParams) {
    params = {
      'page[number]': state.currentPage,
      limit: state.perPage,
      'filter[search]': state.searchTerm,
      category: state.filteredProcategory?.IdTrueCategory,
    }
  }

  return new Promise((resolve, reject) => {
    axios.get('/categories', { params })
      .then(response => {
        commit('setCategories', response.data.categories.data)
        commit('setTotalCategories', response.data.categories.total)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
      .then(() => {
        commit('setLoadingCategories', false)
      })
  })
}

export const loadCategoriesWithoutPagination = async ({ commit }, all = false) => new Promise((resolve, reject) => {
  commit('setLoadingCategories', true)

  let url = '/categories/list'

  if (all) {
    url = '/categories/list-add'
  }

  axios.get(url)
    .then(response => {
      commit('setCategories', response.data.categories)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
    .then(() => {
      commit('setLoadingCategories', false)
    })
})

// 14/09/2022 - TAG: Create a new category
export const createCategory = async ({ commit, dispatch }, brand) => {
  commit('setLoadingCategories', true)

  return new Promise((resolve, reject) => {
    axios.post('/categories/save', brand)
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: response.data.message,
        })
        dispatch('loadCategories')
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: 'Error al intentar crear la subcategoría',
        })
        reject(error)
      })
      .then(() => {
        commit('setLoadingCategories', false)
      })
  })
}

// 14/09/2022 - TAG: Update an existing category
export const updateCategory = async ({ commit, dispatch }, category) => {
  commit('setLoadingCategories', true)

  return new Promise((resolve, reject) => {
    axios.put('/categories/update', category)
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: response.data.message,
        })
        dispatch('loadCategories')
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: 'Error al intentar actualizar la subcategoría',
        })
        reject(error)
      })
      .then(() => {
        commit('setLoadingCategories', false)
      })
  })
}

// 09/09/2022 - TAG: Set the search term for category searching
export const setSearchTerm = ({ commit }, searchTerm) => {
  commit('setSearchTerm', searchTerm)
}

// 14/09/2022 - TAG: Set the current page on category list pagination
export const setCurrentPage = ({ commit }, currentPage) => {
  commit('setCurrentPage', currentPage)
}

// 14/09/2022 - TAG: Set the count per page for category list pagination
export const setPerPage = ({ commit }, perPage) => {
  commit('setPerPage', perPage)
}

// 14/09/2022 - TAG: Set the filtered category
export const setFilteredProcategory = ({ commit, dispatch }, filteredProcategory) => {
  commit('setFilteredProcategory', filteredProcategory)
  dispatch('loadCategories')
}
