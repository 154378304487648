/* eslint-disable import/prefer-default-export */

// * 14/09/2022 - TAG: Functions for suppliers list

// 14/09/2022 - TAG: Set the suppliers obtained from server to show on list
export const setSuppliers = (state, suppliers) => {
  state.suppliers = suppliers
}

// * 14/09/2022 - TAG: General states for suppliers page

// 14/09/2022 - TAG: Set the loading responses state for overlay functions
export const setLoadingSuppliers = (state, isLoadingSuppliers) => {
  state.isLoadingSuppliers = isLoadingSuppliers
}

// 14/09/2022 - TAG: Set the state response based on axios requests
export const setStateResponse = (state, stateResponse) => {
  state.stateResponse = stateResponse
}

// * 09/09/2022 - TAG: Functions for set pagination attributes

// 14/09/2022 - TAG: Set the search term for personalized query on server
export const setSearchTerm = (state, searchTerm) => {
  state.searchTerm = searchTerm
}

// 14/09/2022 - TAG: Set the total count suppliers on server for pagination
export const setTotalSuppliers = (state, totalSuppliers) => {
  state.totalSuppliers = totalSuppliers
}

// 14/09/2022 - TAG: Set the current page for pagination on suppliers list
export const setCurrentPage = (state, currentPage) => {
  state.currentPage = currentPage
}

// 14/09/2022 - TAG: Set the count of suppliers for showing on list
export const setPerPage = (state, perPage) => {
  state.perPage = perPage
}
