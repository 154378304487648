/* eslint-disable import/prefer-default-export */

// * 16/09/2022 - TAG: Used on requests from wainting interactions
export const setLoadingCustomers = (state, isLoadingCustomers) => {
  state.isLoadingCustomers = isLoadingCustomers
}

// * 14/09/2022 - TAG: Set the global id for form interactions
export const setIdQuote = (state, idQuote) => {
  state.idQuote = idQuote
}

// * 14/09/2022 - TAG: Set the customers obtained from server to show on select form
export const setCustomers = (state, customers) => {
  state.customers = customers
}

// * 01/11/2022 - TAG: Selected customer from customer information select
export const setSelectedCustomer = (state, selectedCustomer) => {
  state.selectedCustomer = selectedCustomer
}

// #region Customer data form
export const setCustomerType = (state, customerType) => {
  state.customerType = customerType
}

export const setCustomerPhone = (state, customerPhone) => {
  state.customerPhone = customerPhone
}

export const setCustomerEmail = (state, customerEmail) => {
  state.customerEmail = customerEmail
}

export const setCustomerLastName = (state, customerLastName) => {
  state.customerLastName = customerLastName
}

export const setCustomerFirstName = (state, customerFirstName) => {
  state.customerFirstName = customerFirstName
}
// #endregion

// #region Invoice data form
// * 14/09/2022 - TAG: Set the alternative RFC from invoice data form
export const setRFC = (state, RFC) => {
  state.RFC = RFC
}

// * 14/09/2022 - TAG: Set the alternative CFDI ussage from invoice data form
export const setCFDIUssage = (state, CFDIUssage) => {
  state.CFDIUssage = CFDIUssage
}

// * 14/09/2022 - TAG: Set the alternative bussiness name from invoice data form
export const setBusinessName = (state, BusinessName) => {
  state.BusinessName = BusinessName
}
// #endregion

// #region Shipment address data
export const setShipmentCity = (state, shipmentCity) => {
  state.shipmentCity = shipmentCity
}

export const setShipmentState = (state, shipmentState) => {
  state.shipmentState = shipmentState
}

export const setShipmentEmail = (state, shipmentEmail) => {
  state.shipmentEmail = shipmentEmail
}

export const setShipmentPhone = (state, shipmentPhone) => {
  state.shipmentPhone = shipmentPhone
}

export const setShipmentColony = (state, shipmentColony) => {
  state.shipmentColony = shipmentColony
}

export const setShipmentContact = (state, shipmentContact) => {
  state.shipmentContact = shipmentContact
}

export const setShipmentAddress = (state, shipmentAddress) => {
  state.shipmentAddress = shipmentAddress
}

export const setShipmentZipCode = (state, shipmentZipCode) => {
  state.shipmentZipCode = shipmentZipCode
}
// #endregion

// #region Invoice address data
export const setInvoiceCity = (state, invoiceCity) => {
  state.invoiceCity = invoiceCity
}

export const setInvoiceState = (state, invoiceState) => {
  state.invoiceState = invoiceState
}

export const setInvoiceEmail = (state, invoiceEmail) => {
  state.invoiceEmail = invoiceEmail
}

export const setInvoicePhone = (state, invoicePhone) => {
  state.invoicePhone = invoicePhone
}

export const setInvoiceColony = (state, invoiceColony) => {
  state.invoiceColony = invoiceColony
}

export const setInvoiceContact = (state, invoiceContact) => {
  state.invoiceContact = invoiceContact
}

export const setInvoiceAddress = (state, invoiceAddress) => {
  state.invoiceAddress = invoiceAddress
}

export const setInvoiceZipCode = (state, invoiceZipCode) => {
  state.invoiceZipCode = invoiceZipCode
}
// #endregion

// #region State management list (products and circuits)
export const addQuoteProduct = (state, quoteProduct) => {
  const existsAtIndex = state.quoteProducts.findIndex(p => p.IdProduct === quoteProduct.IdProduct)
  if (existsAtIndex === -1) {
    state.quoteProducts = [...state.quoteProducts, quoteProduct]
    state.quotePieces = [...state.quotePieces, ...quoteProduct.pieces]
  } else {
    const filteredProducts = state.quoteProducts.filter(product => product.IdProduct !== quoteProduct.IdProduct)
    const filteredPieces = state.quotePieces.filter(piece => piece.FK_IdProduct !== quoteProduct.IdProduct)

    if (quoteProduct.pieces.length > 0) {
      state.quoteProducts = [...filteredProducts, quoteProduct]
      state.quotePieces = [...filteredPieces, ...quoteProduct.pieces]
    } else {
      state.quoteProducts = [...filteredProducts]
      state.quotePieces = [...filteredPieces]
    }
  }
}

export const updateQuoteProduct = (state, quoteProduct) => {
  const filteredProducts = state.quoteProducts.filter(product => product.IdProduct !== quoteProduct.IdProduct)
  const filteredPieces = state.quotePieces.filter(piece => piece.FK_IdProduct !== quoteProduct.IdProduct)

  if (quoteProduct.pieces.length > 0) {
    const oldQuoteProduct = state.quoteProducts.find(quoteProductList => quoteProductList.IdProduct === quoteProduct.IdProduct)
    oldQuoteProduct.pieces = quoteProduct.pieces

    state.quotePieces = [...filteredPieces, ...quoteProduct.pieces]
  } else {
    state.quoteProducts = [...filteredProducts]
    state.quotePieces = [...filteredPieces]
  }
}

export const addSelectedCircuit = (state, selectedCircuit) => {
  state.quoteSelectedCircuits = [...state.quoteSelectedCircuits, selectedCircuit]
}

export const updateSelectedCircuit = (state, selectedCircuit) => {
  const oldQuoteCircuit = state.quoteSelectedCircuits.find(quoteCircuit => quoteCircuit.IdCircuit === selectedCircuit.IdCircuit)
  oldQuoteCircuit.count = selectedCircuit.count
}

export const deleteSelectedCircuit = (state, selectedCircuit) => {
  const filteredCircuits = state.quoteSelectedCircuits.filter(circuit => circuit.IdCircuit !== selectedCircuit.IdCircuit)
  state.quoteSelectedCircuits = [...filteredCircuits]
}

export const cleanSelectedCircuits = state => {
  state.quoteSelectedCircuits = []
}

export const addQuoteCircuit = (state, quoteCircuit) => {
  state.quoteCircuits = [...state.quoteCircuits, quoteCircuit]
}

export const updateQuoteCircuit = (state, quoteCircuit) => {
  const oldCircuit = state.quoteCircuits.find(circuit => circuit.uuid === quoteCircuit.uuid)
  oldCircuit.count = quoteCircuit.count
}

export const deleteQuoteCircuit = (state, quoteCircuit) => {
  const filteredCircuits = state.quoteCircuits.filter(circuit => circuit.uuid !== quoteCircuit.uuid)
  state.quoteCircuits = [...filteredCircuits]
}
// #endregion

// #region Delivery type and methods
export const setArriveDate = (state, arriveDate) => {
  state.arriveDate = arriveDate
}

export const setInvoiceRequired = (state, invoiceRequired) => {
  state.invoiceRequired = invoiceRequired
}

export const setSelectedDeliveryType = (state, selectedDeliveryType) => {
  state.selectedDeliveryType = selectedDeliveryType
}

export const setSelectedDeliveryMethod = (state, selectedDeliveryMethod) => {
  state.selectedDeliveryMethod = selectedDeliveryMethod
}
// #endregion

// #region Extra information
export const setDiscount = (state, discount) => {
  state.discount = discount
}

export const setExchangeType = (state, exchangeType) => {
  state.exchangeType = exchangeType
}

export const setShipmentCost = (state, shipmentCost) => {
  state.shipmentCost = shipmentCost
}

export const setInstallationCost = (state, installationCost) => {
  state.installationCost = installationCost
}
// #endregion

export const setIncomesYears = (state, incomesYears) => {
  state.incomesYears = incomesYears
}

export const seClientstSuggests = (state, clientstSuggests) => {
  state.clientstSuggests = clientstSuggests
}
