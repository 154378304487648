/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

export async function getDashboard({ commit }) {
  return new Promise((resolve, reject) => {
    axios.get('/dashboard')
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function gateDateDashboard({ commit }, dateParams) {
  return new Promise((resolve, reject) => {
    axios.get(`/dashboard?from=${dateParams.from}&to=${dateParams.to}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function loadRegisteredPayments({ commit }, dateParams = null) {
  return new Promise((resolve, reject) => {
    let route = '/total-payment-recorded'

    if (dateParams) {
      route = `/total-payment-recorded?from=${dateParams.from}&to=${dateParams.to}`
    }

    axios.get(route)
      .then(response => {
        commit('setRegisteredPayments', response.data.data.total_payment_recorded)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function loadTotalSaleByUser({ commit }, { userId, dateParams = null }) {
  return new Promise((resolve, reject) => {
    let route = `/total-sales?user=${userId}`

    if (dateParams) {
      route = `/total-sales?from=${dateParams.from}&to=${dateParams.to}&user=${userId}`
    }

    axios.get(route)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export async function loadTotalRegisteredPaymentsByUser({ commit }, { userId, dateParams = null }) {
  return new Promise((resolve, reject) => {
    let route = `/total-payment-recorded?user=${userId}`

    if (dateParams) {
      route = `/total-payment-recorded?from=${dateParams.from}&to=${dateParams.to}&user=${userId}`
    }

    axios.get(route)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const listenerParmasDashboard = ({ commit }, params) => {
  commit('listenerParmasDashboard', params)
}

export const setTotalSaleRangeDates = ({ commit }, totalSaleRangeDates) => {
  commit('setTotalSaleRangeDates', totalSaleRangeDates)
}

export const setRegisteredPaymentsRangeDates = ({ commit }, registeredPaymentsRangeDates) => {
  commit('setRegisteredPaymentsRangeDates', registeredPaymentsRangeDates)
}
