/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// eslint-disable-next-line no-unused-vars
export const loadWholesalers = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/users/wholesalers',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const createWholesaler = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/users/wholesaler', formData)
    .then(response => {
      console.log(response, 'en el salvado Correcto')
      resolve(response)
    })
    .catch(error => {
      console.log(error, 'en el salvado Incorrecto')
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updateWholesaler = async ({ commit }, formData) => new Promise((resolve, reject) => {
  formData.append('_method', 'PUT')

  axios.post('/users/wholesaler', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadWholesaler = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/users/wholesaler?id=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const deleteWholesaler = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.delete(`/users/wholesaler?id=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadZipCode = async ({ commit }, zipCode) => new Promise((resolve, reject) => {
  axios.get(`/users/postal-codes?code=${zipCode}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
