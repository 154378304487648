/* eslint-disable import/prefer-default-export */
// * 08/09/2022 - TAG: Get the filtered products stored
export const getProducts = state => state.products

// #region V-Models form (Selectables data)
export const getTypes = state => state.types
export const getWeights = state => state.weights
export const getPaintColors = state => state.paintColors
export const getTapestryColors = state => state.tapestryColors
export const getSuggests = state => state.suggests
// #endregion

// #region V-Models form (main information)
export const getRealCount = state => state.realCount
export const getSelectedType = state => state.selectedType
export const getSelectedDate = state => state.selectedDate
export const getPreSale = state => state.preSale
export const getTransferableProductId = state => state.transferableProductId
// #endregion

export const getOriginProductRoute = state => state.originProductRoute

export const getSearchTerm = state => state.searchTerm
export const getLocationTerm = state => state.locationTerm
export const getSearchTerms = state => state.searchTerm.split(' ')

// * 21/04/2023 - TAG: For global inventory filter control
export const getFilteredProductsCount = state => state.filteredProductsCount
export const getFilteredLowInventoryProductsCount = state => state.filteredLowInventoryProductsCount
export const getFilteredOutInventoryProductsCount = state => state.filteredOutInventoryProductsCount
export const getLoadingFilteredLists = state => state.loadingFilteredLists

// * 17/05/2023 - TAG: Dimensiones del producto
export const getWidth = state => state.width
export const getHeight = state => state.height
export const getWeight = state => state.weight
export const getLength = state => state.length
export const getSelectedWeight = state => state.selectedWeight

// * 17/05/2023 - TAG: Precios del producto
export const getCostUtility = state => state.costUtility
export const getExchangeRate = state => state.exchangeRate
export const getProvidersPriceUsd = state => state.providersPriceUsd
export const getUnitPriceInvoiceMn = state => state.unitPriceInvoiceMn
export const getProvidersCostingMn = state => state.providersCostingMn
export const getUnitPriceInvoiceUsd = state => state.unitPriceInvoiceUsd

// * 18/05/2023 - TAG: Precios de lista de productos nuevos y remanufacturados
export const getMinNormalPrice = state => state.minNormalPrice
export const getMaxNormalPrice = state => state.maxNormalPrice
// Respaldo para los precios normales de lista
export const getBackMinNormalPrice = state => state.backMinNormalPrice
export const getBackMaxNormalPrice = state => state.backMaxNormalPrice
// Banderas para el setting de precios o si habrá precios de mayorista
export const getPriceToBeDecided = state => state.priceToBeDecided
export const getWholesalerPrice = state => state.wholesalerPrice
// Precios de mayorista
export const getMinWholesalerPrice = state => state.minWholesalerPrice
export const getMaxWholesalerPrice = state => state.maxWholesalerPrice
// Respaldo de los precios de mayorista
export const getBackMinWholesalerPrice = state => state.backMinWholesalerPrice
export const getBackMaxWholesalerPrice = state => state.backMaxWholesalerPrice

// * 18/05/2023 - TAG: Precios de los productos usados
export const getLfPrice = state => state.lfPrice
export const getDrPrice = state => state.drPrice
export const getSuggestMaxPrice = state => state.suggestMaxPrice
// Respaldo de los precios de productos usados
export const getBackLfPrice = state => state.backLfPrice
export const getBackDrPrice = state => state.backDrPrice
export const getBackSuggestMaxPrice = state => state.backSuggestMaxPrice

// * 04/09/2023 - TAG: Precio de oferta
export const getOfferPrice = state => state.offerPrice
// Respaldo del precio de oferta
export const getBackOfferPrice = state => state.backOfferPrice

// * 18/05/2023 - TAG: Información general del producto
export const getName = state => state.name
export const getItem = state => state.item
export const getModel = state => state.model
export const getImages = state => state.images
export const getImage01 = state => state.image01
export const getImage02 = state => state.image02
export const getImage03 = state => state.image03
export const getSatCode = state => state.satCode
export const getLowCount = state => state.lowCount
export const getBackItem = state => state.backItem
export const getIdProduct = state => state.idProduct
export const getSubmitted = state => state.submitted
export const getDestination = state => state.destination
export const getDescription = state => state.description
export const getInternalCode = state => state.internalCode
export const getSelectedBrand = state => state.selectedBrand
export const getSelectedPaint = state => state.selectedPaint
export const getPendingImage = state => state.pendingImage
export const getImagesLoaded = state => state.imagesLoaded
export const getFilteredOptions = state => state.filteredOptions
export const getLoadingDataForm = state => state.loadingDataForm
export const getSelectedCategory = state => state.selectedCategory
export const getSelectedTapestry = state => state.selectedTapestry
export const getSelectedSupplier = state => state.selectedSupplier
export const getCanceledTypeChange = state => state.canceledTypeChange
export const getBackSelectedSupplier = state => state.backSelectedSupplier
