/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// eslint-disable-next-line no-unused-vars
export const loadCustomers = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/users/customers',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const createCustomer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/users/customer', formData)
    .then(response => {
      console.log(response, 'en el salvado Correcto')
      resolve(response)
    })
    .catch(error => {
      console.log(error, 'en el salvado Incorrecto')
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updateCustomer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  formData.append('_method', 'PUT')

  axios.post('/users/customer', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadCustomer = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/users/customer?id=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const deleteCustomer = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.delete(`/users/customer?id=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadZipCode = async ({ commit }, zipCode) => new Promise((resolve, reject) => {
  axios.get(`/users/postal-codes?code=${zipCode}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// * 08/11/2022 - TAG: Set the original route after calling customer form
export const setOriginRouteToCustomerForm = ({ commit }, originRouteToCustomerForm) => {
  commit('setOriginRouteToCustomerForm', originRouteToCustomerForm)
}
