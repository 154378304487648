/* eslint-disable import/prefer-default-export */
export const getOrder = state => state.order
export const getSearchTerm = state => state.searchTerm

/**
 * Information for filters orders list
 */
export const getOrdersStatus = state => state.ordersStatus
export const getPaymentsStatus = state => state.paymentsStatus

/**
 * For orders list filters
 */
export const getClientsSuggests = state => state.clientsSuggests
export const getCreatorsSuggests = state => state.creatorsSuggests

function searcheableProduct(product, searchTerm) {
  return (product.Name?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.Model?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.Type?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.Item?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.name_brand?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.name_categorie?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.NamePaint?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.NameTapestry?.toLowerCase().includes(searchTerm.toLowerCase())
        || product.QtyIventory?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        || product.Qty?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        || product.UnitPrice?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        || product.Subtotal?.toString().toLowerCase().includes(searchTerm.toLowerCase())
  )
      || product.pieces.filter(piece => piece.Tag.toLowerCase().includes(searchTerm.toLowerCase())
        || piece.NoSeries.toLowerCase().includes(searchTerm.toLowerCase())
        || piece.NoPetition.toLowerCase().includes(searchTerm.toLowerCase())
        || piece.Condition.toLowerCase().includes(searchTerm.toLowerCase())
        || piece.Availability.toLowerCase().includes(searchTerm.toLowerCase())
        || piece.Location.toLowerCase().includes(searchTerm.toLowerCase())).length > 0
}

/**
 * Products list and its pieces for order details
 */
export const getDetailProducts = (state, getters) => state.detailProducts.filter(product => {
  const searchTerm = getters.getSearchTerm
  const searcheable = searchTerm.length > 0
    ? searcheableProduct(product, searchTerm)
    : true

  return searcheable
})

/**
 * Products without delivered pieces showed in delivery type select (En suministro)
 */
export const getDetailProductsWithoutDeliveredPieces = (state, getters) => {
  const filteredProducts = []
  const detailProducts = getters.getDetailProducts

  detailProducts.forEach(product => {
    const alterProduct = { ...product }

    if (alterProduct.Delivered !== 'Entregado') {
      const isDeliveredPiece = piece => piece.Delivered === 'Entregado'
      const allDeliveredPieces = alterProduct.pieces.every(isDeliveredPiece)

      if (!allDeliveredPieces) {
        const filteredPieces = []
        alterProduct.pieces.forEach(piece => {
          if (piece.Delivered !== 'Entregado' && piece.Delivered !== 'Si') {
            filteredPieces.push(piece)
          }
        })

        alterProduct.pieces = filteredPieces
      }

      filteredProducts.push(alterProduct)
    }
  })

  return filteredProducts
}

/**
 * Get all the order circuits
 */
export const getDetailCircuits = (state, getters) => state.detailCircuits.filter(circuit => {
  const searchTerm = getters.getSearchTerm
  const searcheable = searchTerm.length > 0
    ? (
      (circuit.Name?.toLowerCase().includes(searchTerm.toLowerCase())
        || circuit.Location?.toLowerCase().includes(searchTerm.toLowerCase())
        || circuit.PriceMax?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        || circuit.PriceMin?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        || circuit.Qty?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
      || circuit.products.filter(product => searcheableProduct(product, searchTerm))
    )
    : true

  return searcheable
})

/**
 * Get all the order circuits without delivered state
 */
export const getDetailCircuitsWithoutDeliveredState = (state, getters) => {
  const filteredCircuits = []
  const detailCircuits = getters.getDetailCircuits

  detailCircuits.forEach(circuit => {
    if (circuit.Delivered !== 'Entregado' && circuit.Delivered !== 'Si') {
      filteredCircuits.push({ ...circuit })
    }
  })

  return filteredCircuits
}

/**
 * Get all the order pieces
 */
export const getDetailProductsPieces = state => state.detailProductsPieces

/**
 * Get all the order pieces without delivered state
 */
export const getDetailProductsPiecesWithoutDeliveredState = (state, getters) => {
  const formatedPieces = []
  const detailProductsPieces = getters.getDetailProductsPieces

  detailProductsPieces.forEach(piece => {
    if (piece.Delivered !== 'Entregado' && piece.Delivered !== 'Si') {
      formatedPieces.push({ ...piece })
    }
  })

  return formatedPieces
}

/**
 * Delivery types for orders
 */
export const getDeliveryTypes = (state, getters) => {
  const detailProductsPiecesWithoutDeliveredStateCount = getters.getDetailProductsPiecesWithoutDeliveredState
  const detailCircuitsWithoutDeliveredStateCount = getters.getDetailCircuitsWithoutDeliveredState

  if ((detailProductsPiecesWithoutDeliveredStateCount.length === 1 && detailCircuitsWithoutDeliveredStateCount.length === 0)
  || (detailCircuitsWithoutDeliveredStateCount.length === 1 && detailProductsPiecesWithoutDeliveredStateCount.length === 0)) {
    return [
      {
        id: 6,
        text: 'Entrega completa',
      },
    ]
  }

  return state.deliveryTypes
}

export const getInvoicedOptions = state => state.invoicedOptions

/**
 * For making partial delivery
 */
export const getPartialDeliveryProvitionalProducts = state => state.partialDeliveryProvitionalProducts
export const getPartialDeliveryProvitionalCircuits = state => state.partialDeliveryProvitionalCircuits
export const getPartialDeliveryProvitionalProductsPieces = state => state.partialDeliveryProvitionalProductsPieces

/**
 * Order edition
 */
export const getEditionModeState = state => state.editionModeState

/**
 * For order prices
 */
export const getSubtotalUSD = state => {
  let subtotal = 0

  state.detailProducts.forEach(product => {
    subtotal += product.unitPrice * product.pieces.length
  })

  state.detailCircuits.forEach(detailCircuit => {
    detailCircuit.products.forEach(product => {
      subtotal += product.unitPrice * product.pieces.length
    })
  })

  state.circuitsWithOfferPriceSelected.forEach(quoteCircuit => {
    subtotal += quoteCircuit.OfferPrice
  })

  return subtotal
}

/**
 * Get the subtotal MXN for quote
 */
export const getSubtotalMXN = (state, getters) => {
  const realSubtotalMXN = getters.getSubtotalUSD * state.order.total_de_cotizacion.ExchangeRate
  const discount = state.order.total_de_cotizacion.Discount

  if (discount > 0) {
    return realSubtotalMXN - (realSubtotalMXN * (discount / 100))
  }

  return realSubtotalMXN
}

/**
 * Get the total MXN for quote
 */
export const getTotalMXN = (state, getters) => {
  const subtotalMXN = getters.getSubtotalMXN
  let totalMXN = subtotalMXN

  if (state.invoiceRequired === 'Sí') {
    totalMXN += getters.getIVA
  }

  const shipmentCost = state.order.total_de_cotizacion.ShippingCost

  if (shipmentCost > 0) {
    totalMXN += Number(shipmentCost)
  }

  const installationCost = state.order.total_de_cotizacion.InstallationCost

  if (installationCost > 0) {
    totalMXN += Number(installationCost)
  }

  return totalMXN.toFixed(2)
}

/**
 * Get the IVA for quote
 */
export const getIVA = (state, getters) => {
  if (state.invoiceRequired === 'Sí') {
    if (state.countToInvoice) {
      return ((16 / 100) * state.countToInvoice)
    }
    return ((16 / 100) * getters.getSubtotalMXN)
  }
  return 0
}

export const getLocations = state => state.locations
export const getCanMakeRefund = state => state.canMakeRefund
export const getCountToInvoice = state => state.countToInvoice
export const getInvoiceRequired = state => state.invoiceRequired
export const getSelectedLocation = state => state.selectedLocation

/**
 * Circuits with offer price selected
 */
export const getCircuitsWithOfferPriceSelected = state => state.circuitsWithOfferPriceSelected
export const getListsParams = state => state.listsParams
export const getColumnsList = state => state.columnsList

/**
 * Commission statuses list for orders list
 */
export const getCommissionStatus = state => state.commissionStatus
