/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// eslint-disable-next-line no-unused-vars
export const createTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/store', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      console.log(response, 'en el salvado Correcto')
      resolve(response)
    })
    .catch(error => {
      console.log(error, 'en el salvado Incorrecto')
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadTransfers = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/transfers',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
        date: loadParams.dateTerm,
        status: loadParams.statusTerm,
        origin: loadParams.originTerm,
        destiny: loadParams.destinationTerm,
        ...(loadParams.sortTerm === 'Descendente' ? { sort: 'ID' } : { sort: '-ID' }),
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadTransfer = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/transfer?id=${id}`)
    .then(response => {
      commit('setSelectedOrigin', response.data.data.transfer.LocationOrigin)
      commit('setSelectedDestination', { text: response.data.data.transfer.LocationDestiny })
      commit('setSelectedRequiredDate', response.data.data.transfer.DateRequired)
      commit('setTransfer', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const confirmTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/confirm', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const confirmTransferRequiredDate = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/confirm-date', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const approveTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/approve', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const rejectTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/decline', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const makeForDeliveryTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/ready', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const cancelTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/cancel', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const makeInTransitTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/transit', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const receivedTransfer = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/transfer/received', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updateTransfer = async ({ commit }, { id, formData }) => new Promise((resolve, reject) => {
  formData.append('_method', 'PUT')

  axios.post(`/transfer/update/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const deleteTransfer = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.delete(`/transfer/delete?id=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadPDFFile = async ({ commit }, { IdTransfer, ID }) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/transfer/download?id=${IdTransfer}`, { responseType: 'arraybuffer' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `orden_traspaso_${ID}.pdf`
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const loadStatusTransfer = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/transfer/status/list/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// #region Transfer information
export const setSelectedOrigin = ({ commit }, selectedOrigin) => {
  commit('setSelectedOrigin', selectedOrigin)
}

export const setSelectedDestination = ({ commit }, selectedDestination) => {
  commit('setSelectedDestination', selectedDestination)
}

export const setSelectedRequiredDate = ({ commit }, selectedRequiredDate) => {
  commit('setSelectedRequiredDate', selectedRequiredDate)
}
// #endregion

// #region Transfer interactions
export const addTransferPieces = ({ commit }, transferPieces) => {
  commit('addTransferPieces', transferPieces)
}
// #endregion

// #region State management list (products and circuits)
export const addReceivedProvitionalProduct = ({ commit }, receivedProduct) => {
  commit('addReceivedProvitionalProduct', receivedProduct)
}

export const addReceivedProvitionalCircuit = ({ commit }, receivedCircuit) => {
  commit('addReceivedProvitionalCircuit', receivedCircuit)
}

export const deleteReceivedProvitionalCircuit = ({ commit }, receivedCircuit) => {
  commit('deleteReceivedProvitionalCircuit', receivedCircuit)
}

export const addTransferProduct = ({ commit }, transferProduct) => {
  commit('addTransferProduct', transferProduct)
}

export const updateTransferProduct = ({ commit }, transferProduct) => {
  commit('updateTransferProduct', transferProduct)
}

export const addSelectedCircuit = ({ commit }, selectedCircuit) => {
  commit('addSelectedCircuit', selectedCircuit)
}

export const updateSelectedCircuit = ({ commit }, selectedCircuit) => {
  commit('updateSelectedCircuit', selectedCircuit)
}

export const deleteSelectedCircuit = ({ commit }, selectedCircuit) => {
  commit('deleteSelectedCircuit', selectedCircuit)
}

export const cleanSelectedCircuits = ({ commit }) => {
  commit('cleanSelectedCircuits')
}

export const addTransferCircuit = ({ commit }, transferCircuit) => {
  commit('addTransferCircuit', transferCircuit)
}

export const updateTransferCircuit = ({ commit }, transferCircuit) => {
  commit('updateTransferCircuit', transferCircuit)
}

export const deleteTransferCircuit = ({ commit }, transferCircuit) => {
  commit('deleteTransferCircuit', transferCircuit)
}
// #endregion

// * 16/03/2023 - TAG: Transfer edition mode
export const setEditionModeState = ({ commit }, editionModeState) => {
  commit('setEditionModeState', editionModeState)
}

export const setSearchTerm = ({ commit }, searchTerm) => {
  commit('setSearchTerm', searchTerm)
}

export const clearDeliveryState = ({ commit }) => {
  commit('clearDeliveryState')
}

export const clearFormData = ({ commit }, resetTransfer = true) => {
  commit('clearFormData', resetTransfer)
}
