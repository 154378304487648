/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// * 08/09/2022 - TAG: Make a request to load brands based on params
export const loadBrands = async ({ commit, state }, withParams = true) => {
  commit('setLoadingBrands', true)

  let params = {}

  if (withParams) {
    params = {
      sort: 'Name',
      'page[number]': state.currentPage,
      limit: state.perPage,
      'filter[search]': state.searchTerm,
    }
  }

  return new Promise((resolve, reject) => {
    axios.get('/brands', { params })
      .then(response => {
        commit('setBrands', response.data.brands.data)
        commit('setTotalBrands', response.data.brands.total)
        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
      .then(() => {
        commit('setLoadingBrands', false)
      })
  })
}

export const loadBrandsWithoutPagination = async ({ commit }, all = false) => new Promise((resolve, reject) => {
  commit('setLoadingBrands', true)

  let url = '/brands/list'

  if (all) {
    url = '/brands/list-add'
  }

  axios.get(url)
    .then(response => {
      commit('setBrands', response.data.brands)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
    .then(() => {
      commit('setLoadingBrands', false)
    })
})

// * 14/09/2022 - TAG: Create a new brand
export const createBrand = async ({ commit, dispatch }, brand) => {
  commit('setLoadingBrands', true)

  return new Promise((resolve, reject) => {
    axios.post('/brands/save', brand)
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: response.data.message,
        })
        dispatch('loadBrands')
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: 'Error al intentar crear la marca',
        })
        reject(error)
      })
      .then(() => {
        commit('setLoadingBrands', false)
      })
  })
}

// 14/09/2022 - TAG: Update an existing brand
export const updateBrand = async ({ commit, dispatch }, brand) => {
  commit('setLoadingBrands', true)

  return new Promise((resolve, reject) => {
    axios.put('/brands/update', brand)
      .then(response => {
        commit('setStateResponse', {
          success: true,
          message: response.data.message,
        })
        dispatch('loadBrands')
        resolve(response)
      })
      .catch(error => {
        commit('setStateResponse', {
          success: false,
          message: 'Error al intentar actualizar la marca',
        })
        reject(error)
      })
      .then(() => {
        commit('setLoadingBrands', false)
      })
  })
}

// 09/09/2022 - TAG: Set the search term for brand searching
export const setSearchTerm = ({ commit }, searchTerm) => {
  commit('setSearchTerm', searchTerm)
}

// 14/09/2022 - TAG: Set the current page on brand list pagination
export const setCurrentPage = ({ commit }, currentPage) => {
  commit('setCurrentPage', currentPage)
}

// 14/09/2022 - TAG: Set the count per page for brand list pagination
export const setPerPage = ({ commit }, perPage) => {
  commit('setPerPage', perPage)
}
