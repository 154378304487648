/* eslint-disable import/prefer-default-export */

export const setCities = (state, cities) => {
  state.cities = cities
}

export const setStates = (state, states) => {
  state.states = states
}

export const setColonies = (state, colonies) => {
  state.colonies = colonies
}

// * 08/11/2022 - TAG: Set the original route after calling customer form
export const setOriginRouteToCustomerForm = (state, originRouteToCustomerForm) => {
  state.originRouteToCustomerForm = originRouteToCustomerForm
}
