/* eslint-disable import/prefer-default-export */
export const getPieces = state => state.pieces

function searcheablePiece(piece, searchTerm) {
  return piece.Tag.toLowerCase().includes(searchTerm.toLowerCase())
  || piece.NoSeries.toLowerCase().includes(searchTerm.toLowerCase())
  || piece.NoPetition.toLowerCase().includes(searchTerm.toLowerCase())
  || piece.NoInvoice.toLowerCase().includes(searchTerm.toLowerCase())
  || piece.NoImport.toLowerCase().includes(searchTerm.toLowerCase())
  || piece.Condition.toLowerCase().includes(searchTerm.toLowerCase())
  || piece.Availability.toLowerCase().includes(searchTerm.toLowerCase())
  || piece.Location.toLowerCase().includes(searchTerm.toLowerCase())
  || piece.ArriveDate.toLowerCase().includes(searchTerm.toLowerCase())
}

export const getLocalPieces = (state, getters) => state.localPieces.filter(piece => {
  const searchTerm = getters.getLocalSearchTerm
  const searcheable = searchTerm.length > 0
    ? searcheablePiece(piece, searchTerm)
    : true

  return searcheable
})

export const getExternalPieces = state => state.externalPieces
export const getPiecesToUpdate = state => state.piecesToUpdate
export const getPiecesToDelete = state => state.piecesToDelete

export const getTotalExternalPieces = state => state.totalExternalPieces

export const getPiecesCount = state => state.localPieces.length + state.totalExternalPieces

export const getLocalSearchTerm = state => state.localSearchTerm
export const getExternalSearchTerm = state => state.externalSearchTerm

export const getEditablePiece = state => state.editablePiece

// #region V-models form (selectables data)
export const getLocations = state => state.locations
export const getConditions = state => state.conditions
export const getPiecesStatus = state => state.piecesStatus
export const getAvailabilities = state => state.availabilities
// #endregion

export const getGlobalDate = state => state.globalDate
export const getEditionMode = state => state.editionMode
export const getAvailablePieces = state => state.availablePieces

// #region V-Models form (main information)
export const getTag = state => state.tag
export const getArriveDate = state => state.arriveDate
export const getSerieNumber = state => state.serieNumber
export const getImportNumber = state => state.importNumber
export const getInvoiceNumber = state => state.invoiceNumber
export const getPetitionNumber = state => state.petitionNumber
export const getSelectedLocation = state => state.selectedLocation
export const getSelectedCondition = state => state.selectedCondition
export const getSelectedAvailability = state => state.selectedAvailability
// #endregion

// * 06/06/2023 - TAG: Lista de todas las piezas de un producto agregas a un circuito

export const getCountPiecesQuery = state => state.countPiecesQuery
export const getProductPiecesQuery = state => state.productPiecesQuery
