export default () => ({
  listsParams: [],
  newProductsColumnsList: [],
  usedProductsColumnsList: [],

  tabs: [
    {
      title: 'Productos nuevos',
      value: 'new',
    },
    {
      title: 'Productos usados',
      value: 'used',
    },
  ],

  isEditionModeEnabled: false,
  isLoadingGlobalResponse: false,
})
