export default () => ({
  refund: null,

  // * 12/04/2023 - TAG: Locations for refunds
  refundslocationsList: [
    {
      text: 'Bodega GDL',
    },
    {
      text: 'Bodega CDMX',
    },
    {
      text: 'Tienda',
    },
  ],

  // * 12/04/2023 - TAG: Statuses for refunds
  refundsStatusList: [
    {
      text: 'Solicitud',
    },
    {
      text: 'Aprobada',
    },
    {
      text: 'Rechazada',
    },
    {
      text: 'Recibida',
    },
    {
      text: 'Cancelada',
    },
  ],

  // * 12/04/2023 - TAG: Statuses for refunds
  refundsPaymentsStatusList: [
    {
      text: 'Pagado',
    },
    {
      text: 'Pendiente',
    },
  ],

  creatorsSuggests: [],
  paymentMethods: [],
  refundPayments: [],

  /**
   * Commission statuses list for refunds list
   */
  commissionStatus: [
    {
      text: 'Pendiente',
    },
    {
      text: 'Por aplicar',
    },
    {
      text: 'Aplicado',
    },
  ],
})
