/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// eslint-disable-next-line no-unused-vars
export const loadImports = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  commit('saveListParams', { ...loadParams })

  axios.get('/import-numbers',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        preOwned: loadParams.preOwned,
        'filter[search]': loadParams.searchTerm,
        availability: loadParams.availabilityTerm,
        etd: loadParams.etdDateStartTerm,
        etdEnd: loadParams.etdDateEndTerm,
        eta: loadParams.etaDateStartTerm,
        etaEnd: loadParams.etaDateEndTerm,
        orderDate: loadParams.orderDateStartTerm,
        orderDateEnd: loadParams.orderDateEndTerm,
        arriveDate: loadParams.arrivalDateStartTerm,
        arriveDateEnd: loadParams.arrivalDateEndTerm,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updateImportNumber = async ({ commit }, { formData, loadParams }) => {
  formData.append('_method', 'PUT')

  return new Promise((resolve, reject) => {
    axios.post('/update-import-numbers', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        preOwned: loadParams.preOwned,
        'filter[search]': loadParams.searchTerm,
        availabilityFilter: loadParams.availabilityTerm,
        etdFilter: loadParams.etdDateStartTerm,
        etdEndFilter: loadParams.etdDateEndTerm,
        etaFilter: loadParams.etaDateStartTerm,
        etaEndFilter: loadParams.etaDateEndTerm,
        orderDateFilter: loadParams.orderDateStartTerm,
        orderDateEndFilter: loadParams.orderDateEndTerm,
        arriveDateFilter: loadParams.arrivalDateStartTerm,
        arriveDateEndFilter: loadParams.arrivalDateEndTerm,
      },
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const loadImportsColumnsList = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/import-columns-list')
    .then(response => {
      commit('setNewProductsColumnsList', response.data.data.columnsList)
      commit('setUsedProductsColumnsList', response.data.data.columnsList)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadImportsTotal = async ({ commit }, data) => new Promise((resolve, reject) => {
  axios.post('/import-sum', JSON.stringify(data),
    {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const sendCSVFileEmail = async ({ commit }, { url, loadParams }) => new Promise((resolve, reject) => {
  const params = {
    preOwned: loadParams.preOwned,
    'filter[search]': loadParams.searchTerm,
    availability: loadParams.availabilityTerm,
    etd: loadParams.etdDateStartTerm,
    etdEnd: loadParams.etdDateEndTerm,
    eta: loadParams.etaDateStartTerm,
    etaEnd: loadParams.etaDateEndTerm,
    orderDate: loadParams.orderDateStartTerm,
    orderDateEnd: loadParams.orderDateEndTerm,
    arriveDate: loadParams.arrivalDateStartTerm,
    arriveDateEnd: loadParams.arrivalDateEndTerm,
  }

  loadParams.activedColumnsTerm.forEach((column, index) => {
    params[`active[${index}]`] = column.value
  })

  axios.get(url, { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export const setIsEditionModeEnabled = ({ commit }, isEditionModeEnabled) => {
  commit('setIsEditionModeEnabled', isEditionModeEnabled)
}

export const setIsLoadingGlobalResponse = ({ commit }, isLoadingGlobalResponse) => {
  commit('setIsLoadingGlobalResponse', isLoadingGlobalResponse)
}

export const clearImportsInfo = ({ commit }) => {
  commit('clearImportsInfo')
}
