/* eslint-disable import/prefer-default-export */

// * 08/10/2022 - TAG: Add product to provinional list befero create circuit
export const addProvitionalProduct = (state, provitionalProduct) => {
  // console.log('Added to Provitional');
  // * 08/10/2022 - TAG: 1. Getting index product from provitional list
  const existsAtIndex = state.provitionalProducts.findIndex(p => p.IdProduct === provitionalProduct.IdProduct)

  // * 08/10/2022 - TAG: 2. Verifify if product exists in provitional list
  if (existsAtIndex === -1) {
    // * 08/10/2022 - TAG: 3. If product does not exist is added to the list, before join to the existing product list
    state.provitionalProducts = [...state.provitionalProducts, provitionalProduct]
    state.provitionalPieces = [...state.provitionalPieces, ...provitionalProduct.pieces]
  } else {
    // * 08/10/2022 - TAG: 4. Filter all the provitional products without the existing provitional product
    const filteredProducts = state.provitionalProducts.filter(product => product.IdProduct !== provitionalProduct.IdProduct)
    const filteredPieces = state.provitionalPieces.filter(piece => piece.FK_IdProduct !== provitionalProduct.IdProduct)

    // * 08/10/2022 - TAG: 5. Verify if product has pieces
    if (provitionalProduct.pieces.length > 0) {
      // * 08/10/2022 - TAG: 6. If product has pieces, the provitional product list is updated with the provitional product
      state.provitionalProducts = [...filteredProducts, provitionalProduct]
      state.provitionalPieces = [...filteredPieces, ...provitionalProduct.pieces]
    } else {
      // * 08/10/2022 - TAG: 7. If product does not have pieces, product list is updated without de provitional product
      state.provitionalProducts = [...filteredProducts]
      state.provitionalPieces = [...filteredPieces]
    }
  }
}

export const addProvitionalProductToDelete = (state, provitionalProductToDelete) => {
  // console.log('Added to Provitional to delete');
  const existsAtIndex = state.provitionalProductsToDelete.findIndex(p => p.IdProduct === provitionalProductToDelete.IdProduct)

  if (existsAtIndex === -1) {
    state.provitionalProductsToDelete = [...state.provitionalProductsToDelete, provitionalProductToDelete]
  } else {
    const filteredProducts = state.provitionalProductsToDelete.filter(product => product.IdProduct !== provitionalProductToDelete.IdProduct)

    if (provitionalProductToDelete.pieces.length > 0) {
      state.provitionalProductsToDelete = [...filteredProducts, provitionalProductToDelete]
    } else {
      state.provitionalProductsToDelete = [...filteredProducts]
    }
  }
}

export const addCircuitProduct = (state, circuitProduct) => {
  // console.log('Added to circuit');
  const existsAtIndex = state.circuitProducts.findIndex(p => p.IdProduct === circuitProduct.IdProduct)
  if (existsAtIndex === -1) {
    state.circuitProducts = [...state.circuitProducts, circuitProduct]
    state.circuitPieces = [...state.circuitPieces, ...circuitProduct.pieces]
  } else {
    const filteredProducts = state.circuitProducts.filter(product => product.IdProduct !== circuitProduct.IdProduct)
    const filteredPieces = state.circuitPieces.filter(piece => piece.FK_IdProduct !== circuitProduct.IdProduct)

    if (circuitProduct.pieces.length > 0) {
      state.circuitProducts = [...filteredProducts, circuitProduct]
      state.circuitPieces = [...filteredPieces, ...circuitProduct.pieces]
    } else {
      state.circuitProducts = [...filteredProducts]
      state.circuitPieces = [...filteredPieces]
    }
  }
}

export const addCircuitProductEmptyPieces = (state, circuitProduct) => {
  // console.log('Added to circuit empty pieces');
  const existsAtIndex = state.circuitProducts.findIndex(p => p.IdProduct === circuitProduct.IdProduct)
  if (existsAtIndex === -1) {
    state.circuitProducts = [...state.circuitProducts, circuitProduct]
    state.loadedPieces = [...state.loadedPieces, ...circuitProduct.pieces]
  } else {
    const filteredProducts = state.circuitProducts.filter(product => product.IdProduct !== circuitProduct.IdProduct)
    const filteredPieces = state.loadedPieces.filter(piece => piece.FK_IdProduct !== circuitProduct.IdProduct)

    if (circuitProduct.pieces.length > 0) {
      state.circuitProducts = [...filteredProducts, circuitProduct]
      state.loadedPieces = [...filteredPieces, ...circuitProduct.pieces]
    } else {
      state.circuitProducts = [...filteredProducts]
      state.loadedPieces = [...filteredPieces]
    }
  }
}

export const addDeletedProduct = (state, deletedProduct) => {
  // console.log('Added to delete');
  const existsAtIndex = state.deletedProducts.findIndex(p => p.IdProduct === deletedProduct.IdProduct)
  if (existsAtIndex === -1) {
    state.deletedProducts = [...state.deletedProducts, deletedProduct]
    state.deletedPieces = [...state.deletedPieces, ...deletedProduct.pieces]
  } else {
    const filteredProducts = state.deletedProducts.filter(product => product.IdProduct !== deletedProduct.IdProduct)
    const filteredPieces = state.deletedPieces.filter(piece => piece.FK_IdProduct !== deletedProduct.IdProduct)

    if (deletedProduct.pieces.length > 0) {
      state.deletedProducts = [...filteredProducts, deletedProduct]
      state.deletedPieces = [...filteredPieces, ...deletedProduct.pieces]
    } else {
      state.deletedProducts = [...filteredProducts]
      state.deletedPieces = [...filteredPieces]
    }
  }
}

export const clearFormData = state => {
  state.provitionalProductsToDelete = []
  state.provitionalProducts = []
  state.provitionalPieces = []
  state.circuitProducts = []
  state.deletedProducts = []
  state.circuitPieces = []
  state.deletedPieces = []
  state.loadedPieces = []
}
