/* eslint-disable import/prefer-default-export */
// export const getSelectedSuppliers = state => state.selectedSuppliers
export const getListsParams = state => state.listsParams
export const getTabs = state => state.tabs
// export const getColumnsList = state => state.columnsList
export const getHidePrice = state => state.hidePrice
export const getDescription = state => state.description
export const getEcommerceStatus = state => state.EcomerceStatus
export const getFavorite = state => state.favorite
export const getCategories = state => state.categories
