export default [
  {
    path: '/produccion/circuitos',
    name: 'circuits-home',
    component: () => import('@/modules/production/circuits/views/CircuitsList.vue'),
    meta: {
      pageTitle: 'Circuitos',
      breadcrumb: [
        {
          text: 'Producción',
          active: true,
        },
        {
          text: 'Circuitos',
          active: true,
        },
      ],
      resource: 'Circuits',
      action: 'read',
      contentClass: 'circuits-module',
    },
  },
  {
    path: '/produccion/circuitos/nuevo/:number',
    name: 'new-circuit',
    component: () => import('@/modules/production/circuits/views/SaveCircuitForm.vue'),
    props: true,
    meta: {
      pageTitle: 'Circuitos',
      breadcrumb: [
        {
          text: 'Producción',
        },
        {
          text: 'Circuitos',
          to: { name: 'circuits-home' },
        },
        {
          text: 'Nuevo',
          active: true,
        },
      ],
      resource: 'Circuits',
      action: 'read',
      contentClass: 'circuits-module',
      navActiveLink: 'circuits-home',
    },
  },
  {
    path: '/produccion/circuitos/actualizar/:id',
    name: 'update-circuit',
    component: () => import('@/modules/production/circuits/views/SaveCircuitForm.vue'),
    props: true,
    meta: {
      pageTitle: 'circuitos',
      breadcrumb: [
        {
          text: 'Producción',
        },
        {
          text: 'circuitos',
          to: { name: 'circuits-home' },
        },
        {
          text: 'Actualizar',
          active: true,
        },
      ],
      resource: 'Circuits',
      action: 'read',
      contentClass: 'circuits-module',
      navActiveLink: 'circuits-home',
    },
  },
]
