export default [
  {
    path: '/almacen/entradas',
    name: 'incomes',
    component: () => import('@/modules/store/incomes/views/IncomesViews.vue'),
    meta: {
      pageTitle: 'Entradas',
      breadcrumb: [
        {
          text: 'Almacen',
        },
        {
          text: 'Entradas',
          active: true,
        },
      ],
      resource: 'Inventory_entries',
      action: 'read',
      contentClass: 'incomes-module',

    },
  },
  {
    path: '/almacen/entradas/:id',
    name: 'incomes-details',
    component: () => import('@/modules/store/incomes/views/IncomesDetails.vue'),
    props: true,
    meta: {
      pageTitle: 'Entradas',
      breadcrumb: [
        {
          text: 'Almacen',
        },
        {
          text: 'Entradas',
          to: { name: 'incomes' },
        },
        {
          text: 'Entradas',
          active: true,
        },
      ],
      resource: 'Inventory_entries',
      action: 'read',
      contentClass: 'incomes-module',
      navActiveLink: 'incomes',
    },
  },
]
