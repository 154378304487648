/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// eslint-disable-next-line no-unused-vars
export const loadRefunds = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/devolutions',
    {
      params: {
        'page[number]': loadParams.currentPage,
        limit: loadParams.perPage,
        'filter[search]': loadParams.searchTerm,
        status: loadParams.statusTerm,
        date: loadParams.dateTerm,
        locationDevolution: loadParams.locationTerm,
        creator: loadParams.creatorTerm,
        refundPayment: loadParams.refundPaymentStatusTerm,
        receivedAt: loadParams.receivedDateTerm,
        monthReceived: loadParams.receivedMonthTerm,
        commissionDiscount: loadParams.commissionStatusTerm,
        ...(loadParams.sortTerm === 'Descendente' ? { sort: 'ID' } : { sort: '-ID' }),
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadRefund = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/devolution?id=${id}`)
    .then(response => {
      commit('setRefund', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadCreatorsSuggests = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/creators')
    .then(response => {
      commit('setCreatorsSuggests', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadPDFFile = async ({ commit }, { id, idd }) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/devolution/download/${id}`, { responseType: 'arraybuffer' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `devolución_${idd}.pdf`
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export const approveRefund = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/devolution/approve', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const rejectRefund = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/devolution/decline', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const deleteRefund = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.delete(`/devolution/delete?id=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const confirmRefund = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.post(`/devolution/confirm/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const cancelRefund = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.post(`/devolution/cancel/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const loadStatusRefund = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/devolutions/status/list?id=${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const loadRefundPayments = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/devolution/refunds?idDevolution=${id}`)
    .then(response => {
      commit('setRefundPayments', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadPaymentMethods = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/devolution/refund-methods')
    .then(response => {
      commit('setPaymentMethods', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const createRefundPaymentImage = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/images/save-refund', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const createPayment = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/devolution/register-refund', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const updatePayment = async ({ commit }, payment) => new Promise((resolve, reject) => {
  axios.put('/devolution/update-refund', payment)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const confirmPayment = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/devolution/confirm-refund', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const cancelPayment = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/devolution/cancel-refund', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadImage = async ({ commit }, id) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/devolution/download-refunds?idPaymentDevolution=${id}`, {
      responseType: 'blob',
    })
      .then(response => {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'pago.jpg'
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadImageBlob = async ({ commit }, id) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/devolution/download-refunds?idPaymentDevolution=${id}`, {
      responseType: 'blob',
    })
      .then(response => {
        const blob = new Blob([response.data], { type: 'image/jpg' })
        resolve(blob)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

export const clearData = ({ commit }) => {
  commit('clearData')
}
