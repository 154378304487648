/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import axios from '@/libs/axios'

// eslint-disable-next-line no-unused-vars
export const loadCommissions = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/commissions',
    {
      params: {
        limit: loadParams.perPage,
        'page[number]': loadParams.currentPage,
        'filter[search]': loadParams.searchTerm,
        seller: loadParams.sellerTerm,
        payment: loadParams.paymentDateTerm,
        created: loadParams.registerDateTerm,
        monthyear: loadParams.monthAndYearTerm,
        year: loadParams.yearTerm,

        status: loadParams.statusTerm,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadOrders = async ({ commit }, params) => new Promise((resolve, reject) => {
  axios.get('/orders/list', { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadRefunds = async ({ commit }, params) => new Promise((resolve, reject) => {
  axios.get('/devolutions', { params })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const loadSellersSuggests = async ({ commit }) => new Promise((resolve, reject) => {
  axios.get('/commissions/sellers')
    .then(response => {
      commit('setSellersSuggests', response.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const createCommission = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/commissions/save', formData)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadCommission = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/commissions/show/${id}`)
    .then(response => {
      commit('setCommission', response.data.data)
      commit('setCommissionPercentage', response.data.data.percentage)
      commit('setCommissionOrdersIds', JSON.parse(response.data.data.orders))
      commit('setCommissionRefundsIds', JSON.parse(response.data.data.refunds))
      commit('setOriginalCommissionsOrdersIds', JSON.parse(response.data.data.orders))
      commit('setOriginalCommissionsRefundsIds', JSON.parse(response.data.data.refunds))
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const saveVoucherImage = async ({ commit }, formData) => new Promise((resolve, reject) => {
  axios.post('/commissions/images/save-commission', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars
export const loadCommissionsYears = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get('/commissions/years')
    .then(response => {
      commit('setCommissionsYears', response.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadVoucherImage = async ({ commit }, params) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/commissions/download-image/${params.commissionId}`, {
      responseType: 'blob',
    })
      .then(response => {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = params.fileName
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadVoucherImageBlob = async ({ commit }, params) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/commissions/download-image/${params.commissionId}`, {
      responseType: 'blob',
    })
      .then(response => {
        const blob = new Blob([response.data], { type: 'image/png' })
        resolve(blob)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export const cancelCommission = async ({ commit }, id) => new Promise((resolve, reject) => {
  axios.get(`/commissions/cancel/${id}`)
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

// eslint-disable-next-line no-unused-vars, arrow-body-style
export const downloadPDFFile = async ({ commit }, downloadParams) => {
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    axios.get(`/commissions/pdf/${downloadParams.commissionId}`, { responseType: 'arraybuffer' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${downloadParams.commissionId} ${downloadParams.sellerDate} ${downloadParams.sellerName}.pdf`
        link.click()

        resolve(response)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      })
  })
}

// eslint-disable-next-line no-unused-vars
export const sendCommissionsCSVFile = async ({ commit }, loadParams) => new Promise((resolve, reject) => {
  axios.get('/commissions/csv',
    {
      params: {
        'page[number]': 1,
        'filter[search]': loadParams.searchTerm,
        seller: loadParams.sellerTerm,
        payment: loadParams.paymentDateTerm,
        created: loadParams.registerDateTerm,
        monthyear: loadParams.monthAndYearTerm,
        year: loadParams.yearTerm,
        status: loadParams.statusTerm,
      },
    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})

export const addSelectedCommissionOrder = ({ commit }, order) => {
  commit('addSelectedCommissionOrder', order)
}

export const removeSelectedCommissionOrder = ({ commit }, order) => {
  commit('removeSelectedCommissionOrder', order)
}

export const addSelectedCommissionRefund = ({ commit }, refund) => {
  commit('addSelectedCommissionRefund', refund)
}

export const removeSelectedCommissionRefund = ({ commit }, refund) => {
  commit('removeSelectedCommissionRefund', refund)
}

export const clearSelectedItemsListsFromCommission = ({ commit }) => {
  commit('clearSelectedItemsListsFromCommission')
}

export const setCommissionPercentage = ({ commit }, percentage) => {
  commit('setCommissionPercentage', percentage)
}

export const setSelectedCommissionSeller = ({ commit }, seller) => {
  commit('setSelectedCommissionSeller', seller)
}

export const setSelectedCommissionMonthAndYear = ({ commit }, monthAndYear) => {
  commit('setSelectedCommissionMonthAndYear', monthAndYear)
}
