export default () => ({
  isLoadingBrands: false,
  brands: [],

  // 09/09/2022 - TAG: Manage the resposes after calls
  stateResponse: {
    success: false,
    message: '',
  },

  // 09/09/2022 - TAG: Attibutes for pagination
  searchTerm: '',
  currentPage: 1,
  totalBrands: 0,
  perPage: 10,
})
