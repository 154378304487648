export default [
  {
    path: '/comercial/cotizaciones:filter?',
    name: 'quotes-home',
    component: () => import('@/modules/trade/quotes/views/QuotesList.vue'),
    meta: {
      pageTitle: 'Cotizaciones',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Cotizaciones',
          active: true,
        },
      ],
      resource: 'Quotes',
      action: 'read',
      contentClass: 'quotes-module',
    },
  },
  {
    path: '/comercial/cotizacion/nueva',
    name: 'new-quote',
    component: () => import('@/modules/trade/quotes/views/SaveQuoteForm.vue'),
    meta: {
      pageTitle: 'Cotizaciones',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Cotizaciones',
          to: { name: 'quotes-home' },
        },
        {
          text: 'Nuevo',
          active: true,
        },
      ],
      resource: 'Quote',
      action: 'create',
      contentClass: 'quotes-module',
      navActiveLink: 'quotes-home',
    },
  },
  {
    path: '/comercial/cotizacion/actualizar/:id',
    name: 'update-quote',
    component: () => import('@/modules/trade/quotes/views/SaveQuoteForm.vue'),
    props: true,
    meta: {
      pageTitle: 'Cotizaciones',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Cotizaciones',
          to: { name: 'quotes-home' },
        },
        {
          text: 'Actualizar',
          active: true,
        },
      ],
      resource: 'Quote',
      action: 'edit',
      contentClass: 'quotes-module',
      navActiveLink: 'quotes-home',
    },
  },
  {
    path: '/comercial/cotizacion/:id',
    name: 'quote-details',
    component: () => import('@/modules/trade/quotes/views/QuoteDetails.vue'),
    props: true,
    meta: {
      pageTitle: 'Cotizaciones',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Cotizaciones',
          to: { name: 'quotes-home' },
        },
        {
          text: 'Detalles de la cotización',
          active: true,
        },
      ],
      resource: 'Quote',
      action: 'read',
      contentClass: 'quotes-module',
      navActiveLink: 'quotes-home',
    },
  },
]
