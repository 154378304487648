export default [
  {
    path: '/produccion/catalogos',
    name: 'catalog',
    component: () => import('@/modules/production/catalog/layouts/Catalog.vue'),
    meta: {
      pageTitle: 'Catálogo',
      breadcrumb: [
        {
          text: 'Producción',
          active: true,
        },
        {
          text: 'Catálogo',
          active: true,
        },
      ],
      resource: 'Categories',
      action: 'read',
    },
  },
]
